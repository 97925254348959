import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const addParticularPropertyIntoFav = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.favourite.setTheFavourite}${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const getFavourPropertyData = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
