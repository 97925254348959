import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function RentApplicationModal({ open, setOpen, data = {} }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  console.log("location", location);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          Rent Application Sent
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          color={"#07262E"}
          fontWeight={"400"}
          fontSize={"18px"}
          className="text-center"
        >
          Rent application for “
          {location?.state?.propertyName || data?.propertyName}” has been{" "}
          {id ? "Updated to" + " " : "sent to"} the respective landlord.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonComponent
          onClick={() => {
            setOpen(false);
            navigate("/renter");
          }}
          title={"Done"}
          sx={{ marginBottom: "20px" }}
        />
      </DialogActions>
    </Dialog>
  );
}
