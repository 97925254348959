import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const PaginationComponent = ({
  page = {
    pagination: 1,
    setPagination: () => {},  // set the default props
  },
  count,
}) => {
  const { pagination, setPagination } = page;
  return (
    <Pagination
      page={pagination}
      size="large"
      count={Math.ceil(count / 10)}
      onChange={(e, page) => {
        setPagination(page);
      }}
      renderItem={(item) => (
        <PaginationItem
          // component={Link}
          // to={`/inbox${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );
};

export default PaginationComponent;
