import { Card, Container, Grid, Typography } from "@mui/material";
import React from "react";
import OrderSummary from "../../components/orderSummary";
import CustomTextField from "../../components/TextField";
import ButtonComponent from "../../components/Button";

const ReceipetDetails = () => {
  return (
    <Container maxWidth="xl" className="mb-5">
      <Grid container spacing={4} mt={3}>
        <Grid item md={8}>
          <Card elevation={0} sx={{ p: 2 ,borderRadius: 3,boxShadow: "5px 5px 19px 4px #0000000d"}}  className="p-4">
            <Typography
              fontSize={24}
              fontWeight={"bold"}
              color={"text.primary"}
            >
              Receipt’s account details
            </Typography>
            <div className="my-4">
              <CustomTextField
                label={"Account Number"}
                placeholder={"Enter account number"}
              />
              <CustomTextField
                className="mt-4"
                label={"Account Holder Name"}
                placeholder={"Enter account holder name"}
              />
              <CustomTextField
                className="mt-4"
                label={"Swift Code"}
                placeholder={"Enter Swift Code"}
              />
            </div>
          </Card>
        </Grid>
        <Grid item md={4}>
          <OrderSummary
            title={"Amount to be withdrawn"}
            items={[{ title: "Withdrawal amount", amount: 6000 }]}
          />
          <ButtonComponent
            sx={{ width: 264, height: 51 }}
            title={"Proceed"}
            className={"mt-3"}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReceipetDetails;
