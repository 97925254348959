export const setLocalUser = (userDetails) => {
  return localStorage.setItem("user", JSON.stringify(userDetails));
};

export const RetrieveLocalUser = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const RemoveLocalUser = () => {
  return localStorage.removeItem("user", null);
};
