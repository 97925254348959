import { City, Country, State } from "country-state-city";
import moment from "moment";

//validate the email
export function validateEmail(value) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (!emailRegex.test(value)) {
    return true;
  } else {
    return false;
  }
}

// type only number on Onchange of the inpu
export function onChangeToNumber(value) {
  return String(value)?.replace(/[^\d]/g, "").trimStart();
}

export function generateRanges(step, max) {
  let result = [];
  for (let i = 0; i < max; i += step) {
    const start = i;
    const end = i + step;
    result.push(`${start}-${end}`);
  }
  return result;
}

// currnecy formater with comma
//@@ param amount
export function formatCurrency(amount, withSymbol) {
  const roundedAmount = Math.round(amount);
  return !withSymbol
    ? "₦" + roundedAmount?.toLocaleString("en-NG")
    : roundedAmount?.toLocaleString("en-NG");
}

/// get future date
export const dateAhead = (value, monthValue) => {
  const getDate = new Date(value);

  // Create a new Date object for the next month
  const nextMonthDate = new Date(getDate);

  // Set the month to the next month
  nextMonthDate.setMonth(getDate.getMonth() + 1);
  return nextMonthDate;
};

// replace the image url with /
//@@ param as image url
export const replaceHttpImgUrl = (img) => {
  return img?.replace("https:", "https:/");
};

// remove comma from the string
export const removeCommaFromString = (str) => {
  const stringWithoutCommas = str?.split(",").join("");
  return stringWithoutCommas;
};

// get the differnce in date
export const differenceInDays = (date) => {
  return Math.abs(moment().diff(moment(parseInt(date, 10) * 1000), "days"));
};


