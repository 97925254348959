import {
  Dialog,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  TextField,
  Typography,
  Fade,
  Radio,
  Box,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCalendar from "../../../components/calendar";
import ButtonComponent from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddInspectionRequest,
  editInspectionRequest,
} from "../../../services/inspections.services";
import { NotificationManager } from "react-notifications";
import { SlotListManageCalender } from "../../../utils/CONSTANTS";
import {
  getTheCalenderSlot,
  getTheCalenderSlotOfRenterBooked,
  getTheTimeSlotAsPerTheParticularDate,
} from "../../../services/manageCalenderSlotServices";
import { API_Endpoints } from "../../../services/API_Endpoints";
import { fDate } from "../../../utils/format-time";
import { inspectionRequestDone } from "../../../assets/images";
import { theme } from "../../../assets/theme";
import Loader from "../../../components/loader";
import { RetrieveLocalUser } from "../../../utils/authService";
const expandStyle = (toggle) => {
  return {
    "& .MuiAccordion-region": {
      height: toggle ? "auto" : 0,
    },
    "& .MuiAccordionDetails-root": {
      display: toggle ? "block" : "none",
    },
  };
};
const InspectionRequestModal = ({
  open,
  setOpen,
  propertyId,
  heading = "  Manage Calendar",
  data = {
    message: "",
    propertyName: "",
    _id: "",
    landlordPropertyMangerIDforGettheSlot: "",
  },
}) => {
  const user = RetrieveLocalUser("user");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [step, setStep] = useState(1);
  const [getDates, setGetDates] = useState([]);
  const [morningSlot, setMorningSlot] = React.useState(true);
  const [afternoonSlot, setAfternoonSlot] = React.useState(true);
  const [selectedSlot, setSelectedSlot] = useState({
    id: "",
    time: "",
    checked: false,
    _id: "",
  });
  const [slotManageArr, setSlotManageArr] = useState({
    ...SlotListManageCalender,
  });
  const location = useLocation();
  const [eveningSlot, setEveningSlot] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inspectionTime, setInspectionTime] = useState("19:00");
  const [message, setMessage] = useState("");
  console.log("messge", message, data?.message);
  const navigate = useNavigate();
  const findTimeSLot = () => {
    const findDateTimeSlot = getDates?.find(
      (item) =>
        fDate(item?.date || item?.inspectionDate, "yyyy-MM-dd") ===
        fDate(selectedDate, "yyyy-MM-dd")
    );
    return findDateTimeSlot;
  };
  const handleInspectionRequest = async () => {
    if (!selectedSlot.time) {
      return NotificationManager.error("", "select a time slot");
    }
    const timeSLot = findTimeSLot();
    if (timeSLot?.time === selectedSlot.time) {
      return NotificationManager.error("", "Time slot is already booked");
    }
    setLoading(true);
    const payload = {
      ...(location?.pathname == "/my-inspections"
        ? { inspectionID: data?._id }
        : {}),
      propertyID: data?.propertyID || propertyId,
      message: message,
      inspectionDate: selectedDate,
      inspectionTime: selectedSlot.time,
      id: selectedSlot.id,
      // _id: selectedSlot._id,
    };
    const response =
      location?.pathname == "/my-inspections"
        ? await editInspectionRequest(payload)
        : await AddInspectionRequest(payload);

    if (response.status) {
      setStep(2);
    } else {
      NotificationManager.error("", response.message);
    }
    setLoading(false);
  };

  // const get the calender slot of the particular date
  const getTheCalenderSlotasPerDate = async () => {
    const getData = await getTheCalenderSlot(
      `${API_Endpoints.manageCalenderSlot.addMangeCalenderSlot}?propertyID=${
        data?._id || propertyId
      }`
    );
    if (getData?.status) {
      setGetDates(getData?.data);
      console.log("gtdata", getData);
    } else {
      console.log("getdaty", getData);
    }
  };
  console.log("dsas", data?._id);

  /**
   *
   * @param  filterArr
   * @returns a sloted array which is filter as per the requirement
   */
  const filterTimeSlotBooking = (filterArr) => {
    let slotData = { ...slotManageArr };
    console.log("filterarr", filterArr);
    if (filterArr) {
      //convert the object key into array  and that particular key contain an array
      Object.keys(slotData).forEach((keyName) => {
        slotData[keyName].forEach((el) => {
          el.checked = false;
          let matchFound = false;
          let fullDayFlag = false;
          setSelectedSlot({
            id: "",
            time: "",
            checked: false,
            _id: "",
          });
          filterArr?.forEach((inel) => {
            if (inel.fullDay) {
              fullDayFlag = true;
            } else if (el.id === inel.id) {
              el.checked = true;
              matchFound = true;
            }
            setSelectedSlot({
              checked: inel?.renter_id === user._id,
              ...el,
            });
          });

          if (!matchFound) {
            el.checked = false;
          }
        });
      });
    } else {
      Object.keys(slotData).forEach((keyName) => {
        slotData[keyName].forEach((el) => {
          el.checked = false;
          delete el._id;
        });
      });
    }
    console.log("slotadta", slotData);
    return slotData;
  };

  //get the particular time slot as per the date
  const getTheSlotFromTheBackend = async () => {
    const dateData = fDate(selectedDate, "yyyy-MM-dd").split("-");
    const params = {
      year: Number(dateData[0]),
      month: Number(dateData[1]),
      day: Number(dateData[2]),
      landlordID: data?.landlordPropertyMangerIDforGettheSlot,
    };
    const findDate = getDates?.find(
      (item) =>
        fDate(item?.date || item?.inspectionDate, "yyyy-MM-dd") ==
        fDate(selectedDate, "yyyy-MM-dd")
    );
    if (findDate) {
      setLoader(true);
      const getData = await getTheTimeSlotAsPerTheParticularDate(params);
      const getDataRenterBooked = await getTheCalenderSlotOfRenterBooked(
        `${
          API_Endpoints.manageCalenderSlot.addMangeCalenderSlot
        }/timeslot?renterID&propertyID=${
          data?._id || propertyId
        }&landlordID&year=${params?.year}&month=${params.month}&day=${
          params.day
        }&page=1&count=20`
      );
      console.log("getDataRenterBooked", getData, getDataRenterBooked);
      const blockedDates = getData.data[0].data[0]?.blocked_dates || [];
      const inspections =
        getDataRenterBooked.data[0].data[0]?.inspections || [];
      const getTimeslot = filterTimeSlotBooking([
        ...blockedDates,
        ...inspections,
      ]);
      setSlotManageArr(getTimeslot);
      console.log("slt", getTimeslot);
    } else {
      const getTimeslot = filterTimeSlotBooking();
      console.log("gettimeslot", getTimeslot);
      setSlotManageArr(getTimeslot);
    }
    setLoader(false);
  };

  useEffect(() => {
    getTheSlotFromTheBackend();
    const findDateTimeSlot = findTimeSLot();
  }, [selectedDate]);

  useEffect(() => {
    if (!loading && open) getTheCalenderSlotasPerDate();
  }, [loading, open, data?.propertyID, propertyId]);

  useEffect(() => {
    setMessage(data?.message);
  }, [data.message]);
  console.log("selectedSlot", selectedSlot);
  return (
    <Dialog
      className="timing-modal"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          width: "1200px",
          maxWidth: "none",
          padding: 3,
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={700}
            color={"text.primary"}
            marginBottom={"20px"}
          >
            {heading}
          </Typography>

          <Grid container spacing={4}>
            {step === 1 ? (
              <>
                {loader && <Loader />}
                <Grid item md={6}>
                  <CustomCalendar
                    currentMonth={currentMonth}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setCurrentMonth={setCurrentMonth}
                    setInspectionTime={setInspectionTime}
                    kay={"ddb"}
                    fetchedDates={
                      getDates &&
                      getDates?.map((item) =>
                        fDate(item?.date || item?.inspectionDate, "yyyy-MM-dd")
                      )
                    }
                  />
                </Grid>

                <Grid item md={6}>
                  <div className="py-3">
                    <Card
                      className="py-3 phone-padding-0"
                      elevation={0}
                      sx={{
                        pr: 3,
                        border: "1px solid #DBDBDB",
                        borderRadius: 2,
                      }}
                    >
                      <div className="calendar-right">
                        <Typography
                          color={"text.secondary"}
                          fontSize={18}
                          fontWeight={"bold"}
                        >
                          Time Slot
                        </Typography>
                        <div className="time-slot-options py-3">
                          <Card
                            elevation={0}
                            className="mt-2"
                            sx={{
                              border: "1px solid #DBDBDB",
                              borderRadius: 2,
                            }}
                          >
                            <Accordion
                              elevation={0}
                              expanded={morningSlot}
                              onChange={() =>
                                setMorningSlot((prevExpanded) => !prevExpanded)
                              }
                              slots={{ transition: Fade }}
                              slotProps={{ transition: { timeout: 400 } }}
                              sx={expandStyle(morningSlot)}
                            >
                              <AccordionSummary
                                expandIcon={<>{">"}</>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography
                                  color={"text.secondary"}
                                  fontSize={18}
                                  fontWeight={"bold"}
                                >
                                  Morning
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="time-buttons">
                                  {slotManageArr.morningSlot.map((item) => (
                                    <>
                                      {" "}
                                      <label
                                        htmlFor={item.id}
                                        key={item.id}
                                        className={
                                          item.id === selectedSlot.id
                                            ? "time-button label_selected"
                                            : item.checked
                                            ? "time-button grey-color"
                                            : "time-button"
                                        }
                                      >
                                        {item.time}
                                      </label>
                                      <Radio
                                        name="radio"
                                        key={item.id}
                                        className="d-none"
                                        id={item.id}
                                        disabled={
                                          selectedSlot.fullDayNotAvailable ||
                                          item.checked
                                        }
                                        onChange={(e) =>
                                          setSelectedSlot((prev) => ({
                                            ...prev,
                                            ...item,
                                            checked: e.target.checked,
                                          }))
                                        }
                                      />
                                    </>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Card>

                          <Card
                            className="mt-2"
                            elevation={0}
                            sx={{
                              border: "1px solid #DBDBDB",
                              borderRadius: 2,
                            }}
                          >
                            <Accordion
                              elevation={0}
                              expanded={afternoonSlot}
                              onChange={() =>
                                setAfternoonSlot(
                                  (prevExpanded) => !prevExpanded
                                )
                              }
                              slots={{ transition: Fade }}
                              slotProps={{ transition: { timeout: 400 } }}
                              sx={expandStyle(afternoonSlot)}
                            >
                              <AccordionSummary
                                expandIcon={<>{">"}</>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography
                                  color={"text.secondary"}
                                  fontSize={18}
                                  fontWeight={"bold"}
                                >
                                  Afternoon
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="time-buttons">
                                  {slotManageArr.afterNoonSlot.map((item) => (
                                    <>
                                      {" "}
                                      <label
                                        htmlFor={item.id}
                                        key={item.id}
                                        className={
                                          item.id === selectedSlot.id
                                            ? "time-button label_selected"
                                            : item.checked
                                            ? "time-button grey-color"
                                            : "time-button"
                                        }
                                      >
                                        {item.time}
                                      </label>
                                      <Radio
                                        key={item.id}
                                        disabled={
                                          selectedSlot.fullDayNotAvailable ||
                                          item.checked
                                        }
                                        name="radio"
                                        className="d-none"
                                        id={item.id}
                                        onChange={(e) =>
                                          setSelectedSlot((prev) => ({
                                            ...prev,
                                            ...item,
                                            checked: e.target.checked,
                                          }))
                                        }
                                      />
                                    </>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Card>
                          <Card
                            className="mt-2"
                            elevation={0}
                            sx={{
                              border: "1px solid #DBDBDB",
                              borderRadius: 2,
                            }}
                          >
                            {" "}
                            <Accordion
                              elevation={0}
                              expanded={eveningSlot}
                              onChange={() =>
                                setEveningSlot((prevExpanded) => !prevExpanded)
                              }
                              slots={{ transition: Fade }}
                              slotProps={{ transition: { timeout: 400 } }}
                              sx={expandStyle(eveningSlot)}
                            >
                              <AccordionSummary
                                expandIcon={<>{">"}</>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Typography
                                  color={"text.secondary"}
                                  fontSize={18}
                                  fontWeight={"bold"}
                                >
                                  Evening
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="time-buttons">
                                  {slotManageArr.eveningSlot.map((item) => (
                                    <>
                                      {" "}
                                      <label
                                        htmlFor={item.id}
                                        key={item.id}
                                        className={
                                          item.id === selectedSlot.id
                                            ? "time-button label_selected"
                                            : item.checked
                                            ? "time-button grey-color"
                                            : "time-button"
                                        }
                                      >
                                        {item.time}
                                      </label>
                                      <Radio
                                        key={item.id}
                                        disabled={
                                          selectedSlot.fullDayNotAvailable ||
                                          item.checked
                                        }
                                        name="radio"
                                        className="d-none"
                                        id={item.id}
                                        onChange={(e) =>
                                          setSelectedSlot((prev) => ({
                                            ...prev,
                                            ...item,
                                            checked: e.target.checked,
                                          }))
                                        }
                                      />
                                    </>
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Card>
                        </div>
                      </div>
                    </Card>
                    <Typography
                      className="mt-3"
                      fontSize={18}
                      fontWeight={"bold"}
                      color={"text.secondary"}
                    >
                      Message (Optional)
                    </Typography>
                    <TextField
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      sx={{ borderRadius: 2 }}
                      className="mt-2"
                      fullWidth
                      rows={5}
                      multiline={true}
                      placeholder={
                        "Write a short message to Landlord/Property manager."
                      }
                    />

                    <div className="d-flex flex-row-reverse">
                      <ButtonComponent
                        loading={loading}
                        disabled={loading}
                        onClick={handleInspectionRequest}
                        className={"mt-3"}
                        sx={{ width: 284, height: 64, borderRadius: 2 }}
                        title={`${
                          location?.pathname == "/my-inspections"
                            ? "Update"
                            : "Submit"
                        } Inspection Request`}
                      />
                    </div>
                  </div>
                </Grid>
              </>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Stack spacing={2}>
                  <div className="text-center inspection-modal">
                    <Typography
                      fontSize={24}
                      fontWeight={700}
                      color={theme.palette.text.secondary}
                    >
                      Request Sent
                    </Typography>
                    <Typography
                      fontSize={18}
                      fontWeight={400}
                      marginTop={2}
                      marginBottom={2}
                      color={"#07262E"}
                    >
                      Your inspection booking request for{" "}
                      <span className="customSpanDesignText">
                        {fDate(selectedDate)}, {selectedSlot?.time}
                      </span>{" "}
                      for “{data?.propertyName}” has been sent to the Landlord
                      for approval. We will notify you shortly
                    </Typography>
                    <ButtonComponent
                      title={"Done"}
                      onClick={() => {
                        navigate("/my-inspections");
                        setOpen(false);
                        setStep(1);
                      }}
                      sx={{ marginTop: "16px", marginBottom: "16px" }}
                    />
                    {inspectionRequestDone()}
                  </div>
                </Stack>
              </Box>
            )}
          </Grid>
        </>
      </DialogTitle>
    </Dialog>
  );
};

export default InspectionRequestModal;
