import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const GetMangerListService = () => {
  return axiosInstance
    .get(API_Endpoints.landlordPropertyManger.getPropertyManger)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetMangerDetailService = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.landlordPropertyManger.getPropertyMangerDetail}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const GetMangerPropertyDataService = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.landlordPropertyManger.getPropertyList}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};