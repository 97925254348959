import { Box, Container, Stack, Typography } from "@mui/material";
import SupportImg from "../../../assets/images/support.png";
import React from "react";
import { theme } from "../../../assets/theme";
import { ArrowRightIcon } from "../../../assets/icons";
import { FaqIcon, SupportIconSVG } from "../../../assets/svg";
import { useNavigate } from "react-router-dom";

export default function Support() {

  const navigate = useNavigate()
  return (
    <Container maxWidth="xl" className="mb-5">
      <Box >
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginTop={"30px"}
        >
          Support
        </Typography>
      </Box>
      <Box textAlign={"center"} margin={"40px"} >
        <img  className="supprt-img" src={SupportImg} />
        <Typography
          fontSize={28}
          fontWeight={700}
          color={"#13556D"}
          marginTop={"20px"}
        >
          Hello, How can we help you?
        </Typography>{" "}
        <Typography
          fontSize={20}
          fontWeight={400}
          color={"#596E73"}
          margin={"20px"}
        >
          If you need instant support then use chat option to reach us quickly.
          Our Support will reply as soon as possible after you send us a
          message.
        </Typography>
      </Box>
      <Stack>
        <div className="d-flex pb-3 justify-content-center flex-wrap supprt-btns">
          <button
            style={{
              width: "336px",
              height: "51px",
              borderRadius: "10px",
              marginRight: "70px",
              color: "#13556D",
              border: "1px solid #E6E9EA",
            }}
          >
            <span onClick={() => navigate('/chat')} className="d-flex align-items-center justify-content-between px-2">
              <div className="d-flex gap-3 align-items-center">
                <SupportIconSVG />
                <span>Chat With Support</span>
              </div>
              <span style={{fontSize : 20}}>{">"}</span>
            </span>
          </button>
          <button
            style={{
              width: "336px",
              color: "#13556D",
              height: "51px",
              borderRadius: "10px",
              border: "1px solid #E6E9EA",
            }}
          >
           <span onClick={() => navigate('/faq')} className="d-flex align-items-center justify-content-between px-2">
              <div className="d-flex gap-3 align-items-center">
                <FaqIcon />
                <span>FAQ</span>
              </div>
              <span style={{fontSize : 20}}>{">"}</span>
            </span>
          </button>
        </div>
      </Stack>
    </Container>
  );
}
