
import { io } from "socket.io-client";
import { RetrieveLocalUser } from "./authService";
const user = RetrieveLocalUser("user");

const { accessToken } = user || {};
const baseurl = "https://api.rentranzact.com"

// coonect the frontend app to the backend server
export const socket = io(baseurl, {
  autoConnect: true,
  extraHeaders: {
    authorization: `Bearer ${accessToken}`,
  },

});


