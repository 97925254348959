import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";

export default function InspectionApplicationRequestModal({
  open,
  setOpen,
  heading,
  heading2,
  Customfunction,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
            textAlign={"center"}
          >
            {heading}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex mb-4">
            <Typography
              fontSize={18}
              fontWeight={700}
              color={"#07262E"}
              textAlign={"center"}
            >
              {heading2}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <ButtonComponent
            onClick={() => {
              setOpen(false);
              if (Customfunction) {
                Customfunction();
              }
            }}
            title={"Done"}
            sx={{
              marginLeft: "20px",
              marginBottom: "20px",
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
