import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/es/storage";
import { maintainceRequestSlice } from "./maintainceRequest/maintainceRequestSlice";
import { authSlice } from "./auth/authSlice";
import { reviewRatingSlice } from "./admin/review&rating/reviewRatingSlice";
import { rentApplicationSlice } from "./rentApplication/rentApplicationSlice";
import { propertyListSlice } from "./propertyList/propertyListSlice";
import { myRenterSlice } from "./my-renters/myRentersSlice";
import { paymentOptionSlice } from "./paymentOption/paymentOptionSlice";

const rootReducer = combineReducers({
  MAINTAINCEREQUEST: maintainceRequestSlice.reducer,
  AUTH: authSlice.reducer,
  REVIEWRATING: reviewRatingSlice.reducer,
  RENTAPPLICATION: rentApplicationSlice.reducer,
  PROPERTYLIST: propertyListSlice.reducer,
  MYRENTERS: myRenterSlice.reducer,
  PAYMENTOPTION:paymentOptionSlice.reducer
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["PROPERTYLIST", "RENTAPPLICATION","MYRENTERS"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };
