import axiosInstance from "../utils/axios";

// useDebounceSearch.js
import { useEffect } from "react";

// Global search for the website
//@@ param url this param is handle the api given url of the given page

export const globalSearchServiceForPost = (url, body) => {
  return axiosInstance
    .get(url, body)
    .then((response) => response.data)
    .catch((err) => err);
};

/**
 * useDebounce hook to delay the execution of a callback based on the search value.
 *
 * @param {string} searchValue - The current value from the input field provided by the user.
 * @param {Function} callback - The function to execute when the debounced value changes.
 * @param {number} delay - The delay (in milliseconds) before the callback is triggered.
 * @param {*} [additionalParameter] - An optional parameter used for additional logic in the useEffect, such as deleting or updating items in a list or table.
 */

const useDebounceSearch = (
  searchValue,
  callback,
  delay = 2000,
  anotherParameter = {}
) => {
  const serializedParameter = JSON.stringify(anotherParameter);

  useEffect(() => {
    if (!searchValue) {
      callback(searchValue);
    } else {
      const handler = setTimeout(() => {
        callback(searchValue);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchValue, serializedParameter]);
};

export default useDebounceSearch;
