import { useEffect, useState } from "react";
import { Table, TableBody, Card, Typography } from "@mui/material";
import PaginationComponent from "../../components/Pagination";
import Loader from "../../components/loader";
import TableContainerComponent from "../../components/TableContainerComponent";
import useDebounceSearch from "../../services/globalSearchService";
import PageHead from "../../components/PageHead";
import { getAdminNewsLatter } from "../../services/admin/adminCmsServices";
import UserTableHead from "../../sections/user/user-table-head";
import UserTableRow from "../../sections/user/user-table-row";
import UserTableToolbar from "../../sections/user/user-table-toolbar";
// import SearchIcon from "@mui/icons-material/Search";

const Newsletter = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = newsletters.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const fetchNewsletters = async () => {
    setLoading(true);
    const response = await getAdminNewsLatter({ page, search });

    if (response.status) {
      const newsLatterData = response.data[0].data;
      const paginationData = response.data[0].pagination[0];

      setNewsletters(newsLatterData);
      setTotalRecords(paginationData);
    }

    setLoading(false);
  };

  const searchNewsletters = async () => {
    const response = await getAdminNewsLatter({ page, search });

    if (response.status) {
      const newsLatterData = response.data[0].data;
      const paginationData = response.data[0].pagination[0];
      setNewsletters(newsLatterData);
      setTotalRecords(paginationData);
    }
  };

  const handleFilterByName = (event) => {
    setSearch(event.target.value);
  };

  useDebounceSearch(search, searchNewsletters, {
    page: page,
  });

  console.log("totalrecords", totalRecords);
  return (
    <div className="px-5">
      <div className="d-flex justify-content-between align-items-center w-100 mt-3">
        <PageHead title="Newsletter" />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <Card className="mt-4">
          <UserTableToolbar
            numSelected={selected.length}
            filterName={search}
            onFilterName={handleFilterByName}
            placeholder="Search newslatter..."
          />
          <TableContainerComponent>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={newsletters.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: "email", label: "Email" },
                  { id: "date", label: "Date" },
                ]}
              />
              <TableBody>
                {newsletters && newsletters?.length === 0 ? (
                  <Typography
                    marginTop={"10px"}
                    marginBottom={"10px"}
                    textAlign={"center"}
                  >
                    No Data Found
                  </Typography>
                ) : (
                  newsletters?.map((row) => (
                    <UserTableRow
                      _id={row?._id}
                      key={row?._id}
                      date={row?.date}
                      email={row?.email}
                      selected={selected.indexOf(row.name) !== -1}
                      naviagteType={"newslatter"}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainerComponent>
          <div className="p-3 d-flex justify-content-center align-items-center">
            <PaginationComponent
              page={{ pagination: page, setPagination: setPage }}
              count={totalRecords.total}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default Newsletter;
