import {
  Box,
  Card,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import { LocationIcon, SearchIcon } from "../../../assets/icons";
import { inspectionHouseImgCard } from "../../../assets/images";
import ButtonComponent from "../../../components/Button";
import WithDrawModal from "./withDrawModal";
import { useNavigate } from "react-router-dom";
import { GetRentApplicationsService } from "../../../services/rentapplications.service";
import Loader from "../../../components/loader";
import { API_Endpoints } from "../../../services/API_Endpoints";
import useDebounceSearch from "../../../services/globalSearchService";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";
import { useDispatch, useSelector } from "react-redux";
import { rentApplicationSlice } from "../../../redux/rentApplication/rentApplicationSlice";

export default function RentApplications() {
  const [withDrawModal, setWithDrawModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allStatusSearchState, setAllStatusSearchState] = useState({
    search: "",
  });
  const { withDrawModalStatus } = useSelector((state) => state.RENTAPPLICATION);
  const [rentApplications, setRentApplications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getRentApplicationFromBackend = async () => {
    setLoading(true);
    const response = await GetRentApplicationsService(
      `${API_Endpoints.rentApplications.GET_RENT_APPLICATION}?search=${allStatusSearchState.search}&applicationStatus=pending,accepted,canceled`
    );
    console.log("Response", response);
    if (response.status) {
      setRentApplications(response.data);
    }
    setLoading(false);
  };

  // this work as the global search hook with useeffect inside them
  useDebounceSearch(
    allStatusSearchState.search,
    getRentApplicationFromBackend,
    1000,
    {
      withDrawModalStatus: withDrawModalStatus,
    }
  );

  return (
    <Container maxWidth="xl" className="mt-3">
      <div className="py-4 ">
        <Box>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Rent Applications
          </Typography>
        </Box>
        <Box className="mb-4 mt-4">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                type="search"
                variant="outlined"
                className="w-100"
                onChange={(e) =>
                  setAllStatusSearchState((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }))
                }
                placeholder="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <Loader />
        ) : rentApplications?.length === 0 ? (
          <h2 className="property_not_found w-100">
            No Rent Application Found
          </h2>
        ) : (
          rentApplications?.map((x, i) => (
            <Card
              key={i}
              className="my-3 p-3"
              elevation={0}
              sx={{ borderRadius: 3, boxShadow: "5px 5px 19px 4px #0000000d" }}
            >
              <Grid className="align-items-center mt-3" container>
                <Grid item xs={12} md={1}>
                  {inspectionHouseImgCard(
                    replaceHttpImgUrl(
                      x?.property_info && x?.property_info[0]?.images[0]?.url
                    )
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="ps-4 d-flex align-items-center flex-wrap inspectionleftspace "
                  sx={{
                    paddingLeft: {
                      xs: "0rem",
                      sm: "0rem",
                    },
                  }}
                >
                  <Stack
                    sx={{
                      marginTop: {
                        xs: "1rem",
                        sm: "1rem",
                      },
                      marginBottom: {
                        xs: "1rem",
                        sm: "1rem",
                      },
                      paddingLeft: {
                        xs: "0rem",
                        sm: "0rem",
                      },
                    }}
                  >
                    <Typography
                      fontSize={24}
                      fontWeight={700}
                      color={theme.palette.text.primary}
                    >
                      {x?.property_info[0]?.propertyName}
                    </Typography>
                    <Typography
                      fontSize={14}
                      fontWeight={400}
                      color={"#8D9B9F"}
                    >
                      {LocationIcon()}{" "}
                      {x?.property_info[0]?.address.addressText}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={5} className="">
                  {x.applicationStatus === "accepted" ? (
                    <div className="text-end">
                      {" "}
                      <span
                        style={{
                          fontSize: "18px",
                        }}
                        className="text-end text-success"
                      >
                        Accepted
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-end flex-wrap">
                      <ButtonComponent
                        title={"Edit"}
                        color={"secondary"}
                        sx={{ width: "115px" }}
                        onClick={() =>
                          navigate(`/rent-applications-edit/${x?._id}`, {
                            state: {
                              id: x?.propertyID,
                              category:
                                x?.property_info &&
                                x?.property_info[0]?.category,
                            },
                          })
                        }
                      />
                      <ButtonComponent
                        title={"Withdraw"}
                        sx={{ width: "162px", marginLeft: "16px" }}
                        onClick={() => {
                          dispatch(
                            rentApplicationSlice.actions.setTheRentApplicationData(
                              x
                            )
                          );
                          setWithDrawModal(true);
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Card>
          ))
        )}
      </div>
      <WithDrawModal open={withDrawModal} setOpen={setWithDrawModal} />
    </Container>
  );
}
