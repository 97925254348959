import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { theme } from "../../../assets/theme";
import Loader from "../../../components/loader";
import { getMaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import { mantainanceFilter } from "../../../utils/CONSTANTS";
import RequestCard from "./requestCard";

export default function MaintainceRequestPage() {
  const [loading, setLoading] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const { filterStatus } = useSelector((state) => state.MAINTAINCEREQUEST);

  //get the mainteance request data from the backend
  const getTheDataOfRequest = async () => {
    try {
      setLoading(true);
      const getData = await getMaintenanceRequest("");
      console.log("getDtaa", getData);
      if (getData?.status) {
        setMaintenanceData(getData?.data);
      }
    } catch (error) {
      console.log("expevctional error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTheDataOfRequest();
  }, [filterStatus]);
  console.log("maintenanceData", maintenanceData);
  return (
    <Container maxWidth="xl" className="mt-4">
      <Box marginTop={"23px"}>
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          Maintenance Requests
        </Typography>
      </Box>
      {loading ? (
        <Loader />
      ) : maintenanceData?.length === 0 ? (
        <h2 className="property_not_found w-100 mt-3 mb-3">
          No Maintenance Requests Found
        </h2>
      ) : (
        <>
          <Box>
            <Typography
              className="mb-4"
              color={"#13556D"}
              fontWeight={700}
              fontSize={24}
            >
              Open Requests
            </Typography>
            <Grid className="mx-0 w-100" container spacing={4}>
              {" "}
              {maintenanceData?.filter(
                (filterValue) =>
                  filterValue.status === mantainanceFilter.pending
              ).length === 0 ? (
                <h2 className="property_not_found w-100 mt-3 mb-3">
                  No Open Maintenance Requests Found
                </h2>
              ) : (
                maintenanceData
                  ?.filter(
                    (filterValue) =>
                      filterValue.status === mantainanceFilter?.pending
                  )
                  .map((item) => <RequestCard cardData={item} />)
              )}
            </Grid>
          </Box>
          <Box marginBottom={"45px"} marginTop={"24px"}>
            <Typography
              className="mb-4"
              color={"#13556D"}
              fontWeight={700}
              fontSize={24}
            >
              Closed Requests
            </Typography>
            <Grid className="mx-0 w-100" container spacing={4}>
              {" "}
              {maintenanceData?.filter(
                (filterValue) =>
                  filterValue.status == mantainanceFilter?.remarked ||
                  filterValue.status == mantainanceFilter?.resolved
              ).length === 0 ? (
                <h2 className="property_not_found w-100 mt-3 mb-3">
                  No Close Maintenance Requests Found
                </h2>
              ) : (
                maintenanceData
                  ?.filter(
                    (filterValue) =>
                      filterValue.status == mantainanceFilter?.remarked ||
                      filterValue.status == mantainanceFilter?.resolved
                  )
                  .map((item) => <RequestCard cardData={item} />)
              )}
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
}
