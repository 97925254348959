import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../../assets/images/Rectangle1.png";
import image2 from "../../../assets/images/Rectangle2.png";
import { Box } from "@mui/material";
import { LeftArrow, RightArrow } from "../../../assets/svg";
import backgroundImage from "../../../assets/images/propertyDetail.png";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";

const PropertyImageCarousel = ({
  selectedImage,
  setSelectedImage,
  imagesData,
}) => {
  const sliderRef = useRef();
  const [images, setImages] = useState(imagesData);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: imagesData?.length > 8 ? 8 : imagesData?.length,
    slidesToScroll: 1,
    prevArrow: (
      <LeftArrow
        onClick={() => sliderRef.current.slickPrev()}
        className="prev-slider-arrow"
      />
    ),
    nextArrow: (
      <RightArrow
        onClick={() => sliderRef.current.slickNext()}
        className="next-slider-arrow"
      />
    ),
    responsive: [
      {
        breakpoint: 768, // Breakpoint for tablet size
        settings: {
          slidesToShow: 4, // Adjust as needed
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Breakpoint for phone size
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setImages(imagesData);
  }, [imagesData]);
  console.log("imgaes", imagesData);
  return (
    <div className="w-100 slider-container">
      <Slider ref={sliderRef} {...settings}>
        {images &&
          images?.map((x, i) => {
            return (
              <img
                loading="lazy"
                onClick={() => setSelectedImage(replaceHttpImgUrl(x?.url))}
                src={replaceHttpImgUrl(x?.url)}
                key={i}
                className={
                  selectedImage === x ? "slider-active-class" : "img-fluid"
                }
                style={
                  imagesData.length === 3
                    ? {
                        width: "130px",
                      }
                    : {}
                }
              />
            );
          })}
      </Slider>
    </div>
  );
};

export default PropertyImageCarousel;
