import {
  Box,
  Card,
  Chip,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import rented from "../../../assets/images/rented.png";
import { LocationIcon } from "../../../assets/icons";
import appReviewer from "../../../assets/images/app_reviewer.png";
import { useNavigate, useParams } from "react-router-dom";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import InspectionApplicationRequestModal from "../../common/inspection-request/inspectionRequestModal";
import { GetPropertyDetailService } from "../../../services/properties.services";
import {
  formatCurrency,
  replaceHttpImgUrl,
} from "../../../utils/helperFunctionForValidte";
import ButtonComponent from "../../../components/Button";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import {
  ApplicationListAsperTheProperty,
  ApplicationRequestStatusChangedService,
} from "../../../services/application.services";
import { API_Endpoints } from "../../../services/API_Endpoints";
export default function ApplicationPropertyRequest() {
  const navigate = useNavigate()
  const [apiNotCallOnCancel, setApiNotCallOnCancel] = useState("");
  const [modalData, setModalData] = useState({});
  const { id } = useParams();
  const [btnLoader, setBtnLoader] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [applicationPropertyData, setApplicationPropertyData] = useState({
    propertyDetail: {},
    applicationRecived: [],
  });
  
  // get the property detail
  const GetPropertyDetailById = async () => {
    const response = await GetPropertyDetailService(id);
    if (response.status) {
      setApplicationPropertyData((prev) => ({
        ...prev,
        propertyDetail: response?.data,
      }));
    }
  };

  // accept the request of application
  const applicationReqAccept = async (data) => {
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      rentApplicationID: data?._id,
    };
    setBtnLoader(true);
    const changeStatus = await ApplicationRequestStatusChangedService(body);
    if (changeStatus.status) {
      setAcceptModal(true);
      setModalData(data);
    } else {
      alert("Some thing went wrong");
    }
    setBtnLoader(false);
  };

  // get the application req list
  const getTheAppliationReqList = async () => {
    const getData = await ApplicationListAsperTheProperty(
      `${API_Endpoints.application_recived.GET_APPLICATION_LIST}?applicationStatus=pending&propertyID=${id}`
    );
    if (getData?.status) {
      setApplicationPropertyData((prev) => ({
        ...prev,
        applicationRecived: getData?.data,
      }));
    }
    console.log("getData", getData);
  };

  useEffect(() => {
    GetPropertyDetailById();
  }, []);

  useEffect(() => {
    if (!cancelModal && !acceptModal) {
      // this check is implemented if user click on cancel button and outside of the modal
      if (apiNotCallOnCancel === "cancel") {
        return;
      }
      getTheAppliationReqList();
    }
  }, [cancelModal, acceptModal]);
  console.log("--++", applicationPropertyData);
  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
          className="title_page pt-4 phone-heading-30"
        >
          Application(
          {applicationPropertyData?.propertyDetail?.propertyData?.propertyName})
        </Typography>
      </Box>
      <Card className="my-3">
        <Grid container spacing={3} p={2}>
          <Grid item md={5}>
            <Grid item md={12}>
              <Box
                sx={{
                  backgroundImage: `url(${
                    applicationPropertyData?.propertyDetail?.propertyData
                      ?.images &&
                    replaceHttpImgUrl(
                      applicationPropertyData?.propertyDetail?.propertyData
                        ?.images[0]?.url
                    )
                  })`,
                  width: "100%",
                  height: 258,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  objectFit: "cover",
                  p: 1,
                }}
              >
                <Chip
                  label={"Available"}
                  className="bg-white"
                  sx={{
                    color: "#009DA6",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                />
              </Box>
              {/* <img className="w-100" src={x.img} /> */}
            </Grid>
            <Grid item md={12}>
              <Typography
                className="py-1"
                fontSize={24}
                color="#031013"
                fontWeight={"bold"}
              >
                {
                  applicationPropertyData?.propertyDetail?.propertyData
                    ?.propertyName
                }
              </Typography>
              <div className="d-flex gap-1 py-1 mb-2">
                <LocationIcon />
                <Typography color="#8D9B9F" fontWeight={200}>
                  {
                    applicationPropertyData?.propertyDetail?.propertyData
                      ?.address?.addressText
                  }
                </Typography>
              </div>
              <Rating value={4} />
              <Typography
                className="py-2"
                fontWeight={"bold"}
                color="#031013"
                fontSize={18}
              >
                {formatCurrency(
                  applicationPropertyData?.propertyDetail?.propertyData?.rent
                )}
                /{" "}
                {
                  applicationPropertyData?.propertyDetail?.propertyData
                    ?.rentType
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={7}>
            {applicationPropertyData?.applicationRecived.map(
              (applicationData) => (
                <Grid container className="pt-3">
                  <Grid
                    className="applications_card my-1"
                    sx={{
                      borderRadius: 3,
                      boxShadow: "5px 5px 19px 4px #0000000d",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Grid className="left_detail">
                      <div className="img_block">
                        <img
                          style={{
                            width: "180px",
                            height: "180px",
                            borderRadius: "50%",
                            cursor: "pointer"
                          }}
                          src={applicationData?.renter_info[0]?.picture}
                          alt="no img"
                          onClick={() =>
                            navigate(
                              `/applicant-details/${applicationData?._id}`
                            )
                          }
                        />
                      </div>
                      <div className="app_person_details">
                        <h3>{applicationData?.renter_info[0]?.fullName}</h3>
                        <h5>
                          {applicationData?.property_info[0]?.propertyName}
                        </h5>
                        <p>{applicationData?.renter_info[0]?.phone}</p>
                        <span>{applicationData?.checkinDate}</span>
                      </div>
                    </Grid>

                    <Grid className="right_btns text-end">
                      <button
                        onClick={() => {
                          setCancelModal(true);
                          setModalData(applicationData);
                        }}
                        className="btn_common reject"
                      >
                        Reject
                      </button>
                      <ButtonComponent
                        onClick={() => applicationReqAccept(applicationData)}
                        className="btn_common"
                        title={"Accept"}
                        loading={btnLoader}
                        disabled={btnLoader}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Card>
      <LandlordCommonModal
        open={cancelModal}
        setOpen={setCancelModal}
        heading={"Reason for Rejection"}
        heading2={"Reason for Rejection"}
        detailData={modalData}
        apiNotCallOnCancel={setApiNotCallOnCancel}
      />
      <InspectionApplicationRequestModal
        open={acceptModal}
        setOpen={setAcceptModal}
        heading={"Request Accepted"}
        heading2={`You have accepted the application request of ${
          modalData?.renter_info && modalData?.renter_info[0]?.fullName
        } for “${
          modalData?.property_info && modalData?.property_info[0]?.propertyName
        }” on ${modalData?.checkinDate}`}
        Customfunction={() => setApiNotCallOnCancel("")}
      />
    </Container>
  );
}
