import {
  Chip,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilterIcon, LocationIcon } from "../../assets/icons";
import { properties } from "..";
import Property from "../../components/Property";
import { PropertyListService } from "../../services/properties.services";
import SkeletonLoading from "../../components/skeletonLoading";

const PropertiesInDemand = () => {
  const filters = ["Filters", "Residential", "Commercial", "Short Stay"];

  const [loading, setLoading] = useState(true);
  const [propertiesInDemand, setPropertiesInDemand] = useState([]);
  const GetPropertiesInDemand = async () => {
    setLoading(true);
    let object = {
      filters: {
        inDemand: true,
      },
    };
    const response = await PropertyListService(object);
    if (response.status) {
      setPropertiesInDemand(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetPropertiesInDemand();
  }, []);
  return (
    <Container maxWidth="xl">
      <div className="d-flex align-items-center gap-2 mt-5">
        <hr style={{ border: "1px solid #008F97", width: 40 }} />
        <Typography color="#009DA6" fontSize={24}>
          Nearby
        </Typography>
      </div>
      <Typography fontSize={41} fontWeight={700} color={"#2A2A2A"} className="phone-heading-30">
        Properties In Demand
      </Typography>
      <div className="d-flex justify-content-between align-items-center mt-4 flex-wrap">
        <div className="d-flex gap-3 flex-wrap mb-2">
          {filters.map((x, i) => {
            return (
              <Chip
                icon={i < 1 && <FilterIcon />}
                sx={{
                  width: 123,
                  height: 46,
                  borderRadius: 30,
                  fontSize: 16,
                  fontWeight: 400,
                }}
                label={x}
                variant="outlined"
                key={i}
                size="large"
              />
            );
          })}
        </div>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <LocationIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Lagos, Nigeria"
          variant="outlined"
          fullWidth
          sx={{ width: 350 }}
        />
      </div>

      <Grid container spacing={4} className="mt-3">
        {propertiesInDemand.map((x, i) => {
         

          console.log(x, "img");

          return (
            <Grid item md={4} sm={12} key={i} className="phone-full-width">
              {loading ? (
                <SkeletonLoading />
              ) : (
                <Property isFavourite={false} {...x} img={x?.images[0]?.url} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default PropertiesInDemand;
