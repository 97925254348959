import { Grid, Typography } from "@mui/material";
import googlePlay from "../assets/images/googleplay.png";
import apple from "../assets/images/apple.png";
import React from "react";
import { theme } from "../assets/theme";

export default function AppHtmlComponent() {
  return (
    <Grid
      display="flex"
      justifyContent="center"
      alignItems="center"
      item
      md={6}
      sx={{ background: "white" }}
    >
      <div className="d-flex flex-column justify-content-center gap-2">
        <Typography
          color={theme.palette.text.primary}
          fontSize={40}
          fontWeight={500}
        >
          Get the Rentranzact App
        </Typography>
        <Typography fontSize={18}>
          Whether you’re seeking access to thousands of verified potential
          clients, looking to rent your next apartment, or want the most
          seamless way to collect rent—our all-in-one Rentranzact App has you
          covered. Rent, manage, and connect effortlessly in one place!
        </Typography>
        <div className="mt-3">
          <img src={googlePlay} />
          &nbsp;&nbsp;&nbsp;
          <img src={apple} />
        </div>
      </div>
    </Grid>
  );
}
