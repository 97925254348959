import axios from "axios";
import { RetrieveLocalUser } from "./authService";

// export const baseURL = "https://88df-2401-4900-1c71-9a3c-c9ce-34c5-ac2c-95b1.ngrok-free.app/api";

export const baseURL = "https://api.rentranzact.com/api";

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    // 'Content-Type': 'multipart/form-data',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = RetrieveLocalUser("user");
    if (user?.accessToken) {
      config.headers["Authorization"] = `Bearer ${user?.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // const newToken = await refreshToken(); // Refresh the token
      // if (newToken) {
      // setToken(newToken); // Save the new token
      // axiosInstance.defaults.headers["Authorization"] = `Bearer ${newToken}`;
      // originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
      console.log("error", error);
      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
