import { ThemeProvider } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import App from "./App";

import { theme } from "./assets/theme";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import  { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
  <GoogleOAuthProvider
    clientId={
      "821353603223-rr782phm070o0da0kbu4dhdde900jr58.apps.googleusercontent.com"
    }
  >
    <ThemeProvider theme={theme}>
      <Suspense>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
        <NotificationContainer />
      </Suspense>
    </ThemeProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
