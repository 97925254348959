import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { LocationIcon, TelePhone } from "../../../assets/svg";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import InspectionApplicationRequestModal from "./inspectionRequestModal";
import { useNavigate, useParams } from "react-router-dom";
import {
  CancelInspectionRequestByRenterService,
  GetInspectionDetailById,
} from "../../../services/inspections.services";
import { fDate, fDateTime } from "../../../utils/format-time";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import { InspectionStatus } from "../../../utils/CONSTANTS";

export default function InspectionDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [inspectionDetail, setInspectionDetail] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);

  // get the inspection detail data as per the backend
  const getInspectionDetail = async () => {
    const getData = await GetInspectionDetailById(id);
    console.log("getData", getData);
    if (getData?.status) {
      setInspectionDetail(getData?.data);
    }
  };
  // accept the request api initgration
  const acceptTheRequest = async (inspectionData) => {
    setLoading(true);
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      inspectionID: inspectionDetail?._id,
    };
    const acceptData = await CancelInspectionRequestByRenterService(body);
    console.log("accept ", acceptData);
    setLoading(false);
    setAcceptModal(true);
  };
  useEffect(() => {
    getInspectionDetail();
  }, []);
  return (
    <Container>
      <Box marginTop={"23px"}>
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          Inspection Requests
        </Typography>
      </Box>
      <Grid container>
        <Grid
          item
          md={6}
          border={"1px solid #DBDBDB"}
          padding={"14px"}
          borderRadius={"10px"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={6}
            alignItems={"center"}
            sx={{
              backgroundImage: "url(/images/header-back.png)",
              height: 250,
              backgroundRepeat: "repeat",
              borderRadius: 2,
            }}
          >
            <Box
              height={167}
              width={167}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"50%"}
              border={"1px solid white"}
            >
              <img
                src={inspectionDetail?.RenterDetails?.picture}
                style={{ height: "155px", width: "155px", borderRadius: "50%" }}
              />
            </Box>
            <div>
              <div className="d-flex align-items-center  gap-2">
                <Typography color={"white"} variant="h3">
                  {inspectionDetail?.RenterDetails?.fullName}
                </Typography>

                <img
                  src={"/images/user-verified.png"}
                  style={{ height: 18, width: 62 }}
                />
              </div>

              <Typography
                color="#C8D736"
                fontSize={18}
                fontWeight={"bold"}
                variant="body1"
              >
                Renter
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item md={6} paddingLeft={"30px"} paddingRight={"30px"}>
          <Box
            border={"1px solid #009DA6"}
            padding={"18px"}
            borderRadius={"10px"}
          >
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              Requested date & time of inspection
            </Typography>

            <Typography color={"#13556D"} fontWeight={700} fontSize={24}>
              {fDateTime(inspectionDetail?.inspectionDate)}
            </Typography>
          </Box>
          {inspectionDetail?.inspectionStatus ===
            InspectionStatus?.INITIATED && (
            <Box
              border={"1px solid #009DA6"}
              padding={"18px"}
              borderRadius={"10px"}
              marginTop={"21px"}
            >
              <div className="text-center">
                <Typography
                  color={"#07262E"}
                  fontWeight={400}
                  fontSize={18}
                  marginBottom={"19px"}
                >
                  Do you want to accept inspection request for{" "}
                  {inspectionDetail?.RenterDetails?.fullName}?
                </Typography>
                <div className="d-flex">
                  <ButtonComponent
                    onClick={() => setCancelModal(true)}
                    title={"No"}
                    sx={{
                      border: "1px solid #C8D736",
                      backgroundColor: "#fff",
                      width: "231px",
                      height: "44px",
                    }}
                  />
                  <ButtonComponent
                    onClick={() => acceptTheRequest()}
                    title={"Yes"}
                    loading={loading}
                    disabled={loading}
                    sx={{
                      marginLeft: "20px",
                      width: "231px",
                      height: "44px",
                    }}
                  />
                </div>
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box paddingLeft={"16px"} marginTop={"30px"}>
        <Typography color={"#07262E"} fontWeight={700} fontSize={24}>
          Contact Info
        </Typography>
        <Typography
          className="mt-3 mb-3"
          color={"#07262E"}
          fontWeight={400}
          fontSize={18}
        >
          <span style={{ marginRight: "10px" }}>{TelePhone()}</span>
          {inspectionDetail?.RenterDetails?.phone}
        </Typography>
        {/* <Typography
          className="mt-3 mb-3"
          color={"#07262E"}
          fontWeight={400}
          fontSize={18}
        >
          <span style={{ marginRight: "10px" }}>{LocationIcon()}</span> 6th
          Floor Tower A, iTech Spaze Building,
        </Typography> */}
      </Box>
      <LandlordCommonModal
        open={cancelModal}
        setOpen={setCancelModal}
        heading={"Reason for Rejection"}
        heading2={"Reason for Rejection"}
        notificationText={`A notification has been sent to ${inspectionDetail?.RenterDetails?.fullName} regarding his Inspection Request`}
        detailData={{
          ...inspectionDetail,
          property_info: [
            {
              propertyName: inspectionDetail?.propertyName,
            },
          ],
        }}
      />
      <InspectionApplicationRequestModal
        open={acceptModal}
        setOpen={setAcceptModal}
        heading={"Request Accepted"}
        heading2={`You have accepted the inspection request of ${
          inspectionDetail?.RenterDetails?.fullName
        } for “${
          inspectionDetail?.propertyName
        }” on ${fDate(inspectionDetail?.inspectionDate)}`}
        Customfunction={() => {
          navigate("/inspection-requests");
        }}
      />
    </Container>
  );
}
