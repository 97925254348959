import { API_Endpoints } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const GetTheRenter = (status) => {
  return axiosInstance
    .get(`${API_Endpoints.renter.getRenter}?current_status=${status}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const terminateRenter = (propertyId) => {
  return axiosInstance
    .get(`${API_Endpoints.renter.terminateRenter}/${propertyId}`)
    .then((res) => res.data)
    .catch((err) => err);
};
export const getRenterHistory = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.renter.renterHistory}${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetRentedPropetyById = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.renter.rentedProperty}${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetTheRenterPastHIstory = (id)=>{
  return axiosInstance
    .get(`${API_Endpoints.renter.renterHistory}${id}`)
    .then((res) => res.data)
    .catch((err) => err);
}
