import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const addManageCalenderSlots = (body) => {
  return axiosInstance
    .post(API_Endpoints.manageCalenderSlot.addMangeCalenderSlot, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addManageMultipleCalenderSlots = (body) => {
  return axiosInstance
    .post(API_Endpoints.manageCalenderSlot.selectedMultipleSlot, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getTheCalenderSlot = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getTheCalenderSlotOfRenterBooked = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getTheTimeSlotAsPerTheParticularDate = (data) => {
  const { day, month, year, landlordID } = data;
  return axiosInstance
    .get(
      `${API_Endpoints.manageCalenderSlot.getTheLanlordBlockTimeSlot}?userID=${landlordID}&year=${year}&month=${month}&day=${day}&page=1&count=20`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
