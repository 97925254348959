import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forgotPassword: {
    id: "",
    email: "",
    acessToken: "",
  },
  USER_ID: "",
  FCM_TOKEN: "",
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    setforgotPasswordUserId: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        forgotPassword: action.payload,
      };
      return data;
    },
    setforgotPasswordIntialState: (state) => {
      let data = { ...state };
      data = {
        ...data,
        forgotPassword: {
          id: "",
          email: "",
          acessToken: "",
        },
      };
      return data
    },
    setTheUserIdAtTimeOfLoginOrSignUp: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        USER_ID: action.payload,
      };
      return data;
    },
    setTheFcmToken: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        FCM_TOKEN: action.payload,
      };
      return data;
    },
  },
});
