import { Container, Typography } from "@mui/material";
import React from "react";
const boldTextStyle = {
  fontSize: 16,
  fontWeight: "bold",
  color: "#596E73",
};
const normalTextStyle = {
  fontSize: 16,
  color: "#596E73",
  fontWeight: 300,
};
const RentInsurancePolicy = () => {
  return (
    <Container maxWidth="xl">
      <div className="py-4">
        <Typography fontSize={40} fontWeight={"bold"} color="text.primary">
          Rent Insurance Policy
        </Typography>

        <div className="py-4">
          <Typography {...boldTextStyle}>Introduction</Typography>

          <Typography className="mt-4" {...normalTextStyle}>
            This Renters Insurance Policy outlines the terms and conditions
            under which coverage is provided to the policyholder for their
            rented property. Renters insurance offers financial protection
            against losses or damages to personal belongings, liability
            coverage, and additional living expenses in case of unforeseen
            events. Coverage
          </Typography>

          <Typography className="mt-3" {...boldTextStyle}>
            1. Personal Property Coverage:
            <span style={{ ...normalTextStyle }}>
              This policy provides coverage for personal belongings such as
              furniture, electronics, clothing, and other personal items against
              risks like fire, theft, vandalism, and natural disasters.
            </span>
          </Typography>
          <Typography className="mt-3" {...boldTextStyle}>
            2. Liability Coverage:
            <span style={{ ...normalTextStyle }}>
              Liability coverage protects the policyholder against lawsuits for
              bodily injury or property damage caused to others while on the
              rented premises. It also covers legal defense costs.
            </span>
          </Typography>
          <Typography className="mt-3" {...boldTextStyle}>
            3. Additional Living Expenses:
            <span style={{ ...normalTextStyle }}>
              In case the rented property becomes uninhabitable due to a covered
              loss, the policy provides coverage for additional living expenses
              such as temporary accommodation and meals.
            </span>
          </Typography>

          <div className="mt-5">
            <Typography {...boldTextStyle}>Exclusions</Typography>

            <div className="mt-4">
              <Typography {...normalTextStyle}>
                The following are typically excluded from coverage under this
                policy:
              </Typography>
              <ul style={{ ...normalTextStyle }}>
                <li style={{ ...normalTextStyle }}>
                  Damage caused by floods, earthquakes, and other specific
                  natural disasters (unless additional coverage is purchased).
                </li>
                <li style={{ ...normalTextStyle }}>
                  Intentional damage to property.
                </li>
                <li style={{ ...normalTextStyle }}>
                  Losses related to business activities conducted on the
                  premises.
                </li>
                <li style={{ ...normalTextStyle }}>Damage to vehicles.</li>
              </ul>
            </div>
          </div>

          <div className="mt-5">
            <Typography {...boldTextStyle}>
              Policy Limits and Deductibles
            </Typography>
            <Typography {...normalTextStyle}>
              Coverage limits and deductibles apply to each section of the
              policy. The policyholder should review these limits carefully to
              ensure adequate coverage for their needs.
            </Typography>
          </div>
          <div className="mt-3">
            <Typography {...boldTextStyle}>Premiums</Typography>
            <Typography {...normalTextStyle}>
              Premiums for renters insurance are typically based on factors such
              as the value of personal property, the location of the rented
              property, and the desired coverage limits. Premiums may be paid
              annually or in installments.
            </Typography>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RentInsurancePolicy;
