import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const getProfileDetailOfUser = () => {
  return axiosInstance
    .get(API_Endpoints.profile.getProfileDetail)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const uploadImage = (body) => {
  console.log("sad", body);
  return axiosInstance
    .post(API_Endpoints.profile.uploadSingleImage, body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const userProfileUpdate = (body) => {
  return axiosInstance
    .put(API_Endpoints.profile.getProfileDetail, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
