export const AppConstants = {
  sidebarConstants: {
    dashboard: "Dashboard",
    manageUsers: "Manage Users",
    manageEmployees: "Manage Employees",
    rolesAndPremission: "Roles & Permission",
    propertyManagement: "Property Management",
    reviewRating: "Review/Rating Mgt",
    financialPerformance: "Financial Performance",
    manageTransactions: "Manage Transactions",
    managePayouts: "Manage Payouts",
    activityLog: "Activity Log",
    manageChat: "Manage Chat",
  },
  dashboardAndHeaderConstants: {},
};

//** static credentials for the login  **/
export const loginCredintials = {
  superAdmin: {
    email: "superadmin@gmail.com",
    password: "123456",
  },
  reviewerAdmin: {
    email: "revieweradmin@gmail.com",
    password: "123456",
  },
  legalAdmin: {
    email: "legaladmin@gmail.com",
    password: "123456",
  },
  financeAdmin: {
    email: "financeadmin@gmail.com",
    password: "123456",
  },
};

// enum for the propertycard props
export const PropertyPropsObjEnum = Object.freeze({
  application_inspection_btn: "ApplicationInspectionBtn",
  terminateBtnShow:"terminateRenter"
});

// enum for the propertycard props
export const roleType = Object.freeze({
  landlord: "Landlord",
});

//inspection application enum
export const inspectionApplicationRequestStatus = Object.freeze({
  cancel: "canceled",
  completed: "completed",
  pending: "pending",
  accept: "accepted",
  withDraw: "withdraw",
});

// search property array
export const FILTER_CHIP = {
  propertyPurpose: [
    {
      title: "Residential",
      isSelected: false,
      value: "residential",
    },
    {
      title: "Commercial",
      isSelected: false,
      value: "commercial",
    },
    {
      title: "Short Stay",
      isSelected: false,
      value: "short stay",
    },
  ],
  propertyType: [
    {
      title: "Detached Duplexes",
      isSelected: false,
    },
    {
      title: "Semi-detached Duplexes",
      isSelected: false,
    },
    {
      title: "Detached Bungalows",
      isSelected: false,
    },
    {
      title: "Semi-detached Bungalows",
      isSelected: false,
    },
    {
      title: "Terraced Bungalows",
      isSelected: false,
    },
    {
      title: "Terraced Duplexes",
      isSelected: false,
    },
    {
      title: "Block of Flats",
      isSelected: false,
    },
    {
      title: "Studio Apartment",
      isSelected: false,
    },
    {
      title: "Mini Flat",
      isSelected: false,
    },
    {
      title: "Office Space",
      isSelected: false,
    },
    {
      title: "Shop",
      isSelected: false,
    },
    {
      title: "Warehouse",
      isSelected: false,
    },
    {
      title: "Open Space",
      isSelected: false,
    },
    {
      title: "Complex",
      isSelected: false,
    },
    {
      title: "Plaza",
      isSelected: false,
    },
    {
      title: "Mall",
      isSelected: false,
    },
  ],

  availability: [
    {
      title: "Immediate",
      isSelected: false,
      value: "0",
    },
    {
      title: "< 15 Days",
      isSelected: false,
      value: 15,
    },
    {
      title: "< 30 Days",
      isSelected: false,
      value: 30,
    },
    {
      title: "> 30 Days",
      isSelected: false,
      value: 29,
    },
  ],
  rooms: [
    {
      title: "Less than 2",
      isSelected: false,
      maxValue: 2,
      minValue:0
    },
    {
      title: "3-5 Rooms",
      isSelected: false,
      maxValue: 5,
      minValue:3
    },
    {
      title: "More than 5 rooms",
      isSelected: false,
      maxvalue: "",
      minValue: 5
    },
  ],
  furnishing: [
    {
      title: "Serviced",
      isSelected: false,
    },
    {
      title: "Furnished",
      isSelected: false,
    },
    {
      title: "Unfurnished",
      isSelected: false,
    },
  ],
  typeOfCommunity: [
    {
      title: "Gated Community",
      isSelected: false,
    },
    {
      title: "Open Community",
      isSelected: false,
    },
  ],
};
