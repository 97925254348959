import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const MarqueText = () => {
  const navigate = useNavigate();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ py: 1 }}
      bgcolor={"#114D63"}
      color={"white"}
      onClick={() => navigate("/profile")}
    >
      <marquee>
        <Typography fontSize={14}>
          Your profile is not complete,Kindly complete your profile.
        </Typography>
      </marquee>
    </Box>
  );
};

export default MarqueText;
