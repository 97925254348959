export const options = {
    errorMessage: "errorMessage",
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      empty: {
        color: "red",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true, // You can customize this option to show or hide the postal code field
    errorMessage: "enter value",
  };