import { Box, Container, Grid, Rating, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeartIcon } from "../../../assets/icons";
import {
  BathroomIcon,
  BedroomIcon,
  FurnitureIcon,
  PowerBackupIcon,
  ResidentialIcon,
  SquareFeetIcon,
} from "../../../assets/svg";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import OrderSummary from "../../../components/orderSummary";
import ShareComponent from "../../../components/shareComponent";
import { addParticularPropertyIntoFav } from "../../../services/favouritePropertyServices";
import {
  DeleteProperty,
  GetPropertyDetailService,
} from "../../../services/properties.services";
import { RetrieveLocalUser } from "../../../utils/authService";
import { ROLES } from "../../../utils/CONSTANTS";
import { formatCurrency } from "../../../utils/helperFunctionForValidte";
import "./index.css";
import InspectionRequestModal from "./inspectionRequestModal";
import PropertyImageCarousel from "./propertyImageCarousel";
import DeleteSuccessFullyModal from "../../../components/deleteSuccessFullyModal";
import Loader from "../../../components/loader";
import { NotificationManager } from "react-notifications";
export default function PropertyDetail() {
  const [loadingImg, setLoadingImg] = useState(true);
  const [inspectionRequestModal, setInspectionRequestModal] = useState(false);
  const [deletePropertyModal, setDeletePropertyModal] = useState(false);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const user = RetrieveLocalUser("user");
  const [loading, setLoading] = useState({
    globalLoader: false,
    deleteBtn: false,
  });
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const GetPropertyDetailById = async () => {
    setLoading((prev) => ({
      ...prev,
      globalLoader: true,
    }));
    const response = await GetPropertyDetailService(id);
    if (response.status) {
      setPropertyDetail(response.data);
    }
    setLoading((prev) => ({
      ...prev,
      globalLoader: false,
    }));
  };
  useEffect(() => {
    GetPropertyDetailById();
  }, [id]);

  // add the property to favourite as per the backend api
  const addThePropertyToFav = async (e) => {
    e.stopPropagation();
    setPropertyDetail((prev) => ({
      ...prev,
      liked: !prev.liked,
    }));
    const getData = await addParticularPropertyIntoFav(property?._id);
    console.log("getdata", getData);
    // window.location.reload()
  };

  console.log(propertyDetail, "propertyDetail");
  const landlord = propertyDetail?.landlord
    ? propertyDetail?.landlord
    : propertyDetail?.property_manager ?? {};
  const property = propertyDetail?.propertyData ?? {};
  const renter_detail = propertyDetail?.renterInfo ?? {};
  console.log("renter_ifod", renter_detail);

  // delete the property this delete property work only on the landlord side and only on that property which is not rented
  const deleteTheProperty = async () => {
    setLoading((prev) => ({
      ...prev,
      deleteBtn: true,
    }));
    const deleteData = await DeleteProperty(id);
    if (deleteData?.status) {
      navigate("/my-properties");
    } else {
      console.log("delte", deleteData);
      NotificationManager.error("", deleteData?.response?.data?.message);
    }
    setLoading((prev) => ({
      ...prev,
      deleteBtn: false,
    }));
  };

  console.log(landlord, "landlord");
  return loading.globalLoader ? (
    <Loader />
  ) : (
    <>
      <div
        // style={{
        //   backgroundImage: `url(${property?.images?.[0]?.url.replace(
        //     "http:",
        //     "http:/"
        //   )})`,
        // }}
        className="property-background"
      >
        <div className="shareLike">
          <ShareComponent
            shareData={{
              propertyName: property?.propertyName,
            }}
          />
          {user && user.role === ROLES.renter && (
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                addThePropertyToFav(e);
              }}
            >
              <HeartIcon isFavourite={propertyDetail?.liked} />
            </div>
          )}
        </div>
        {loadingImg && <div>loading...</div>}
        <img
          src={
            selectedImage ||
            property?.images?.[0]?.url.replace("https:", "https:/")
          }
          onLoad={() => setLoadingImg(false)}
          loading="lazy"
          alt="property_pic"
          style={{ backgroundSize: "cover", resize: "contain" }}
          className="w-100 h-100"
        />
        {property?.images?.length >= 3 && (
          <div
            className={"slider-class"}
            style={
              property?.images?.length === 3
                ? {
                    maxWidth: "939px",
                  }
                : {}
            }
          >
            <PropertyImageCarousel
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              imagesData={property?.images}
            />
          </div>
        )}
      </div>

      <Container maxWidth="xl">
        <Grid container spacing={4} mt={5} className="property-detail-main">
          <Grid item md={7}>
            <Typography
              sx={{
                fontSize: {
                  xs: "1.6rem",
                  sm: "1.6rem",
                  md: "2.5rem",
                  xl: "2.5rem",
                },
              }}
              className="mb-1 mt-4 property-name-sec"
              fontWeight={700}
              color={"#2A2A2A"}
            >
              {property?.propertyName}
            </Typography>
            <Stack className="mb-3">
              <Typography
                fontSize={24}
                fontWeight={700}
                color={theme.palette.text.primary}
                className="mt-3 mb-1"
              >
                {property?.address?.addressText}
              </Typography>
              {/* <Typography fontSize={14} fontWeight={400} color={"#8D9B9F"}>
                {property?.address?.addressText}
              </Typography> */}
              <div className="d-flex mt-2 mb-3">
                <Rating color="#C8D736" value={property?.avg_rating} />
                <span className="rating-span">
                  {property?.total_reviews}/ (5 reviews)
                </span>
              </div>
            </Stack>
            <Stack
              padding={"10px"}
              border={"1px solid #ABABAB"}
              borderRadius={"8px"}
            >
              <div className="d-flex mt-3 py-2 px-0 phone-padding-0">
                {property?.bedrooms > 0 && (
                  <span className="feature-span">
                    <BedroomIcon />
                    <Typography className="span-text">
                      {" "}
                      {property?.bedrooms} Bedrooms
                    </Typography>
                  </span>
                )}
                {property?.number_of_bathrooms > 0 && (
                  <span className="feature-span">
                    <BathroomIcon />
                    <Typography className="span-text">
                      {property?.number_of_bathrooms} Bathrooms
                    </Typography>
                  </span>
                )}
                <span className="feature-span">
                  <SquareFeetIcon />
                  <Typography className="span-text">
                    {property?.carpetArea} sq.ft
                  </Typography>
                </span>
              </div>
              <div className="d-flex mt-4 mb-3 py-2 px-0">
                <span className="feature-span">
                  <ResidentialIcon />
                  <Typography className="span-text text-capitalize">
                    {property?.category}
                  </Typography>
                </span>
                <span className="feature-span">
                  <FurnitureIcon />
                  <Typography className="span-text">
                    {property?.furnishingType}
                  </Typography>
                </span>
              </div>
            </Stack>
            <Typography
              marginTop={"30px"}
              color={"#07262E"}
              fontWeight={700}
              fontSize={24}
            >
              Amenities
            </Typography>
            <div className="d-flex mt-2 mb-4 gap-3 flex-wrap">
              {propertyDetail?.propertyData?.amenities?.length === 0 ? (
                <h2>No amenities related to this property</h2>
              ) : (
                propertyDetail?.propertyData?.amenities?.map((item) => (
                  <span className="d-flex align-items-center gap-2">
                    <PowerBackupIcon />
                    <Typography className="feature-extend-span">
                      {item}
                    </Typography>
                  </span>
                ))
              )}
            </div>
            {(user?.role == ROLES.landlord ||
              user?.role == ROLES.propertyManager) &&
            !propertyDetail?.propertyData?.rented ? null : (
              <div className="owner-card">
                <Box
                  className="phone-gap-0"
                  display={"flex"}
                  justifyContent={"center"}
                  gap={5}
                  alignItems={"center"}
                  sx={{
                    backgroundImage: "url(/images/header-back.png)",
                    height: 250,
                    backgroundRepeat: "repeat",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      Width: {
                        xs: "100",
                        sm: "100",
                        md: "167",
                        xl: "167",
                      },
                      Height: {
                        xs: "100",
                        sm: "100",
                        md: "167",
                        xl: "167",
                      },
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50%"}
                    border={"1px solid white"}
                  >
                    <img
                      src={
                        user?.role == ROLES.landlord ||
                        user?.roles == ROLES.propertyManager
                          ? renter_detail?.picture
                          : landlord?.picture
                      }
                      style={{
                        height: "130px",
                        width: "130px",
                        borderRadius: "100%",
                      }}
                    />
                  </Box>
                  <div>
                    <div className="d-flex align-items-center  gap-2 profile-name">
                      <Typography
                        color={"white"}
                        variant="h3"
                        sx={{
                          fontSize: {
                            xs: "1.5rem",
                            sm: "1.5rem",
                            md: "2rem",
                            xl: "2rem",
                          },
                        }}
                      >
                        {user?.role == ROLES.landlord ||
                        user?.role == ROLES.propertyManager
                          ? renter_detail?.fullName
                          : landlord?.fullName}
                      </Typography>

                      {landlord?.verified && (
                        <img
                          src={"/images/user-verified.png"}
                          style={{ height: 18, width: 62 }}
                        />
                      )}
                    </div>

                    <Typography
                      color="#C8D736"
                      fontSize={18}
                      fontWeight={"bold"}
                      variant="body1"
                    >
                      {user?.role == ROLES.landlord ||
                      user?.role == ROLES.propertyManager
                        ? renter_detail?.role
                        : landlord?.role}
                    </Typography>
                  </div>
                </Box>
              </div>
            )}

            <div className="margin">
              <Typography color={"#07262E"} fontWeight={700} fontSize={24}>
                About Property
              </Typography>
              <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                {property?.aboutProperty}
              </Typography>
            </div>

            <Typography
              color={"#07262E"}
              fontWeight={700}
              fontSize={24}
              marginBottom={"20px"}
            >
              No Review
            </Typography>
            {/* {arr.map(() => (
              <Ratingreview />
            ))} */}
          </Grid>

          <Grid item md={5}>
            {user?.role === ROLES.landlord ||
            user?.role === ROLES.propertyManager ? null : user &&
              user?.role === ROLES.renter ? (
              <div className="d-flex justify-content-between align-items-center mb-4 order-summary flex-wrap">
                <Typography color={"#fff"} fontSize={24} fontWeight={700}>
                  {formatCurrency(propertyDetail?.propertyData?.rent)}/
                  {propertyDetail?.propertyData?.rentType}
                </Typography>
                <ButtonComponent
                  onClick={() => setInspectionRequestModal(true)}
                  sx={{
                    height: "64px",
                    width: "216px",
                  }}
                  title={"Inspection Request"}
                />
              </div>
            ) : null}
            {user &&
              user.role == ROLES.landlord &&
              !propertyDetail?.propertyData?.rented && (
                <div className="d-flex mb-4">
                  {propertyDetail?.application_count > 0 ? (
                    <ButtonComponent
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/application-requests-property/${propertyDetail?.propertyData?._id}`
                        );
                      }}
                      title={`Application Received (${
                        propertyDetail?.application_count === undefined
                          ? 0
                          : propertyDetail?.application_count
                      })`}
                      sx={{
                        border: "1px solid #C8D736",
                        backgroundColor: "#fff",
                        width: "319px",
                        height: "64px",
                      }}
                    />
                  ) : null}
                  {propertyDetail?.inspection_count > 0 ? (
                    <ButtonComponent
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/inspection-requests-property/${propertyDetail?.propertyData?._id}`
                        );
                      }}
                      title={`Inspection Request (${propertyDetail?.inspection_count})`}
                      sx={{
                        width: "319px",
                        height: "64px",
                        marginLeft: "10px",
                      }}
                    />
                  ) : null}
                </div>
              )}
            <OrderSummary
              title={"Rental Breakup"}
              items={[
                {
                  title: "Service Charges",
                  amount: propertyDetail?.rental_breakdown?.service_charge,
                },
                {
                  title: "Rent",
                  amount: propertyDetail?.rental_breakdown?.rent,
                },
                {
                  title: "Insurance",
                  amount: propertyDetail?.rental_breakdown?.insurance,
                },
                {
                  title: "Agency Fee",
                  amount: propertyDetail?.rental_breakdown?.agency_fee,
                },
                {
                  title: "Legal Fee",
                  amount: propertyDetail?.rental_breakdown?.legal_Fee,
                },
                {
                  title: "Caution Deposit",
                  amount: propertyDetail?.rental_breakdown?.caution_deposite,
                },
              ]}
            />

            {user &&
              user.role == ROLES.landlord &&
              !propertyDetail?.propertyData?.rented && (
                <Box
                  width={"100%"}
                  bgcolor={"#009DA6"}
                  mt={3}
                  textAlign={"center"}
                  py={4}
                  borderRadius={2}
                >
                  <Typography fontSize={24} fontWeight={"bold"} color="white">
                    Want to edit or delete this property?
                  </Typography>

                  <div className="d-flex justify-content-center align-items-center gap-3 mt-3">
                    <ButtonComponent
                      sx={{
                        width: 157,
                        height: 64,
                        color: "#C8D736",
                        background: "transparent",
                        border: "1px solid #C8D736",
                      }}
                      onClick={() => {
                        navigate(`/edit-property/${id}`);
                      }}
                      title={"Edit"}
                    />{" "}
                    <ButtonComponent
                      sx={{ width: 157, height: 64 }}
                      title={"Delete"}
                      onClick={() => setDeletePropertyModal(true)}
                    />
                  </div>
                </Box>
              )}

            {(user && user?.role === ROLES.landlord) ||
            (user &&
              user?.role === ROLES.propertyManager) ? null : propertyDetail
                ?.propertyData?.rented ? null : (
              <div className="d-flex justify-content-between align-items-center mt-4 order-summary flex-wrap mb-3">
                <Typography color={"#fff"} fontSize={24} fontWeight={700}>
                  Rent this Property
                </Typography>
                <ButtonComponent
                  onClick={() => {
                    // now this page is not private so if renter is not login then it will reflected to the login page
                    if (!user) {
                      navigate("/login");
                    } else {
                      navigate("/rent-applications-update", {
                        state: {
                          id: id,
                          propertyName:
                            propertyDetail?.propertyData?.propertyName,
                          category: propertyDetail?.propertyData?.category,
                        },
                      });
                    }
                  }}
                  sx={{
                    height: "64px",
                    width: "172px",
                  }}
                  title={"Apply for rent"}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {inspectionRequestModal && (
          <InspectionRequestModal
            open={inspectionRequestModal}
            setOpen={setInspectionRequestModal}
            propertyId={id}
            heading="Inspection Request"
            data={{
              propertyName: propertyDetail?.propertyData?.propertyName,
              landlordPropertyMangerIDforGettheSlot: landlord?._id,
            }}
          />
        )}
        <DeleteSuccessFullyModal
          open={deletePropertyModal}
          setOpen={setDeletePropertyModal}
          headingAndText={{
            mainHeading: "Are you sure you want this property to delete?",
            middleText:
              "This action cannot be undone. Once deleted, the property will be permanently removed from your account",
          }}
          onCLick={deleteTheProperty}
          loading={loading.deleteBtn}
        />
      </Container>
    </>
  );
}
