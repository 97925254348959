import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../assets/theme";
import madeEasy from "../assets/images/madeEasy.png";
import customerMan from "../assets/images/CustomerMan.jpg";
import lady from "../assets/images/lady.jpg";
import homeBanner from "../assets/images/home_banner.png";
import "swiper/css";
import "swiper/css/pagination";
import ButtonComponent from "../components/Button";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay, Pagination, Navigation, A11y } from "swiper/modules";
import "swiper/swiper-bundle.css";
import residential from "../assets/images/residential.png";
import commercial from "../assets/images/commercial.png";
import shortTerm from "../assets/images/shortTerm.png";
import man from "../assets/images/man.png";
import googlePlay from "../assets/images/googleplay.png";
import apple from "../assets/images/apple.png";
import {
  AddPropertyIcon,
  HomeTwo,
  LocationIcon,
  RentHistoryIcon,
  TenanatsIcon,
  VerifiedIcon,
  WalletIcon,
} from "../assets/icons";
import Property from "../components/Property";
import renting from "../assets/images/renting.png";
import require from "../assets/images/require.png";
import propert1 from "../assets/images/Property_1.jpg";
import propert2 from "../assets/images/Property_2.jpg";
import propert3 from "../assets/images/Property_3.jpg";
import { Link, useNavigate } from "react-router-dom";
import GlobalSearchComponent from "../components/globalSearchComponent";
import { PROPERTY_CATEGORY } from "../utils/CONSTANTS";
import { PropertyListService } from "../services/properties.services";
import AppHtmlComponent from "../components/appHtmlComponent";
let slides = [madeEasy, homeBanner, madeEasy];
export const properties = [
  {
    status: "Available",
    isFavourite: true,
    rate: 4.7,
    title: "Golden Tierra Apartments",
    location: "45 Kofa Annan St, Lagos",
    price: "₦ 7,923/ monthly",
  },
  {
    status: "Rented",
    isFavourite: true,
    rate: 4.7,
    title: "Bakare Estate",
    location: "Bakare Estate, Agungi, Lekki, Lagos",
    price: "₦ 7,923/ monthly",
  },
  {
    status: "Available",
    isFavourite: true,
    rate: 4.7,
    title: "Naf Valley Estate",
    location: "Naf Valley Estate, Asokoro District, Abuja",
    price: "₦ 7,923/ monthly",
  },
];

const requirements = [
  { title: "Safe & hassle-free rental process", icon: <AddPropertyIcon /> },
  { title: "Painless rent collection", icon: <RentHistoryIcon /> },
  { title: "Find best tenants", icon: <TenanatsIcon /> },
  { title: "Verified Properties", icon: <VerifiedIcon /> },
  { title: "Secure payments", icon: <WalletIcon /> },
];

const HomePage = () => {
  const [propertiesInDemand, setPropertiesInDemand] = useState([]);
  const GetPropertiesInDemand = async () => {
    let object = {
      filters: {
        inDemand: true,
      },
    };
    const response = await PropertyListService(object);
    console.log("Response", response);
    if (response.status) {
      setPropertiesInDemand(response.data);
    }
  };

  useEffect(() => {
    GetPropertiesInDemand();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Swiper
        direction={"vertical"}
        pagination={{
          clickable: true,
        }}
        style={{ height: 497 }}
        modules={[Pagination, Autoplay, Navigation, A11y]}
        className="swiper swiper_slider"
        autoplay={true}
      >
        <SwiperSlide className="main-slider slide1">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            px={6}
            alignItems={"center"}
            sx={{
              backgroundImage: `url('url1.jpg')`,
              width: "100%",
              backgroundSize: "cover",
              height: 497,
            }}
          >
            <div className="text-left left-column">
              <Typography
                textAlign={"left"}
                fontSize={56}
                fontWeight={"bold"}
                color={"#395158"}
                className="slider_title"
              >
                Find the best place <br /> to live
              </Typography>
              <Typography
                textAlign={"left"}
                fontSize={24}
                color={"#666"} // Replace with `theme.palette.text.secondary` if you have it set up
              >
                Enjoy a worry free house hunting experience. Connect directly
                with verified landlords and property managers on our secure
                platform​
              </Typography>
              <ButtonComponent
                title="Browse Articles​"
                sx={{ height: 55, mt: 3 }}
              />
            </div>
            <div></div>
          </Box>
        </SwiperSlide>

        {/* Slide 2 */}
        <SwiperSlide className="slide2">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            px={6}
            alignItems={"center"}
            sx={{
              backgroundImage: `url('url2.jpg')`,
              width: "100%",
              backgroundSize: "cover",
              height: 497,
            }}
          >
            <div className="text-left left-column">
              <Typography
                textAlign={"left"}
                fontSize={56}
                fontWeight={"bold"}
                color={"#395158"}
                className="slider_title"
              >
                Rental Advice
              </Typography>
              <Typography
                textAlign={"left"}
                fontSize={24}
                color={"#666"} // Replace with `theme.palette.text.secondary` if you have it set up
              >
                Weather you are a landlord, property manager, or a renter, our
                insightful blog would guide you on all you should know about
                renting.​
              </Typography>
              <ButtonComponent
                title="Browse Articles​​"
                sx={{ height: 55, mt: 3 }}
              />
            </div>
            <div></div>
          </Box>
        </SwiperSlide>

        {/* Slide 3 */}
        <SwiperSlide className="slide3">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            px={6}
            alignItems={"center"}
            sx={{
              backgroundImage: `url('url3.jpg')`,
              width: "100%",
              backgroundSize: "cover",
              height: 497,
            }}
          >
            <div className="text-left left-column">
              <Typography
                textAlign={"left"}
                fontSize={56}
                fontWeight={"bold"}
                color={"#395158"}
                className="slider_title"
              >
                Manage your property​
              </Typography>
              <Typography
                textAlign={"left"}
                fontSize={24}
                color={"#666"} // Replace with `theme.palette.text.secondary` if you have it set up
              >
                Simplify your property management system with our all in one
                platform. List, collect rent, track payment, handle maintenance
                and do lots more.​
              </Typography>
              <ButtonComponent
                title="Manage your properties​"
                sx={{ height: 55, mt: 3 }}
              />
            </div>
            <div></div>
          </Box>
        </SwiperSlide>
      </Swiper>
      <div
        style={{ width: "100%" }}
        className=" bg-white d-flex justify-content-center  text-center mt-3"
      >
        <Card
          sx={{
            mt: -7,
            position: "relative",
            zIndex: 1,
            boxShadow: "5px 5px 19px 4px #0000000d",
            width: "60%",
          }}
          className="banner_search_filter"
        >
          <GlobalSearchComponent />
        </Card>
      </div>
      <div className="bg-white py-5">
        <Container maxWidth="xl" className="w-100">
          <div className="d-flex align-items-center gap-2 mt-5 ">
            <hr style={{ border: "1px solid #008F97", width: 40 }} />
            <Typography color="#009DA6" fontSize={24}>
              Explore
            </Typography>
          </div>
          <Typography className="home_headings" fontSize={41} fontWeight={700} color={"#2A2A2A"}>
            Properties In Demand 
          </Typography>

          <Grid container spacing={4} className="mt-3">
            {propertiesInDemand?.map((x, i) => {
              return (
                <Grid item md={4} sm={12} key={i} className="phone-full-width">
                  <Property {...x} img={x.images[0].url} />
                </Grid>
              );
            })}
          </Grid>

          <div className="text-center">
            <ButtonComponent sx={{ width: 150, height: 64 }} title="Explore" />
          </div>
        </Container>
      </div>
      <div className="home-bg my-5 pb-5 pt-1">
        <Container maxWidth="xl">
          <Grid container spacing={5} my={5}>
            <Grid md={8} item>
              <div className="d-flex align-items-center gap-2 ">
                <hr style={{ border: "1px solid #008F97", width: 40 }} />
                <Typography color="#009DA6" fontSize={24}>
                  Who We Are
                </Typography>
              </div>
              <Typography
                fontSize={40}
                fontWeight={500}
                color={theme.palette.text.primary}
              >
                Renting is easier with us
              </Typography>
              <Typography fontSize={18} color={theme.palette.text.secondary}>
                We are a family with one goal: making property renting and
                management easy for everyone. Our all inclusive platform helps
                landlords and property managers find great tenants, manage
                properties smoothly, and access clear financial reports of their
                properties. While renters easily find homes, build credit score,
                save for rent, report maintenance issues, and get their caution
                deposit back. Join us in changing the rental experience for all.
              </Typography>
              <ButtonComponent
                sx={{ mt: 4, width: 200, height: 64 }}
                title="Get Started"
              />
            </Grid>
            <Grid md={4} item>
              <img className="renting_img" src={renting} />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="bg-white py-3">
        <Container maxWidth="xl">
          <div className="mt-4">
            <Box
              className="cat_section"
              sx={{
                mb: 3,
                backgroundImage: `url(${require})`,
                height: 290,
                width: "100%",
                backgroundSize: "cover",
                borderBottomRightRadius: 25,
                borderBottomLeftRadius: 25,
              }}
            >
              <Grid className="cat_row" container spacing={2}>
                <Grid md={4} item m={3}>
                  <div >
                    <Typography
                      fontSize={40}
                      fontWeight={"bold"}
                      color={"white"}
                      className="home_headings"
                      
                    >
                      Rentranzact Has
                      <br /> Everything You Require.
                    </Typography>
                    <ButtonComponent
                      sx={{ height: 64, mt: 2 }}
                      title={"Add Property For Free"}
                      onClick={() => navigate("/login")}
                    />
                  </div>
                </Grid>
                <Grid md={4} item>
                  {requirements.map((x) => {
                    return (
                      <Box
                        px={2}
                        height={36}
                        sx={{ background: "#F1F1F1", m: 2, borderRadius: 2 }}
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                        justifyContent={"flex-start"}
                        // py={1}
                        // p={0.3}
                      >
                        <span>{x.icon}</span>
                        <Typography
                          fontSize={16}
                          color={"text.primary"}
                          fontWeight={500}
                        >
                          {x.title}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid md={4} item></Grid>
              </Grid>
            </Box>
          </div>

          <div className="d-flex our_cat_section justify-content-center flex-column align-items-center">
            <div className="d-flex align-items-center gap-2 mt-5">
              <hr style={{ border: "1px solid #008F97", width: 40 }} />
              <Typography color="#009DA6" fontSize={24}>
                Categories
              </Typography>
            </div>
            <Typography
              fontSize={40}
              fontWeight={500}
              color={theme.palette.text.primary}
            >
              Our Property Categories
            </Typography>
            <Typography color="text.secondary" fontSize={18} fontWeight={500}>
              Find Your Fit: Whether short-term stays, cozy homes, workspaces,
              or retail spots – our categories cover it all for your
              necessities.
            </Typography>
          </div>
          <Grid
            container
            spacing={3}
            className="mt-4 property-type property_section"
          >
            <Grid item md={6}>
              <Grid container spacing={3} className="mobile_property_view">
                <Grid item md={12}>
                  <Box
                    onClick={() =>
                      navigate(`/filter-properties`, {
                        state: PROPERTY_CATEGORY.commerical,
                      })
                    }
                    sx={{
                      backgroundImage: `url(${commercial})`,
                      height: 302,
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      position: "relative",
                    }}
                  >
                    <Typography
                      className="typo_res_clas"
                      position="absolute"
                      bottom={0}
                      right={0}
                      margin={1}
                      left={0}
                      fontWeight={"bold"}
                      fontSize={32}
                      color={"white"}
                    >
                      Commercial
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    onClick={() =>
                      navigate(`/filter-properties`, {
                        state: PROPERTY_CATEGORY.shortStay,
                      })
                    }
                    sx={{
                      backgroundImage: `url(${shortTerm})`,
                      height: 302,
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      position: "relative",
                    }}
                  >
                    <Typography
                      className="typo_res_clas"
                      position="absolute"
                      bottom={0}
                      right={0}
                      margin={1}
                      left={0}
                      fontWeight={"bold"}
                      fontSize={32}
                      color={"white"}
                    >
                      Short-term Stays
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Box
                onClick={() =>
                  navigate(`/filter-properties`, {
                    state: PROPERTY_CATEGORY.resisdential,
                  })
                }
                sx={{
                  backgroundImage: `url(${residential})`,
                  height: 630,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                }}
              >
                <Typography
                  className="typo_res_clas"
                  position="absolute"
                  bottom={0}
                  right={0}
                  margin={1}
                  left={0}
                  fontWeight={"bold"}
                  fontSize={32}
                  color={"white"}
                >
                  Residential
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Grid container className="mt-5 app_row home-bg">
        <AppHtmlComponent />
        <Grid item md={6}>
          <img src={man} style={{ width: "100%" }} />
        </Grid>
      </Grid>

      <div className="bg-white our_custor_says mb-4">
        <Container maxWidth="xl" className="px-0">
          <div className="d-flex customer_top_row justify-content-between align-items-center">
            <div>
              <div className="d-flex align-items-center gap-2 mt-5">
                <hr style={{ border: "1px solid #008F97", width: 40 }} />
                <Typography color="#009DA6" fontSize={24}>
                  Testimonials
                </Typography>
              </div>
              <Typography
                className="customer_title"
                fontSize={40}
                fontWeight={"bold"}
                color={"#2A2A2A"}
              >
                What Our Customers Say About Us
              </Typography>
            </div>
            <Link to="/testimonials" className="view_more">
              <Typography fontSize={18} fontWeight={"bold"} color="#009DA6">
                View More
              </Typography>
            </Link>
          </div>

          <Grid container spacing={4} py={3}>
            {Array(3).fill(
              <Grid item md={4}>
                <Card
                  elevation={0}
                  sx={{ border: "1px solid #F1F1F1", p: 3, borderRadius: 2 }}
                >
                  <div className="d-flex gap-3">
                    <img src={customerMan} />
                    <div>
                      <Typography
                        fontSize={24}
                        fontWeight={500}
                        color={"text.secondary"}
                      >
                        Joseph
                      </Typography>
                      <Typography
                        color={"#009DA6"}
                        fontSize={18}
                        fontWeight={500}
                      >
                        Renter
                      </Typography>
                    </div>
                  </div>

                  <Typography
                    className="mt-4"
                    fontSize={14}
                    fontWeight={500}
                    color={"text.primary"}
                  >
                    At consequat augue elementum netus suspendisse mi. Odio eget
                    amet praesent nulla amet. Molestie etiam id condimentum
                    neque a diam tortor. Viverra sit dis viverra mauris
                    pellentesque aliquet molestie. Dignissim blandit varius
                    proin vel neque curabitur. Felis lorem at nisl eros nullam
                    auctor. Tristique purus commodo eleifend at quis a viverra.
                    Ante mollis aenean eget blandit habitant vulputate donec
                    velit habitant.
                  </Typography>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default HomePage;
