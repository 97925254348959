import React from "react";
import { Typography } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";

const PageHead = ({ title, backArrow = false }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center gap-4">
      {backArrow && (
        <Icon
          onClick={() => navigate(-1)}
          icon="zondicons:arrow-left"
          cursor={"pointer"}
        />
      )}
      <Typography variant="h4" py={3}>
        {title}
      </Typography>
    </div>
  );
};

export default PageHead;
