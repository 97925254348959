import axiosInstance from "../../utils/axios";
import { API_Endpoints } from "../API_Endpoints";


export const addContact = (body) => {
  return axiosInstance
    .post(API_Endpoints.contactUs.addContactUs, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};