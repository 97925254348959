import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.css";
import { GetNotificationList } from "../../services/notification.services";
import { useNavigate } from "react-router-dom";
import { fDate, fDateTime } from "../../utils/format-time";
import Loader from "../../components/loader";
const UserNotifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //* Get the list of notification from the backend  *//
  const getTheNotificationDataFromBackend = async () => {
    try {
      setLoading(true);
      const getData = await GetNotificationList();
      console.log("asda",getData)
      if (getData?.status) {
        setNotificationData(getData?.data[0]?.data);
      } else {
        console.log("getdata", getData);
      }
    } catch (error) {
      console.log("errr", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTheNotificationDataFromBackend();
  }, []);

  console.log("+++", notificationData);
  return loading ? (
    <Loader />
  ) : (
    <Container maxWidth="xl">
      <div className="mt-5">
        <Typography
          component={"h2"}
          color={"text.primary"}
          fontWeight={700}
          fontSize={40}
          className="page_title"
        >
          Notifications
        </Typography>
        <Grid container className="py-5">
          <div className="w-100 notification_containers">
            {notificationData && notificationData.length === 0 ? (
              <Typography
                component={"h2"}
                color={"text.primary"}
                fontWeight={500}
                fontSize={40}
                className="page_title"
                textAlign={"center"}
              >
                No Notification found
              </Typography>
            ) : (
              notificationData.map((item) => (
                <div key={item?._id} className="notification_list row ">
                  <div className="col-10 ">
                    <div className="li">
                      <div className="notification_text">
                        <h5>{item?.notificationHeading}</h5>
                        <p>{item?.notificationBody}</p>
                        <span>{fDateTime(item?.createdAt)}</span>
                      </div>
                      {item?.amount && (
                        <div className="notification_btn">
                          <button
                            onClick={() =>
                              navigate("/payment-method", {
                                state: {
                                  amount: item?.amount,
                                  propertyID: item?.propertyID,
                                  renterApplicationID:
                                    item?.renterApplicationID,
                                  notificationID: item?._id,
                                },
                              })
                            }
                            className="btn_common"
                          >
                            Pay Now
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="date_notification">
                      <span>{fDate(item?.createdAt)}</span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Grid>
      </div>
    </Container>
  );
};

export default UserNotifications;
