import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { useDispatch } from "react-redux";
import { maintainceRequestSlice } from "../../../redux/maintainceRequest/maintainceRequestSlice";

export default function MarkResolvedModal({ open, setOpen, detailData = {} }) {
  const dispatch = useDispatch();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          Marked Resolved
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          fontSize={18}
          fontWeight={700}
          color={"#07262E"}
          textAlign={"center"}
        >
          Maintenance request from{" "}
          {detailData?.renterDetails && detailData?.renterDetails[0]?.fullName}{" "}
          has been marked resolved, and a notification has been sent to{" "}
          {detailData?.renterDetails && detailData?.renterDetails[0]?.fullName}.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonComponent
          onClick={() => {
            setOpen(false);
            dispatch(maintainceRequestSlice.actions.setTheFilterValue());
          }}
          title={"Done"}
          sx={{
            marginLeft: "20px",
            marginBottom: "20px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
