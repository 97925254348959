export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export function emptyRows(page, rowsPerPage, arrayLength) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function descendingComparator(a, b, orderBy) {
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] === null) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({ inputData, comparator, filterName }) {
  console.log(inputData,"inputData")
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData?.filter(
      (user) => user?.name?.toLowerCase().indexOf(filterName?.toLowerCase()) !== -1
    );
  }

  return inputData;
}

export const ObjectToFormdata = (Json) => {
  console.log(Json,'Json')
  const formData = new FormData();
  Object.keys(Json).forEach((key) => {
    if (Array.isArray(Json[key])) {
      Json[key].forEach((item, index) => {
        return formData.append(`${key}[${index}]`, JSON.stringify(item));
      });
    } else {
      formData.append(key, Json[key]);
    }
  });

  return formData;
};

export const propertyType = [
  {
    label: "Detached Duplexes",
    value: "Detached Duplexes",
  },
  {
    label: "Semi-detached Duplexes",
    value: "Semi-detached Duplexes",
  },
  {
    label: "Detached Bungalows",
    value: "Detached Bungalows",
  },
  {
    label: "Semi-detached Bungalows",
    value: "Semi-detached Bungalows",
  },
  {
    label: "Terraced Bungalows",
    value: "Terraced Bungalows",
  },
  {
    label: "Terraced Duplexes",
    value: "Terraced Duplexes",
  },
  {
    label: "Block of Flats",
    value: "Block of Flats",
  },
  {
    label: "Studio Apartment",
    value: "Studio Apartment",
  },
  {
    label: "Mini Flat",
    value: "Mini Flat",
  },
  {
    label: "Office Space",
    value: "Office Space",
  },
  {
    label: "Shop",
    value: "Shop",
  },
  {
    label: "Warehouse",
    value: "Warehouse",
  },
  {
    label: "Open Space",
    value: "Open Space",
  },
  {
    label: "Complex",
    value: "Complex",
  },
  {
    label: "Plaza",
    value: "Plaza",
  },
  {
    label: "Mall",
    value: "Mall",
  },
];
export const listOfMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const cities = [
  "Aba South",
  "Arochukwu",
  "Bende",
  "Ikwuano",
  "Isiala Ngwa North",
  "Isiala Ngwa South",
  "Isuikwuato",
  "Obi Ngwa",
  "Ohafia",
  "Osisioma",
  "Ugwunagbo",
  "Ukwa East",
  "Ukwa West",
  "Umuahia North",
  "Umuahia South",
  "Umu Nneochi",
];
