import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "../../components/Button";
import AuthenticationLayout from ".";
import axios from "axios";
import { baseURL } from "../../utils/axios";
import { API_Endpoints } from "../../services/API_Endpoints";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";

const CreateNewPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useSelector((state) => state.AUTH);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    newPassword: {
      value: "",
      warning: false,
    },
    confirmNewPassword: {
      value: "",
      warning: false,
    },
  });
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({
      ...prev,
      [name]: {
        value: value,
        warning:
          name == "confirmNewPassword"
            ? value != prev.newPassword.value || value === ""
            : value === "",
      },
    }));
  };

  //onSUbmit validate the form data
  const onSubmit = (e) => {
    e.preventDefault();
    let data = { ...formValue };
    data = {
      ...data,
      newPassword: {
        ...data.newPassword,
        warning: data.newPassword.value == "",
      },
      confirmNewPassword: {
        ...data.confirmNewPassword,
        warning:
          data.confirmNewPassword.value != data.newPassword.value ||
          data.confirmNewPassword.value == "",
      },
    };
    setFormValue({ ...data });
    if (!data.newPassword.warning && !data.confirmNewPassword.warning) {
      createNewPasswordAPIIntigration();
    }
  };

  // api intigration for the create new password
  const createNewPasswordAPIIntigration = () => {
    setLoading(true);
    axios
      .post(
        baseURL + API_Endpoints.auth.RESET_PASSWORD,
        {
          id: forgotPassword.id,
          password: formValue.newPassword.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${forgotPassword?.accessToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          navigate("/login");
          NotificationManager.success("", res.data.message);
        }
        setLoading(false);
        console.log("ress", res);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.success("", err.message);
        console.log("err", err);
      });
  };

  return (
    <AuthenticationLayout>
      <form onSubmit={onSubmit}>
        <Typography
          fontSize={24}
          fontWeight={"bold"}
          color="#111111"
          className="text-center"
          mb={2}
        >
          Create new password
        </Typography>

        <Typography fontSize={16} color="#8D9B9F" className="text-center">
          This password should be different from the previous <br /> password.
        </Typography>

        <div className="mt-3">
          <div>
            <TextField
              fullWidth
              placeholder={"New Password"}
              label={"New Password"}
              type={"password"}
              name="newPassword"
              value={formValue?.newPassword?.value}
              onChange={(e) => onChange(e)}
            />
            {formValue.newPassword.warning && (
              <span className="text-danger">Password is required</span>
            )}
          </div>
          &nbsp;
          <div>
            {" "}
            <TextField
              fullWidth
              placeholder={"Confirm Password"}
              label={"Confirm Password"}
              type={"password"}
              name="confirmNewPassword"
              value={formValue?.confirmNewPassword?.value}
              onChange={(e) => onChange(e)}
            />
            {formValue.confirmNewPassword.warning && (
              <span className="text-danger">
                Both Password should be matched
              </span>
            )}
          </div>
        </div>
        <ButtonComponent
          title={"Set Password"}
          color={"secondary"}
          sx={{ width: "100%", mt: 4, height: 53 }}
          type={"submit"}
          loading={loading}
          disabled={loading}
        />
      </form>
    </AuthenticationLayout>
  );
};

export default CreateNewPassword;
