import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { CloseIcon, MenuIcon, ProfileIcon } from "../../assets/icons";
import { theme } from "../../assets/theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppLogo,
  ChatIcon,
  NotificationIcon,
  WalletIcon,
} from "../../assets/svg";
import { RetrieveLocalUser } from "../../utils/authService";
import { NotificationManager } from "react-notifications";
import { ROLES } from "../../utils/CONSTANTS";
const Header = ({ setOpen, open }) => {
  const navigate = useNavigate();
  const user = RetrieveLocalUser("user");
  console.log(user, "userrrrr");
  const location = useLocation();

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "white",
        height: 64,
        zIndex: 99999,
      }}
      elevation={0}
    >
      <Toolbar className="d-flex justify-content-between align-items-center">
        <div className=" d-flex align-items-center justify-content-center gap-3">
          <Link
            to={
              user?.role === ROLES.landlord
                ? "/landlord-dashboard"
                : user?.role === ROLES.propertyManager
                ? "/property-manager"
                : user?.role === ROLES.renter
                ? "/renter"
                : "/"
            }
          >
            <AppLogo />
          </Link>
          {user !== null && (
            <>
              <Typography fontWeight={500} height={20}>
                |
              </Typography>

              <Typography fontSize={18} fontWeight={500}>
                {user?.role === ROLES.propertyManager
                  ? ROLES.propertyManager
                  : user?.role === ROLES.renter
                  ? ROLES.renter
                  : ROLES.landlord}
              </Typography>
            </>
          )}
        </div>
        <div className="d-flex justify-content-evenly align-items-center gap-5 ">
          {user === null ? (
            <>
              <Typography
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
                onClick={() => navigate("/blogs")}
              >
                Blog
              </Typography>
              <Typography
                onClick={() => navigate("/property-management")}
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
              >
                Property Management
              </Typography>
              <Typography
                onClick={() => navigate("/login")}
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
              >
                Signup / Login
              </Typography>{" "}
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={() => {
                  if (user !== null) {
                    navigate("/add-property");
                    NotificationManager.info(
                      "",
                      "Please login first and add you property"
                    );
                  } else {
                    navigate("/login");
                  }
                }}
                sx={{
                  cursor: "pointer",
                  height: 64,
                  width: 147,
                  background: theme.palette.primary.main,
                }}
              >
                <Typography color="secondary" fontSize={16} fontWeight={400}>
                  Add Property
                </Typography>
              </Box>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center gap-4 phone-hide">
              <Typography
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
                onClick={() => navigate("/aboutUs")}
              >
                About Us
              </Typography>
              <Typography
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
                onClick={() => navigate("/blogs")}
              >
                Blog
              </Typography>
              <Typography
                sx={{ cursor: "pointer" }}
                color="secondary"
                fontSize={16}
                fontWeight={400}
                onClick={() => navigate("/contactUs")}
              >
                Contact Us
              </Typography>
              <Link to={"/chat"}>
                <ChatIcon />
              </Link>
              <Link to={"/wallet"}>
                <WalletIcon height={16} width={20} />
              </Link>
              <Link to={"/notifications"}>
                <NotificationIcon />
              </Link>
              <Link to="/profile">
                {user.picture !== "" ? (
                  <img
                    style={{
                      height: 32,
                      width: 32,
                      borderRadius: "50%",
                      border: "2px solid #C8D736",
                    }}
                    src={user.picture}
                    alt="no-img"
                  />
                ) : (
                  <ProfileIcon />
                )}
              </Link>
              {user?.role === ROLES.landlord ? (
                <Box
                  onClick={() => navigate("/add-property")}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    cursor: "pointer",
                    height: 64,
                    width: 147,
                    background: theme.palette.primary.main,
                  }}
                >
                  <Typography color="secondary" fontSize={16} fontWeight={400}>
                    Add Property
                  </Typography>
                </Box>
              ) : null}
            </div>
          )}
          {open ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(false)}
            >
              {<CloseIcon />}
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
