import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { routesConfig } from "../../routes/routesConfig";
import { useNavigate, useLocation } from "react-router-dom";
import { DownArrow, UpArrow } from "../../assets/svg";
import SidebarFooter from "./SidebarFooter";
import { RetrieveLocalUser } from "../../utils/authService";
const Sidebar = ({ open, setOpen, setLogoutDialog }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubSidebar, setOpenSubSidebar] = useState({});
  const user = RetrieveLocalUser("user");
  console.log(routesConfig, "routesConfig");
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: 415,
          zIndex: 0,
        },
      }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <List
        className="main-sidebar"
        sx={{
          height: "100vh",
          display: "flex",
          marginTop: 10,
          flexDirection: "column",
        }}
      >
        {routesConfig.map((item, index) => {
          if (!user && item.role[0] === "public") {
            return (
              <ListItem
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(item.link);
                  setOpen(false);
                }}
                style={{
                  marginTop:"10px",
                  marginLeft:"20px",
                  color:"#000"
                }}
              >
                <Typography fontWeight={400}> {item?.title}</Typography>
              </ListItem>
            );
          } else {
            const getBoldRoleTitle = (title) => {
              if (title.startsWith("Switch to")) {
                const [prefix, role] = item.title.split(" to ");
                return (
                  <div className="d-flex align-items-center">
                    <Typography width={"100%"}>
                      {prefix} to<b> {role}</b>
                    </Typography>
                  </div>
                );
              }
              return title;
            };
            return (
              item.role.includes(user?.role) && (
                <>
                  <ListItem
                    // LinkComponent={"a"}
                    onClick={(e) => {
                      if (item?.children?.length > 0) {
                        setOpenSubSidebar((prevState) => ({
                          ...prevState,
                          [index]: !prevState[index],
                        }));
                      }
                      if (item?.isLoggedOutItem) {
                        setLogoutDialog(true);
                      }
                      if (item?.link === "/property-manager") {
                        localStorage.setItem("role", "propertyManager");
                      } else if (item?.link === "/renter") {
                        localStorage.setItem("role", "renter");
                      } else if (item?.link === "/landlord-dashboard") {
                        localStorage.setItem("role", "landlord");
                      }

                      if (!item?.isLoggedOutItem && item?.link) {
                        navigate(item?.link);
                      }
                      // open the sidebar as per the main values doesnot have the children array to expand
                      if (!item?.children?.length > 0) {
                        setOpen(false);
                      }
                    }}
                    className=" d-flex justify-content-between "
                    sx={{ cursor: "pointer" }}
                    key={index}
                  >
                    <div className="d-flex align-items-center w-100 px-5">
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        sx={{
                          fontSize: 18,
                          color:
                            location.pathname === item.link
                              ? "#009DA6"
                              : "#07262E",
                          fontWeight:
                            location.pathname === item.link ? "bold" : "",
                        }}
                        primary={getBoldRoleTitle(item.title)}
                      />
                      {item?.children?.length > 0 ? (
                        <>
                          {openSubSidebar[index] ? <DownArrow /> : <UpArrow />}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </ListItem>
                  <Collapse in={openSubSidebar[index]}>
                    <List
                      sx={{ overflow: "auto !important", height: "auto" }}
                      component="div"
                    >
                      {item?.children &&
                        item?.children?.length > 0 &&
                        item.children.map((subMenu, i) => {
                          return (
                            subMenu.role.includes(user?.role) && (
                              <ListItem
                                sx={{ pl: "20%", cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(subMenu?.link);
                                  setOpen(false);
                                }}
                                key={i}
                              >
                                <ListItemIcon>{subMenu?.icon}</ListItemIcon>
                                <ListItemText
                                  primary={subMenu?.title}
                                  sx={{
                                    fontSize: 18,
                                    color:
                                      location.pathname === subMenu.link
                                        ? "#009DA6"
                                        : "#07262E",
                                    fontWeight:
                                      location.pathname === subMenu.link
                                        ? "bold"
                                        : "",
                                  }}
                                />
                              </ListItem>
                            )
                          );
                        })}
                    </List>
                  </Collapse>
                </>
              )
            );
          }
        })}
      </List>
      <div style={{ paddingLeft: "20%" }}>
        <SidebarFooter />
      </div>
    </Drawer>
  );
};

export default Sidebar;
