import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import "./style.css";
import phnIcon from "../../../assets/icons/call.png";
import mailIcon from "../../../assets/icons/mail.png";
import appReviewer from "../../../assets/images/app_reviewer.png";
import { rentedProperties } from "../../renter/rentedProperties";
import PropertyDetailCard from "../../../components/propertyDetailCard";
import { PropertyPropsObjEnum } from "../../../utils/appConstants";
import {
  GetMangerDetailService,
  GetMangerPropertyDataService,
} from "../../../services/landlorPropertyMangerService";
import { useParams } from "react-router-dom";

const ManagerDetails = () => {
  const { id } = useParams();
  const [propertyMangerData, setPropertyMangerData] = useState({
    detail: {},
    propertyAssigned: [],
  });

  //  get the propertymanger detail
  const getPropertyManagerDetail = async () => {
    const getData = await GetMangerDetailService(id);
    const getPropertyData = await GetMangerPropertyDataService(id);
    console.log("getDtaa", getData);
    if (getData?.status) {
      setPropertyMangerData((prev) => ({
        ...prev,
        detail: getData?.data,
      }));
    }
    if (getPropertyData?.status) {
      setPropertyMangerData((prev) => ({
        ...prev,
        propertyAssigned: getPropertyData?.data,
      }));
    }
  };
  useEffect(() => {
    getPropertyManagerDetail();
  }, []);
  return (
    <div>
      <Container maxWidth={"xl"}>
        <Box>
          <Typography
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
            marginBottom={"20px"}
            className="title_page pt-4 phone-heading-30"
          >
            Property Manager
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={6} className="pb-5">
            <Grid item md={6} xs={12}>
              <div className="manager_details_card">
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={6}
                  alignItems={"center"}
                  sx={{
                    backgroundImage: "url(/images/header-back.png)",
                    height: 250,
                    backgroundRepeat: "repeat",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    height={167}
                    width={167}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderRadius={"50%"}
                    border={"1px solid white"}
                    className="user-avatar"
                  >
                    <img
                      src={propertyMangerData?.detail?.picture}
                      style={{
                        height: "155px",
                        width: "155px",
                        borderRadius: "50%",
                      }}
                    />
                  </Box>
                  <div>
                    <div className="d-flex align-items-center flex-wrap  gap-2">
                      <Typography
                        color={"white"}
                        variant="h3"
                        className="phone-heading-30"
                        fontSize={"17px"}
                      >
                        {propertyMangerData?.detail?.fullName}
                      </Typography>

                      <img
                        className="img-cover"
                        src={"/images/user-verified.png"}
                        style={{ height: 18, width: 62 }}
                      />
                    </div>

                    <Typography
                      color="#C8D736"
                      fontSize={18}
                      fontWeight={"bold"}
                      variant="body1"
                    >
                      {propertyMangerData?.detail?.role}
                    </Typography>
                    <Typography
                      color="#C8D736"
                      fontSize={18}
                      fontWeight={"bold"}
                      variant="body1"
                    >
                      Chat
                    </Typography>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item md={6} xs={12} className="">
              <div className="contact_info">
                <h4>Contact Info</h4>
                <ul>
                  <li>
                    <img src="/assets/icons/call.png" alt="" />
                    {propertyMangerData?.detail?.phone}
                  </li>
                  <li>
                    <img src="/assets/icons/RentedProperties.png" alt="" />
                    Sam Okwaraji Street, 900108 Gwarinpa, Nigeria
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box className="py-5 phone-padding-0">
          <Grid container className="manager_property_about">
            <Grid md={12} xs={12}>
              <h5 className="h5_title mb-3">About</h5>
            </Grid>
            <Grid md={3}>
              <div class="manager_small_details">
                <b>Employer Address: </b>
                <h5>david@gmail.com</h5>
              </div>
            </Grid>
            <Grid md={3}>
              <div class="manager_small_details">
                <b>Age </b>
                <h5>28</h5>
              </div>
            </Grid>
            <Grid md={3}>
              <div class="manager_small_details">
                <b>Address: </b>
                <h5>Apt. 560 950 Parker Mount, North Imogenetown</h5>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box className="assigned_property ">
          <h3>Assigned Properties</h3>
          {/* already maked this section  */}

          {propertyMangerData?.propertyAssigned.map((x, i) => (
            <PropertyDetailCard
              data={x}
              buttonShow={PropertyPropsObjEnum.terminateBtnShow}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default ManagerDetails;
