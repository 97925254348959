import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const GetInsepctionsListService = () => {
  return axiosInstance
    .get(API_Endpoints.inspections.GET_INSPECTION_LIST)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const AddInspectionRequest = (values) => {
  return axiosInstance
    .post(API_Endpoints.inspections.GET_INSPECTION_LIST, values)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const CancelInspectionRequestByRenterService = (values) => {
  return axiosInstance
    .post(API_Endpoints.inspections.CANCEL_INSPECTION_REQ_BY_RENTER, values)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetTheInspectionReqForRelatedProperty = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const GetInspectionDetailById = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.inspections.GET_INSPECTION_LIST}/by-id?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const editInspectionRequest = (values) => {
  return axiosInstance
    .post(API_Endpoints.inspections.editInspection, values)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};