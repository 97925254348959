import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import "./index.css";
import profile from "../../../assets/images/transactionprofile.png";
import { GetTransActionDetailByID } from "../../../services/transactionService";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../../utils/helperFunctionForValidte";
import { fDateTime } from "../../../utils/format-time";
import { RetrieveLocalUser } from "../../../utils/authService";
import { ROLES } from "../../../utils/CONSTANTS";
export default function TransactionDetail() {
  const { id } = useParams();
  const user = RetrieveLocalUser("user");
  const [transactionDetail, setTransactionDetail] = useState({
    _id: "",
    renter_details: {
      _id: "",
      fullName: "",
      picture: "",
    },
    type: "",
    propertyID: null,
    renterID: "",
    landlordID: null,
    status: "",
    amount: 0,
    date: "",
    createdAt: "",
    updatedAt: "",
    property_details: {},
    landlord_details: {},
    payment_mode: "",
    intentID: "",
  });
  // get the tranaction detail from the backend
  const getTheTransactionDetail = async () => {
    const getData = await GetTransActionDetailByID(id);
    console.log("getDTA", getData);
    setTransactionDetail(getData?.data);
  };

  useEffect(() => {
    getTheTransactionDetail();
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography
        fontSize={40}
        fontWeight={700}
        color={theme.palette.text.primary}
        className="mt-4"
      >
        Transaction Details
      </Typography>

      <Card
        className="p-3"
        sx={{ boxShadow: "5px 5px 19px 4px #0000000d", borderRadius: 2 }}
      >
        <Grid
          container
          className="d-flex justify-content-between align-items-center"
          spacing={4}
        >
          <Grid item md={6}>
            <div className="backgroundImg  p-3">
              <div className="d-flex justify-content-between align-items-center flex-column">
                <Typography fontSize={26} fontWeight={"bold"} color={"white"}>
                  {formatCurrency(transactionDetail?.amount)}
                </Typography>
                <Typography fontSize={12} color="#C8D736" fontWeight={500}>
                  Successful transaction
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item md={6} mt={1}>
            <Card
              className="d-flex gap-3 p-4"
              sx={{ boxShadow: "5px 5px 19px 4px #0000000d" }}
            >
              <img
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                src={transactionDetail?.renter_details?.picture}
              />

              <div>
                <Typography fontWeight={700} fontSize={14}>
                  {transactionDetail?.wallet
                    ? "Money added to wallet"
                    : user?.role === ROLES?.renter
                    ? `Rent sent to ${transactionDetail?.landlord_details?.fullName}`
                    : user?.role === ROLES?.landlord ||
                      user?.role === ROLES.propertyManager
                    ? `received from ${transactionDetail?.renter_details?.fullName}`
                    : null}
                </Typography>
                <Typography fontWeight={400} fontSize={14} color={"#07262E"}>
                  {fDateTime(transactionDetail?.createdAt)}
                </Typography>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} p={3}>
            <Typography fontSize={16} fontWeight={700} color={"#1B1B1B"}>
              Transaction Details
            </Typography>
            {Object.keys(
              transactionDetail?.property_details
                ? transactionDetail?.property_details
                : {}
            )?.length > 0 && (
              <Typography
                marginTop={2}
                marginBottom={2}
                fontSize={14}
                fontWeight={400}
                color={"#596E73"}
              >
                Property name
              </Typography>
            )}
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={400}
              color={"#596E73"}
            >
              Description
            </Typography>
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={400}
              color={"#596E73"}
            >
              Date & time
            </Typography>
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={400}
              color={"#596E73"}
            >
              Payment mode
            </Typography>
            <Typography fontSize={14} fontWeight={700} color={"#596E73"}>
              Total amount
            </Typography>
          </Grid>

          <Grid item md={6} className="text-end p-3">
            {Object.keys(
              transactionDetail?.property_details
                ? transactionDetail?.property_details
                : {}
            )?.length > 0 && (
              <Typography
                marginTop={4}
                fontSize={16}
                fontWeight={700}
                color={"#1B1B1B"}
              >
                {transactionDetail?.property_details
                  ? transactionDetail?.property_details?.propertyName
                  : null}
              </Typography>
            )}
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={700}
              color={"#07262E"}
            >
              Rent
            </Typography>
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={700}
              color={"#07262E"}
            >
              {fDateTime(transactionDetail?.createdAt)}
            </Typography>
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={700}
              color={"#07262E"}
            >
              via{" "}
              {transactionDetail?.wallet
                ? "wallet"
                : transactionDetail?.payment_mode}
            </Typography>
            <Typography
              marginTop={2}
              marginBottom={2}
              fontSize={14}
              fontWeight={700}
              color={"#07262E"}
            >
              {formatCurrency(transactionDetail?.amount)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <div className="mt-4 text-end">
        <Button sx={{ textTransform: "none" }} variant="text">
          Download Invoice
        </Button>
      </div>
    </Container>
  );
}
