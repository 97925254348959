import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Button";
import StripeModal from "../../components/stripePopup/stripe";
import { RetrieveLocalUser } from "../../utils/authService";
import { onChangeToNumber } from "../../utils/helperFunctionForValidte";

const WalletPopup = ({ open, setOpen, type }) => {
  const navigate = useNavigate();
  const [stripeModal, setStripeModal] = useState(false);
  const user = RetrieveLocalUser("user");
  const [formDataValue, setFormDataValue] = useState({
    amount: { value: "", warning: false },
  });

  const onClickAddAmount = () => {
    let data = { ...formDataValue };
    data = {
      ...data,
      amount: { ...data.amount, warning: Number(data.amount.value) < 100 },
    };
    setFormDataValue({ ...data });
    if (!data?.amount?.warning) {
      navigate("/payment-method", {
        state: {
          userID: user?._id,
          amount: Number(formDataValue?.amount?.value),
          wallet: "true",
        },
      });
    }
  };

  useEffect(() => {
    if (!stripeModal) {
      setOpen(false);
    }
  }, [stripeModal]);
  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className="text-center"
        fontSize={40}
        fontWeight={"bold"}
        color={"text.primary"}
      >
        {type === "withdraw" ? "Withdraw Amount" : "Top-up Wallet"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          className="mt-2"
          sx={{ width: 727 }}
          value={formDataValue.amount.value}
          placeholder={
            type === "withdraw"
              ? "Enter Withdraw Amount"
              : "Enter top-up amount"
          }
          onChange={(e) => {
            setFormDataValue((prev) => ({
              ...prev,
              amount: {
                value: onChangeToNumber(e.target.value),
                warning: false,
              },
            }));
          }}
        />
        {formDataValue?.amount?.warning && (
          <span className="w-100 text-danger">
            Enter the amount grater than 100 dollar
          </span>
        )}
      </DialogContent>
      <DialogActions className="d-flex justify-content-center align-items-center">
        <ButtonComponent
          title={type === "withdraw" ? "Withdraw Amount" : "Add Money"}
          variant={"primary"}
          onClick={() => onClickAddAmount()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default WalletPopup;
