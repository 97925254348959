import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Radio,
  Checkbox,
} from "@mui/material";
import Iconify from "../../components/iconify";
import DeleteSuccessFullyModal from "../../components/deleteSuccessFullyModal";
import { useDispatch } from "react-redux";
import {
  paymentInitialState,
  paymentOptionSlice,
} from "../../redux/paymentOption/paymentOptionSlice";
import { deteleTheCardService } from "../../services/paymentGatewayServices";

const CardTable = ({ cardDataArr }) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [cardArr, setCardArr] = useState([]);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState({
    delete: false,
  });
  // delete the card
  const deleteTheCard = async () => {
    setLoading((prev) => ({
      ...prev,
      delete: true,
    }));
    const deleteData = await deteleTheCardService(values?.id);
    if (deleteData?.status) {
      setDeleteModal(false);
      dispatch(
        paymentOptionSlice.actions.setTheGetApiModalCallOntheDeleteOrAddCard()
      );
    }
    setLoading((prev) => ({
      ...prev,
      delete: false,
    }));
  };

  // onChange
  /**
   *
   * @param {*} e
   * @param {number} index of the array of the card
   * @param {Object} cardValue that contain the info of the card with like payment intent id
   */
  const onChange = (e, index, cardValue) => {
    let data = cardArr.map((item, cardIndex) => {
      return {
        ...item,
        checked: cardIndex === index ? e.target.checked : false,
      };
    });
    setCardArr(data);
    console.log("e", e.target.checked, index, cardValue);
    e.target.checked
      ? dispatch(paymentOptionSlice.actions.setTheSavedCardDetail(cardValue))
      : dispatch(
          paymentOptionSlice.actions.setTheSavedCardDetail(
            paymentInitialState.CARD_DETAIL
          )
        );
  };

  useEffect(() => {
    const data = cardDataArr.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });
    setCardArr(data);
  }, [cardDataArr]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select Card for Payment</TableCell>
              <TableCell>Card Number</TableCell>
              <TableCell>Exp Month</TableCell>
              <TableCell>Exp Year</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardArr &&
              cardArr.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={row.checked}
                      name="payment"
                      onChange={(e) => onChange(e, index, row)}
                    />
                  </TableCell>
                  <TableCell>xxxxxxxxxxxx{row.last4}</TableCell>
                  <TableCell>{row.exp_month}</TableCell>
                  <TableCell>{row.exp_year}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setDeleteModal(true);
                        setValues(row);
                      }}
                    >
                      <Iconify icon="mingcute:delete-line" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteSuccessFullyModal
        open={deleteModal}
        setOpen={setDeleteModal}
        headingAndText={{
          mainHeading: "Are you sure you want delete this card",
          middleText:
            "This action cannot be undone. Once deleted, the card will be permanently removed from your account",
        }}
        onCLick={deleteTheCard}
        loading={loading.delete}
      />
    </>
  );
};

export default CardTable;
