import { AddPropertyIcon, RentHistoryIcon } from "../assets/icons";
import {
  Calender,
  ElectricityIcon,
  FavouriteIcon,
  HomeIcon,
  InspectionIcon,
  InviteRenterIcon,
  LandlordIcon,
  LeaseIcon,
  LocationIcon,
  LogoutIcon,
  MaintenanceIcon,
  ManagerProperty,
  MyListingIcon,
  MyRenterIcon,
  PercentageIcon,
  PropertMangerIcon,
  PropertyManagerIcon,
  ReferAndEarn,
  RentApplicationIcon,
  RentTransactionIcon,
  SupportIcon,
  SwitchToRenter,
  TransactionHistoryIcon,
} from "../assets/svg";
import { ROLES } from "../utils/CONSTANTS";
export const routesConfig = [
  {
    title: "About Us",
    link: "/aboutUs",
    role: ["public"],
  },

  {
    title: "Careers",
    link: "/careers",
    role: ["public"],
  },
  {
    title: "Testimonials",
    link: "/testimonials",
    role: ["public"],
  },
  {
    title: "Faq",
    link: "/faq",
    role: ["public"],
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
    role: ["public"],
  },
  {
    title: "Data Protection",
    link: "/data-protection",
    role: ["public"],
  },
  {
    title: "Rented Properties",
    link: "/rented-properties",
    icon: <HomeIcon />,
    role: [ROLES.renter],
  },
  {
    title: "My Inspections",
    link: "/my-inspections",
    role: [ROLES.renter],
    icon: <InspectionIcon />,
  },
  {
    title: "Rent Applications",
    link: "/rent-applications",
    role: [ROLES.renter],
    icon: <RentApplicationIcon />,
  },
  {
    title: "Transaction History",
    link: "/transaction-history",
    role: [ROLES.renter],
    icon: <TransactionHistoryIcon />,
  },
  {
    title: "Favourites",
    link: "/favourite",
    role: [ROLES.renter],
    icon: <FavouriteIcon />,
  },

  {
    title: "Maintenance Requests",
    link: "/maintenance-requests",
    role: [ROLES.renter],
    icon: <MaintenanceIcon />,
  },
  {
    title: "Electricity Recharge",
    link: "/electricity-recharge",
    role: [ROLES.renter],
    icon: <ElectricityIcon />,
  },
  {
    title: "My Listings",
    // link: "",
    role: [ROLES.propertyManager, ROLES.landlord],
    icon: <MyListingIcon />,
    children: [
      {
        title: "My Properties",
        link: "/my-properties",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <ManagerProperty />,
      },
      {
        title: "Inspection Requests",
        link: "/inspection-requests",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <LocationIcon />,
      },
      {
        title: "Manage Calender",
        link: "/manage-calender",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <Calender />,
      },
      {
        title: "Add Property",
        link: "/add-property",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <AddPropertyIcon />,
      },
    ],
  },
  {
    title: "Manage Property",
    link: "",
    role: [ROLES.propertyManager, ROLES.landlord],
    icon: <ManagerProperty />,
    children: [
      {
        title: "My Renters",
        link: "/my-renters",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <MyRenterIcon />,
      },

      {
        title: "Rent Transactions",
        link: "/rent-transaction",

        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <RentTransactionIcon />,
      },
      {
        title: "All Transactions",
        link: "/rent-transactions-history",
        role: [ROLES.propertyManager, ROLES.landlord],
        icon: <RentTransactionIcon />,
      },
      {
        title: "Maintenance Requests",
        link: "/maintenance-request",
        role: [ROLES.landlord, ROLES.propertyManager],
        icon: <SupportIcon />,
      },
    
    ],
  },
  {
    title: "Rent History",
    link: "/rent-history",
    role: ["propertyManger"],
    icon: <RentHistoryIcon />,
  },
  {
    title: "Applications Received",
    link: "/applications-recieved",
    role: [ROLES.propertyManager, ROLES.landlord],
    icon: <MyListingIcon />,
  },
  {
    title: "Property Managers",
    link: "/property-managers",
    role: [ROLES.landlord],
    icon: <PropertMangerIcon />,
  },
  {
    title: "Lease Agreement",
    link: "lease-agreement",
    role: [ROLES.renter,ROLES.propertyManager, ROLES.landlord],
    icon: <LeaseIcon />,
  },
  {
    title: "Refer & Earn",
    link: "/refer-and-earn-page",
    role: [ROLES.propertyManager, ROLES.landlord],
    icon: <ReferAndEarn />,
  },
  {
    title: "Commission",
    link: "/commission",
    role: [ROLES.propertyManager],
    icon: <PercentageIcon />,
  },
  {
    title: "Support",
    link: "/support",
    role: [ROLES.renter, ROLES.propertyManager, ROLES.landlord],
    icon: <SupportIcon />,
  },
  {
    title: "Switch to Landlord",
    link: "/landlord-dashboard",
    role: [ROLES.renter, ROLES.propertyManager],
    icon: <PropertyManagerIcon />,
  },
  {
    title: "Switch to Renter",
    link: "/renter",
    role: [ROLES.propertyManager, ROLES.landlord],
    icon: <SwitchToRenter />,
  },
  {
    title: "Switch to Property Manager",
    link: "/property-manager",
    role: [ROLES.renter, ROLES.landlord],
    icon: <PropertyManagerIcon />,
  },
  {
    title: "Logout",
    link: "/login",
    role: [ROLES.renter, ROLES.propertyManager, ROLES.landlord],
    icon: <LogoutIcon />,
    isLoggedOutItem: true,
  },

];
