import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statusChanges: false,
};

export const reviewRatingSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    setChangeStatusDeleteApprove: (state) => {
      let data = { ...state };
      data = {
        ...data,
        statusChanges: !data.statusChanges,
      };
      return data;
    },
  },
});
