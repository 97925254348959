import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import React from "react";

const ButtonComponent = ({
  sx,
  title,
  className,
  color,
  onClick,
  disabled,
  type,
  loading,
}) => {
  return (
    <Button
    type={type}
      disabled={disabled}
      loading={loading}
      variant="contained"
      className={className}
      onClick={onClick}
      loadingPosition="center"
      color={color || "primary"}
      size="large"
      sx={{
        ...sx,
        fontWeight: 500,
        textTransform: "none",
        ...(!loading && {
          "&.Mui-disabled": {
            backgroundColor: "#E6EDA3",
            color: "#618D9D",
          },
        }),
      }}
    >
      {loading ? <CircularProgress size={"1.5rem"} /> : title}
    </Button>
  );
};

export default ButtonComponent;
