import moment from "moment";

export function getImageUrl(image, type = "blogs") {
  return `https://api.rentranzact.com/${type}/${image}`;
}

export function getFormattedDate(dateString) {
  const formattedDate = moment(dateString).format("DD MMM YYYY");

  return formattedDate;
}

export async function convertImgToFileObj(imageUrl) {
  try {
    // Extract the file name from the URL
    const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting image URL to File object:", error);
    return null;
  }
}

export const getQueryParams = (obj) => {
  const queryString = Object.entries(obj)
    .filter(
      ([key, value]) => value !== "" && value !== undefined && value !== null
    )
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return queryString;
};

export const getAdminRoles = (roles) => {
  return Object.keys(roles).map((key) => roles[key]);
};

export function getFormattedTime(dateString) {
  const formattedDate = moment(dateString).format("HH:MM A");

  return formattedDate;
}
