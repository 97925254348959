import { Box, Skeleton } from "@mui/material";
import React from "react";

const SkeletonLoading = () => {
  return (
    <Box sx={{ width: 210, marginRight: 0.5, my: 5 }}>
      <Skeleton variant="rectangular" width={400} height={280} />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton />
        <Skeleton width="60%" />
        <Skeleton width="60%" />
      </Box>
    </Box>
  );
};

export default SkeletonLoading;
