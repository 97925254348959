import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/theme";
import "./style.css";
import phnIcon from "../../../assets/icons/call.png";
import mailIcon from "../../../assets/icons/mail.png";
import appReviewer from "../../../assets/images/app_reviewer.png";
import { useNavigate } from "react-router-dom";

const ReferAndEarnLandlordPM = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/manager-details");
  };
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div>
      <Box className="refer_and_earn_section px-0">
        <Container maxWidth={"xl"}>
          <div className="row">
            <div className="col-lg-5">
              <div className="refer_earn_card">
                <Typography
                  fontSize={40}
                  fontWeight={700}
                  color={theme.palette.text.primary}
                  marginBottom={"20px"}
                  className="title_page pt-4"
                >
                  Refer & earn
                </Typography>
                <h4>Earn referral bonus by referring a Property</h4>
                <p className="refer_earn_text">
                  {" "}
                  Whether you're a real estate agent or just looking to earn
                  extra income, refer properties to Rentranzact and get paid
                  when they’re rented.
                </p>
                <div className="copy_url_id">
                  <p className="label_id">Your referral id</p>
                  <div className="d-flex">
                    <div className="border_copy">
                      <span>78422904</span>
                      <img src="/assets/icons/copy.png" alt="" />
                    </div>
                    <button className="btn_common">Share</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Box>
    </div>
  );
};

export default ReferAndEarnLandlordPM;
