import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DownloadIcon, eyeViewIcon } from "../../assets/icons";
import ButtonComponent from "../../components/Button";
import TransactionhistoryTable from "../../components/transactionhistory";
import WalletPopup from "./walletPopup";
import { useNavigate } from "react-router-dom";
import { WalletIcon } from "../../assets/svg";
import { RetrieveLocalUser } from "../../utils/authService";
import { creditDebitValue, ROLES } from "../../utils/CONSTANTS";
import { fDateTime } from "../../utils/format-time";
import { GetTransactionListData } from "../../services/transactionService";
import { GetWalletData } from "../../services/walletServices";
import { API_Endpoints } from "../../services/API_Endpoints";
import { formatCurrency } from "../../utils/helperFunctionForValidte";
const columns = [
  { title: "Date & Time", key: "date" },
  { title: "Description", key: "description" },
  { title: "Payment mode", key: "debit_credit" },
  { title: "Amount", key: "amount" },
  { title: "Action", key: "action" },
];

const Wallet = () => {
  const user = RetrieveLocalUser("user");
  const [openTopupDialog, setOpenTopupDialog] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [withdrawDialog, setWithdrawDialog] = useState(false);
  const [walletDetail, setWalletDetail] = useState({
    Deposited: 0,
    Withdrawn: 0,
    walletPoints: 0,
  });
  const [dialogType, setDialogType] = useState("");
  const navigate = useNavigate();

  const propertyManagerCards = [
    {
      title: "Deposited",
      amount: "₦ 10,400",
      cardColor: "#e6f5f6",
      percentage: "80%",
    },
    {
      title: "Rent Collected",
      amount: "₦ 40,15,890",
      cardColor: "#eaffd2",
      percentage: "10%",
    },
    {
      title: "Earned Rewards",
      amount: "₦ 3,550",
      cardColor: "#eef3c1",
      percentage: "10%",
    },
    {
      title: "Commission",
      amount: "₦ 3,550",
      cardColor: "#eef3c1",
      percentage: "10%",
    },
    {
      title: "Withdrawn",
      amount: "₦ 0",
      cardColor: "#b6cad2",
      percentage: "0%",
    },
  ];

  // get the wallet detail from the backend
  const getWalletDetails = async () => {
    const getData = await GetWalletData();
    if (getData?.status) {
      setWalletDetail(getData?.data);
    }
    console.log("getData", getData);
  };

  //GET THE DATA OF TRANSACTION FROM THE BACKEND CALL THE TRANSACTION SERVICE
  const getTheTransaction = async () => {
    try {
      // setLoading(true);
      const getData = await GetTransactionListData(
        API_Endpoints.transaction.getTransactionist
      );
      console.log("--get", getData);
      if (getData?.status) {
        const data = getData?.data?.map((item) => {
          return {
            date: fDateTime(item?.createdAt),
            transactionId: "ORD-1562792778679",
            description: `Transfer to ${item?.landlord}`,
            debit_credit:
              item?.type === creditDebitValue.credit ? "Credit" : "Debit",
            status: "Success",
            amount: formatCurrency(item?.amount),
            type: item?.type,
            action: (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/transaction-history-Detail/${item._id}`)
                  }
                >
                  {eyeViewIcon()}
                </span>
                {DownloadIcon()}
              </>
            ),
          };
        });
        setTransactionData(data);
        console.log("rere", data);
        // setLoading(false);
      }
    } catch (error) {
      console.log("exception error", error);
    }
  };
  useEffect(() => {
    if (!openTopupDialog) {
      getTheTransaction();
      getWalletDetails();
    }
  }, [openTopupDialog]);

  return (
    <Container maxWidth="xl">
      <div className="py-4">
        <Typography
          component={"h2"}
          color={"text.primary"}
          fontWeight={700}
          fontSize={40}
        >
          Wallet
        </Typography>

        <Card
          elevation={0}
          sx={{
            p: 2,
            borderRadius: 3,
            mt: 3,
            boxShadow: "5px 5px 19px 4px #0000000d",
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex  gap-4">
              <WalletIcon width={50} height={40} />

              <div>
                <Typography color="#C8D736" fontSize={14} fontWeight={500}>
                  Wallet Balance
                </Typography>
                <Typography
                  className="phone-heading-30"
                  component={"h2"}
                  color={"text.secondary"}
                  fontWeight={"bold"}
                  fontSize={40}
                >
                  {formatCurrency(walletDetail?.walletPoints)}
                </Typography>
              </div>
            </div>

            <div className="d-flex gap-2 flex-wrap wallet-btn">
              <ButtonComponent
                variant="secondary"
                sx={{
                  background: "transparent",
                  border: "2px solid #C8D736",
                  borderRadius: 3,
                  height: 69,
                  width: 227,
                }}
                title={"Withdraw Amount"}
                onClick={() => {
                  setWithdrawDialog(true);
                  setDialogType("withdraw");
                }}
              />
              <ButtonComponent
                sx={{
                  borderRadius: 3,
                  height: 69,
                  width: 227,
                }}
                variant="secondary"
                title={"Top-up Wallet"}
                onClick={() => {
                  setOpenTopupDialog(true);
                  setDialogType("");
                }}
              />
            </div>
          </div>
          {user?.role === ROLES.propertyManager ? (
            <Grid container spacing={3} py={3}>
              {propertyManagerCards.map((x, i) => {
                return (
                  <Grid item md={2.4}>
                    <Box
                      key={i}
                      sx={{
                        p: 2,
                        borderRadius: 3,
                        background: x.cardColor,
                        "@media (min-width:900px)": {
                          width: "100%",
                        },
                      }}
                      display={"flex"}
                      gap={2}
                    >
                      <div
                        style={{ border: "5px solid rgb(0 153 161)" }}
                        className="step-left-text d-flex justify-content-center align-items-center"
                      >
                        <b>{x.percentage}</b>
                      </div>
                      <div>
                        <Typography
                          fontWeight={"bold"}
                          fontSize={18}
                          color={"rgb(7 38 46)"}
                        >
                          {x.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "14px", sm: "14px", xs: "12px" },
                          }}
                          //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                        >
                          {x.amount}
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <div className="d-flex justify-content-between align-items-center gap-3 mt-3 flex-wrap">
              <Box
                sx={{
                  p: 2,
                  borderRadius: 3,
                  background: "#e6f5f6",
                  "@media (min-width:900px)": {
                    width: "100%",
                  },
                }}
                display={"flex"}
                gap={2}
              >
                <div
                  style={{ border: "5px solid rgb(0 153 161)" }}
                  className="step-left-text d-flex justify-content-center align-items-center"
                >
                  <b>80%</b>
                </div>
                <div>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={18}
                    color={"rgb(7 38 46)"}
                  >
                    {"Deposited"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                    //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                  >
                   {formatCurrency(walletDetail?.Deposited)}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  p: 2,
                  borderRadius: 3,
                  background: "#b6cad2",
                  "@media (min-width:900px)": {
                    width: "100%",
                  },
                }}
                display={"flex"}
                gap={2}
              >
                <div
                  style={{ border: "5px solid rgb(0 153 161)" }}
                  className="step-left-text d-flex justify-content-center align-items-center"
                >
                  <b>10%</b>
                </div>
                <div>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={18}
                    color={"rgb(7 38 46)"}
                  >
                    {"Withdrawn"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                    //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                  >
                    ₦ {walletDetail?.Withdrawn}
                  </Typography>
                </div>
              </Box>
            </div>
          )}
        </Card>

        <div className="my-4">
          <Typography color="text.primary" fontSize={24} fontWeight={500}>
            All Transactions
          </Typography>

          <div className="mt-3">
            <TransactionhistoryTable
              tableHeader={columns}
              tableRow={transactionData}
            />
          </div>
        </div>
      </div>
      <WalletPopup
        open={openTopupDialog}
        setOpen={setOpenTopupDialog}
        type={dialogType}
      />
      <WalletPopup
        open={withdrawDialog}
        setOpen={setWithdrawDialog}
        type={dialogType}
      />
    </Container>
  );
};

export default Wallet;
