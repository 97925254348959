import { Navigate } from "react-router-dom";
import { RetrieveLocalUser } from "../../utils/authService";

const PrivateRoute = ({ children }) => {
  const user = RetrieveLocalUser("user");

  return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
