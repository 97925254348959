import * as React from "react";
import PropTypes from "prop-types";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Button } from "@mui/base/Button";
import { useSpring, animated } from "@react-spring/web";
import { NotificationManager } from "react-notifications";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ButtonComponent from "../Button";
import stripeIcon from "../../assets/icons/stripe.png";
import {
  addCardService,
  getTheClientSecretKey,
} from "../../services/paymentGatewayServices";
import { options } from "./stripeCss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SuccessFullySubmitModal from "../successFullySubmittedModal";
import { paymentOptionSlice } from "../../redux/paymentOption/paymentOptionSlice";
export default function StripeModal({
  open,
  setOpen,
  setPaymentDoneModal=()=>{},
  walletData = { userID: "", amount: "", wallet: false }, // this props only set if we have set the data from the wallet page
}) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const dispatch = useDispatch();
  const { ADD_CARD } = useSelector((state) => state.PAYMENTOPTION);
  const [loading, setLoading] = React.useState(false);
  const [submitSuccessModal, setSubmitSuccessModal] = React.useState(false);
  
  // api call for save the card detail's
  /**
   *
   * @param {Object} stripeToken destructe token which we will send to the backend for save the card
   */
  const saveCardDetails = async (stripeToken) => {
    setLoading(true);
    console.log("tokendetail", stripeToken);
    const { token } = stripeToken;
    const { id } = token;
    const body = {
      card_token: id,
      gateway_type: "stripe",
    };

    try {
      const postData = await addCardService(body);
      console.log("postDtaa", postData);
      if (postData?.status) {
        setSubmitSuccessModal(true);
        dispatch(
          paymentOptionSlice.actions.setTheGetApiModalCallOntheDeleteOrAddCard()
        );
      } else {
        setOpen(false);
        NotificationManager.error("", postData?.response?.data?.message);
      }
    } catch (error) {
      console.log("err", error);
    }
    setLoading(false);
  };

  // main function for the payment initgration
  const makePaymentHandler = async () => {
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return NotificationManager.error("Stripe is not loaded yet");
    }
    const cardElement = elements.getElement(CardNumberElement);
    try {
      const stripeToken = await stripe.createToken(cardElement); // create stripe token as per the card element detail and the public id detail
      console.log("stripetokenvalue", stripeToken);

      if (stripeToken?.error) {
        setLoading(false);
        return NotificationManager.error(stripeToken?.error?.message);
      } else {
        if (!ADD_CARD) {
          // this payload set condtionally because we have the differnet payload for the ADD MONEY TO WALLET and differnet payload for the RENT APPLICATION
          const payload = {
            ...(location.state.wallet ? location.state : { ...location.state }),
          };
          const result = await getTheClientSecretKey(payload); // hit the api to backend after that get an client secert id to confirm the payment
          console.log("--===", result);
          if (result.status) {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
              result?.data?.client_secret,
              {
                payment_method: {
                  card: cardElement,
                },
              }
            ); //after confirm the payment as per the flow we will get a payment intent and error through destructing //
            console.log("pausghdf", paymentIntent, error);
            if (error) {
              setLoading(false);
              return NotificationManager.error(error.message);
            } else {
              setOpen(false);
              return setPaymentDoneModal(true);
            }
          } else {
            NotificationManager.error("", result.message);
          }
        } else {
          saveCardDetails(stripeToken);
        }
      }
    } catch (error) {
      console.log("errrr", error);
      NotificationManager.error(error);
    }
    setLoading(false);
  };

  
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <img src={stripeIcon} style={{ maxWidth: "17%" }} />
            <form onSubmit={() => {}}>
              <label>Card Number</label>
              <CardNumberElement
                className="mb-3"
                id="card-number-element"
                options={options}
              />
              <label>Cvc Number</label>
              <CardCvcElement
                className="mb-3"
                id="card-cvc-element"
                options={options}
              />
              <label>Card Expiry</label>
              <CardExpiryElement
                className="mb-3"
                id="card-expiry-element"
                options={options}
              />
              <ButtonComponent
                loading={loading}
                disabled={loading}
                title={!ADD_CARD ? "Pay" : "Add card"}
                onClick={makePaymentHandler}
              />
              {/* {errorMessage && <div className="text-danger">{errorMessage}</div>} */}
            </form>
          </ModalContent>
        </Fade>
      </Modal>
      {submitSuccessModal && (
        <SuccessFullySubmitModal
          open={submitSuccessModal}
          headingAndText={{
            mainHeading: "Card Added SuccessFully",
            middleText: "",
          }}
          setOpen={setSubmitSuccessModal}
          onCLick={() => {
            setOpen(false);
            setSubmitSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return <Fade ref={ref} in={open} {...other} />;
});

Backdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const blue = {
  200: "#99CCFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0066CC",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 510,
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

const TriggerButton = styled(Button)(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px
        ${theme.palette.mode === "dark" ? blue[300] : blue[200]};
      outline: none;
    }
  `
);
