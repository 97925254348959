import Icon from "../components/iconify/iconify";
import { AppConstants } from "../utils/appConstants";
import { permissions } from "../utils/CONSTANTS";
export const routeConfig = [
  {
    title: AppConstants.sidebarConstants.dashboard,
    link: "dashboard",
    path: "/app",
    icon: <Icon icon="ri:dashboard-fill" />,
    role: ["superAdmin"],
    show: true,
  },
  {
    title: "Manage Users",
    icon: <Icon icon="mdi:users" />,
    path: "/src/pages/manageUsers/index",
    role: ["superAdmin"],
    permissionValue : permissions.USER_MANGEMENT,
    show: true,
    children: [
      {
        title: "Add User",
        icon: <Icon icon="formkit:fastforward" />,
        link: "manage-users/add-user",
        path: "/src/pages/manageUsers/add-user.jsx",
        show: true,
      },
      // {
      //   title: "Users List",
      //   link: "manage-users",
      //   icon: <Icon icon="formkit:fastforward" />,
      //   path: "/src/pages/manageUsers/index",
      //   show: true,
      // },
      {
        title: "Renters",
        link: "renters",
        icon: <Icon icon="formkit:fastforward" />,
        path: "/src/pages/manageUsers/index",
        show: true,
      },
      {
        title: "Landlords",
        link: "landlords",
        icon: <Icon icon="formkit:fastforward" />,
        path: "/src/pages/manageUsers/index",
        show: true,
      },
      {
        title: "Property Managers",
        link: "property-managers",
        icon: <Icon icon="formkit:fastforward" />,
        path: "/src/pages/manageUsers/index",
        show: true,
      },
    ],
  },
  {
    title: "Properties",
    // link: "property-management",
    path: "./pages/propertyManagement",
    icon: <Icon icon="ph:warehouse-thin" />,
    permissionValue : permissions.MANAGE_PROPERTY,
    role: ["superAdmin"],
    children: [
      {
        title: "Property List",
        link: "property-management",
        path: "./pages/properties-in-demand",
        icon: <Icon icon="formkit:fastforward" />,
        role: ["superAdmin", "reviewerAdmin", "legalAdmin"],
        show: true,
      },
      {
        title: "Add Property",
        link: "add-property",
        path: "./pages/add-property",
        icon: <Icon icon="formkit:fastforward" />,
        role: ["superAdmin", "reviewerAdmin", "legalAdmin"],
        show: true,
      },
      {
        title: "Lease Agreements",
        link: "lease-aggrements",
        path: "./pages/property-managements/lease-aggrements",
        icon: <Icon icon="formkit:fastforward" />,
        role: ["superAdmin", "reviewerAdmin", "legalAdmin"],
        show: true,
      },

      {
        title: "Property",
        link: "property-management/:id",
        path: "./pages/property-managements/updateProperty",
        icon: <Icon icon="formkit:fastforward" />,
        role: ["superAdmin", "reviewerAdmin", "legalAdmin"],
        show: false,
      },
     
    ],
    show: true,
  },

  {
    title: "User details",
    link: "manage-users/user-details",
    path: "/src/pages/manageUsers/user-details",
    show: false,
  },
  {
    title: AppConstants.sidebarConstants.manageEmployees,
    // link: "manage-employees",
    path: "/src/pages/manage-employees",
    icon: <Icon icon="clarity:employee-group-solid" />,
    role: ["superAdmin"],
    permissionValue : permissions.MANAGE_EMPLOYEES,
    children: [
      {
        title: "Employee List",
        link: "manage-employees",
        path: "/src/pages/manage-employees",
        icon: <Icon icon="formkit:fastforward" />,
        show: true,
      },
      {
        title: "Add Employee",
        link: "manage-employees/add-employees",
        path: "/src/pages/manage-employees/add-employee.jsx",
        icon: <Icon icon="formkit:fastforward" />,
        show: true,
      },
    ],
    show: true,
  },
  {
    title: AppConstants.sidebarConstants.rolesAndPremission,
    link: "roles-and-permissions-management",
    path: "./pages/rolePermissions",
    icon: <Icon icon="fluent-mdl2:permissions" />,
    role: ["superAdmin"],
    show: true,
    permissionValue : permissions.MANAGE_ROLES,
  },

  {
    title: AppConstants.sidebarConstants.reviewRating,
    link: "review-and-rating-mgt",
    path: "./pages/reviewRating",
    icon: <Icon icon="carbon:review" />,
    role: ["superAdmin"],
    show: true,
    permissionValue : permissions.MANAGE_REVIEW,
  },
  {
    title: AppConstants.sidebarConstants.financialPerformance,
    link: "finance-performance",
    path: "./pages/financialPerformance",
    icon: <Icon icon="material-symbols:finance" />,
    role: ["superAdmin"],
    show: true,
    permissionValue : permissions.MANAGE_REVIEW, 
  },
  {
    title: AppConstants.sidebarConstants.manageTransactions,
    link: "manage-transactions",
    path: "./pages/manageTransactions",
    icon: <Icon icon="ant-design:transaction-outlined" />,
    role: ["superAdmin"],
    show: true,
    permissionValue : permissions.MANAGE_TRANSACTION, 
  },
  // {
  //   title: AppConstants.sidebarConstants.managePayouts,
  //   link: "manage-payouts",
  //   path: "./pages/managePayouts",
  //   role: ["superAdmin"],
  //   icon: <Icon icon="heroicons:currency-rupee" />,
  //   show: true,
  // },
  {
    title: AppConstants.sidebarConstants.activityLog,
    link: "activity-logs",
    path: "./pages/activityLog",
    icon: <Icon icon="tdesign:activity" />,
    role: ["superAdmin"],
    show: true,
    permissionValue : permissions.ACTIVITY_LOGS, 
  },
  {
    title: AppConstants.sidebarConstants.manageChat,
    link: "manage-chat",
    path: "./pages/manageChat",
    role: ["superAdmin"],
    icon: <Icon icon="majesticons:chat-line" />,
    show: true,
  },
  {
    title: "Notifications",
    link: "notifications",
    path: "./pages/notifications",
    icon: <Icon icon="material-symbols:notifications" />,
    role: ["superAdmin"],
    show: true,
  },
 
  {
    title: "CMS Pages",
    children: [
      {
        title: "Manage Blogs",
        link: "manage-blogs",
        path: "./pages/manage-blogs",
        role: ["superAdmin"],
        icon: <Icon icon="mdi:blog" />,
        show: true,
      },
      {
        title: "Newsletter",
        link: "newsletter",
        path: "./pages/Newsletter",
        role: ["superAdmin"],
        icon: <Icon icon="mdi:blog" />,
        show: true,
      },
      {
        title: "Manage AboutUs",
        link: "manage-aboutUs",
        path: "./pages/manage-aboutUs",
        role: ["superAdmin"],
        icon: <Icon icon="mdi:about-circle-outline" />,
        show: true,
      },
      {
        title: "Manage Careers",
        link: "manage-careers",
        path: "./pages/manage-careers",
        role: ["superAdmin"],
        icon: <Icon icon="eos-icons:job" />,
        show: true,
      },
      {
        title: "Create Testimonials",
        link: "create-testimonials",
        path: "./pages/create-testimonials/add-testimonials",
        role: ["superAdmin"],
        icon: <Icon icon="fluent:chat-sparkle-16-regular" />,
        show: false,
      },
      {
        title: "Manage Testimonials",
        link: "testimonials",
        path: "./pages/create-testimonials",
        role: ["superAdmin"],
        icon: <Icon icon="fluent:chat-sparkle-16-regular" />,
        show: true,
      },
      {
        title: "Manage Privacy Policy",
        link: "manage-privacy-policy",
        path: "./pages/manage-privacy-policy",
        role: ["superAdmin"],
        icon: <Icon icon="ic:baseline-privacy-tip" />,
        show: true,
      },
      {
        title: "Manage FAQ's",
        link: "manage-faq",
        path: "./pages/manage-faq",
        role: ["superAdmin"],
        icon: <Icon icon="mdi:faq" />,
        show: true,
      },
      {
        title: "Social Media Links",
        link: "manage-social-media-links",
        path: "./pages/manage-social-media-links",
        role: ["superAdmin"],
        icon: <Icon icon="ri:links-fill" />,
        show: true,
      },
      {
        title: "Manage Banners",
        link: "manage-banners",
        path: "./pages/manage-banners",
        role: ["superAdmin"],
        icon: (
          <Icon icon="material-symbols-light:planner-banner-ad-pt-outline" />
        ),
        show: true,
      },
      {
        title: "Data Protection",
        link: "data-protection",
        path: "./pages/data-protection",
        role: ["superAdmin"],
        icon: <Icon icon="flat-color-icons:data-protection" />,
        show: true,
      },
      // {
      //   title: "Manage Agreements",
      //   link: "manage-aggrements",
      //   path: "./pages/manage-aggrements",
      //   role: ["superAdmin"],
      //   icon: <Icon icon="ph:file" />,
      //   show: true,
      // },
      {
        title: "Add Agreements",
        link: "add-aggrements",
        path: "./pages/manage-aggrements/add-aggrements",
        role: ["superAdmin"],
        icon: <Icon icon="ph:file" />,
        show: false,
      },
      {
        title: "Manage Contact Us",
        link: "manage-contact-us",
        path: "./pages/manage-blogs",
        role: ["superAdmin"],
        icon: <Icon icon="mdi:blog" />,
        show: true,
      },
    ],
  
    role: ["superAdmin"],
    icon: <Icon icon="fluent:content-view-16-regular" />,
    show: true,
  },
];
