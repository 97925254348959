import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  maintainceDetail: {
    concern: "",
    createdAt: "",
    landlordID: "",
    landlordRemark: "",
    propertyID: "",
    renterID: "",
    renterRemark: "",
    status: false,
    updatedAt: "",

    _id: "",
  },
  filterStatus: false,
  modalStatus: false
};

export const maintainceRequestSlice = createSlice({
  name: "manPowerEnquiry",
  initialState: initialState,
  reducers: {
    maintainceRequestDetail: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        maintainceDetail: action.payload,
      };
      return data;
    },
    setTheFilterValue: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        filterStatus: !data?.filterStatus,
      };
      return data;
    },
    setTheModalStatus: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        modalStatus: !data?.modalStatus,
      };
      return data;
    },
  },
});
