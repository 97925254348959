import { useNavigate } from "react-router-dom";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Iconify from "../../components/iconify";
import { useState } from "react";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import {
  getCareerList,
  deleteAdminCareer,
  getContactUsList,
} from "../../services/admin/adminCmsServices";
import PaginationComponent from "../../components/Pagination";
import { toast } from "react-toastify";
import useDebounceSearch from "../../services/globalSearchService";
import Loader from "../../components/loader";

const ManageContactUs = () => {
  const navigate = useNavigate();
  const [openDeleteCareer, setOpenDeleteCareer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCareers, setTotalCareers] = useState(0);
  const [page, setPage] = useState(1);
  const [contactUsData, setContactUsData] = useState([]);
  const [search, setSearch] = useState("");

  const fetchContact = async () => {
    setLoading(true);
    const filters = {
      page: page || 1,
      count: 10,
      search: search,
    };
    try {
      const result = await getContactUsList(filters);
      if (result.status) {
        const careersData = result?.data[0]?.data ?? [];
        const paginationData = result?.data[0]?.pagination[0];
        setContactUsData(careersData);
        setTotalCareers(paginationData?.total);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useDebounceSearch(search, fetchContact, 1000, {
    pagination: page,
  });
  console.log("-=-=", contactUsData);
  return (
    <div className="px-5">
      <Stack
        mt={3}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Typography variant="h4">Contact Us</Typography>
      </Stack>
      <Stack
        mt={3}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <OutlinedInput
          placeholder="Search contact..."
          value={search}
          onChange={(e) => {
            setSearch(e?.target?.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      </Stack>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className="mt-4">
        <TableHead>
          <TableRow>
            <TableCell align="left">Sr No</TableCell>
            <TableCell align="left">Company</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Message</TableCell>
            {/* <TableCell align="left">Action</TableCell> */}
          </TableRow>
        </TableHead>
        {loading ? (
          <Loader />
        ) : contactUsData.length < 1 ? (
          <Typography textAlign="center" mt={2}>
            No contact found.
          </Typography>
        ) : (
          contactUsData.map((item, index) => (
            <TableBody key={item?._id}>
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{item?.company}</TableCell>
              <TableCell align="left">{item?.email}</TableCell>
              <TableCell align="left">{item?.name}</TableCell>
              <TableCell align="left">{item?.phone}</TableCell>
              <TableCell align="left">{item?.message}</TableCell>
              <TableCell align="left">
                {/* <Iconify
                  icon="tabler:edit"
                    onClick={() =>
                      navigate("/admin/add-careers", {
                        state: {
                          _id: career._id,
                        },
                      })
                    }
                /> */}
                &nbsp;
                {/* <Iconify
                  icon="mingcute:delete-line"
                  onClick={() => {
                    setOpenDeleteCareer(true);
                  }}
                /> */}
              </TableCell>
            </TableBody>
          ))
        )}
      </Table>
      <div className="p-3 d-flex justify-content-center align-items-center">
        <PaginationComponent
          page={{ pagination: page, setPagination: setPage }}
          count={totalCareers || 1}
        />
      </div>
    </div>
  );
};

export default ManageContactUs;
