import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const uploadLeaseAggrement = (values) => {
  return axiosInstance
    .post(API_Endpoints.leaseAggrement.submitLeaseAggrement, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getUploadLeaseAggrement = () => {
  return axiosInstance
    .get(API_Endpoints.leaseAggrement.getLeaseAggrement)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteUploadLeaseAggrement = (id) => {
  return axiosInstance
    .delete(`${API_Endpoints.leaseAggrement.deleteLeaseAgreement}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
