import { ADMIN_API_ENDPOINTS } from "../API_Endpoints";
import axiosInstance from "../../utils/axios";
import { formData } from "../httpHeaders";

//blogs api functions
export const addAdminBlog = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addBlog, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getBlogsList = (params) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getBlogs}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getBlogById = (id) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getBlogById}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const updateAdminBlog = (body) => {
  return axiosInstance
    .put(ADMIN_API_ENDPOINTS.manageCms.updateBlog, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const deleteAdminBlog = (id) => {
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteBlog}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//about us api functions
export const addAdminAboutUs = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addUpdateAboutUs, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getAdminAboutUs = (slug) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getAboutUs}?slug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//privacy api functions
export const addAdminPrivacy = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addUpdatePrivacy, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getAdminPrivacy = (slug) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getPrivacy}?slug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//data-protection api functions
export const addAdminDataProtection = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addDataProtection, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getAdminDataProtection = (slug) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getDataProtection}?slug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//newslatter api functions
export const getAdminNewsLatter = ({ page, search }) => {
  return axiosInstance
    .get(
      `${ADMIN_API_ENDPOINTS.manageCms.getNewsLatter}?page=${page}&count=10&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//career api functions
// export const addAdminCareer = (body) => {
//   return axiosInstance
//     .post(ADMIN_API_ENDPOINTS.manageCms.addCareer, body, {
//       headers: { ...formData },
//     })
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => err);
// };

// export const getCareerList = ({ page, search }) => {
//   return axiosInstance
//     .get(
//       `${ADMIN_API_ENDPOINTS.manageCms.getCareerList}?page=${page}&count=10&search=${search}`
//     )
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => err);
// };

// export const getCareerById = (id) => {
//   return axiosInstance
//     .get(`${ADMIN_API_ENDPOINTS.manageCms.getCareerById}?id=${id}`)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => err);
// };

// export const updateAdminCareer = (body) => {
//   return axiosInstance
//     .put(ADMIN_API_ENDPOINTS.manageCms.updateCareer, body, {
//       headers: { ...formData },
//     })
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => err);
// };

// export const deleteAdminCareer = (id) => {
//   return axiosInstance
//     .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteCareer}?id=${id}`)
//     .then((res) => {
//       return res.data;
//     })
//     .catch((err) => err);
// };

//testimonials api functions
export const addAdminTestimonials = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addTestimonial, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const updateAdminTestimonials = (body) => {
  return axiosInstance
    .put(ADMIN_API_ENDPOINTS.manageCms.updateTestimonial, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getTestimonialList = ({ page, search }) => {
  return axiosInstance
    .get(
      `${ADMIN_API_ENDPOINTS.manageCms.getTestimonials}?page=${page}&count=10&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const deleteTestimonial = (id) => {
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteTestimonial}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//banners api functions
export const addAdminBanner = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addBanner, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const updateAdminBanner = (body) => {
  return axiosInstance
    .put(ADMIN_API_ENDPOINTS.manageCms.updateBanner, body, {
      headers: { ...formData },
    })
    .then((res) => {
      return res.data;
    })

    .catch((err) => err);
};

export const getBannerList = ({ page, search }) => {
  return axiosInstance
    .get(
      `${ADMIN_API_ENDPOINTS.manageCms.getBanners}?page=${page}&count=10&search=${search}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const deleteAdminBanner = (id) => {
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteBanner}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

//faq api functions
export const addAdminFaq = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addFaq, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getFaqList = (params) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getFaqList}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getFaqById = (id) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getFaqById}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const updateAdminFaq = (body) => {
  return axiosInstance
    .put(ADMIN_API_ENDPOINTS.manageCms.updateFaq, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const deleteAdminFaq = (id) => {
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteFaq}?id=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

// Career API functions
//faq api functions
export const addAdminCareer = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageCms.addCareer, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getCareerList = (params) => {
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getCareerList}`, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getCareerById = (id) => {
  let params = {
    id: id,
  };
  return axiosInstance
    .get(`${ADMIN_API_ENDPOINTS.manageCms.getCareerById}`, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const updateAdminCareer = (body) => {
  return axiosInstance
    .put(ADMIN_API_ENDPOINTS.manageCms.updateCareer, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const deleteAdminCareer = (id) => {
  let params = {
    id: id,
  };
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageCms.deleteCareer}`, {
      params: params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const getContactUsList = () => {
  return axiosInstance
    .get(ADMIN_API_ENDPOINTS.manageCms.getContactUs)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};
