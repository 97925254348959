import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const MaintenanceRequest = (body) => {
  return axiosInstance
    .post(API_Endpoints.maintenanceRequest.submitMaintenanceRequest, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const getMaintenanceRequest = (status) => {
  return axiosInstance
    .get(
      `${API_Endpoints.maintenanceRequest.submitMaintenanceRequest}?status=${status}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const cancelMaintenanceRequest = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.maintenanceRequest.cancelMaintenanceREquest}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addRemarkToMaintenance = (body) => {
  return axiosInstance
    .post(API_Endpoints.maintenanceRequest.addMaintenanceRemark, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const markResolvedMaintenanceRequest = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.maintenanceRequest.submitMaintenanceRequest}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
