import { NotificationManager } from "react-notifications";
import { LocationIcon, RatingIcon } from "../../../assets/icons";
import rented from "../../../assets/images/rented.png";

import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../../components/Button";
import { reviewQuestionArr } from "../../../utils/CONSTANTS";
import { addTheReview } from "../../../services/reviewServices";

const ReviewPropertiesModal = ({ open, setOpen, modalData = {} }) => {
  const [mcqQuestionArr, setMcqQuestionArr] = useState(reviewQuestionArr);
  const [isNextConent, setIsNextContent] = useState(false);
  const [sum, setSum] = useState(0);
  const [loading,setLoading]=useState(false)
  const [modalDataValue, setModalDataValue] = useState(modalData);
  const [formValue, setFormValue] = useState({
    review: {
      value: "",
      warning: false,
    },
  });

  //onchange function to set the rating of the property
  //param upper and lower index means main array have a inside array of the option of mcq
  // value means which radio button the user will click
  const onChange = (e, upperIndex, lowerIndex, value) => {
    let data = [...mcqQuestionArr];
    data[upperIndex].value = value;
    console.log("-valueData", data);
    setMcqQuestionArr(data);
  };

 
  const getRating = async () => {
    if (mcqQuestionArr.some((el) => el.value === 0)) {
      return NotificationManager.error(
        "",
        "Select the option for the all respected question asked"
      );
    }
    let sumValue = 0;
    if (mcqQuestionArr.every((el) => el.value === 4)) {
      sumValue = 5;
      console.log("sim", sum);
      setSum(sumValue);
    } else {
      for (var i = 0; i <= mcqQuestionArr.length - 1; i++) {
        sumValue = sumValue + mcqQuestionArr[i].value;
      }
      setSum(Math.ceil(sumValue / mcqQuestionArr.length));
    }
    setIsNextContent(true);
    if (isNextConent) {
      let data = { ...formValue };
      data = {
        ...data,
        review: {
          ...data.review,
          warning: data.review.value == "",
        },
      };
      setFormValue(data);
      if (!data.review.warning) {
        setLoading(true)
        const payload = {
          type: "property",
          rating: sum,
          review: formValue.review.value,
          property_id: modalDataValue?._id,
          question1: mcqQuestionArr[0]?.question,
          answer1: mcqQuestionArr[0]?.value,
          question2: mcqQuestionArr[1]?.question,
          answer2: mcqQuestionArr[0]?.value,
          question3: mcqQuestionArr[2]?.question,
          answer3: mcqQuestionArr[0]?.value,
          question4: mcqQuestionArr[3]?.question,
          answer4: mcqQuestionArr[0]?.value,
        };
        const postData = await addTheReview(payload);
        console.log("___",postData)
        if(postData?.status){
          NotificationManager.success("","review added")
        }else{
          NotificationManager.error("",postData?.message)
        }
        setOpen(false)
        setLoading(false)
      }
    }
  };

  useEffect(() => {
    setModalDataValue(modalData);
  }, [modalData]);

  console.log("mdalsa", sum);
  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "900px",
          padding: 3,
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle fontSize={40} fontWeight={"bold"} color={"text.primary"}>
        Review Properties
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 3,
        }}
      >
        <Card
          elevation={0}
          sx={{ border: "1px solid #dbdbdb", borderRadius: 3 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center gap-1">
              {!isNextConent ? (
                <img
                  style={{ width: 127, height: 63, borderRadius: 10 }}
                  src={modalDataValue?.images[0]?.url}
                />
              ) : null}
              <div>
                <Typography fontSize={24} color="#031013" fontWeight={"bold"}>
                  {modalData?.propertyName}
                </Typography>
                <div className="d-flex gap-1">
                  <LocationIcon />
                  <Typography color="#8D9B9F" fontWeight={200}>
                    {modalDataValue?.address?.addressText}
                  </Typography>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </Card>
        {!isNextConent ? (
          <div className="py-3">
            <InputLabel
              sx={{ fontSize: 18, fontWeight: "bold", color: "#07262E" }}
            >
              Please answer the below questions first:
            </InputLabel>

            {mcqQuestionArr.map((item, upperIndex) => (
              <div className="mt-3">
                <Typography
                  fontWeight={500}
                  color={"text.secondary"}
                  fontSize={14}
                  key={item.id}
                >
                  {upperIndex + 1}. {item.question}
                </Typography>
                <Grid container spacing={2}>
                  {item.mcqOption.map((optionItem, lowerIndex) => (
                    <Grid item md={6}>
                      <div className="d-flex align-items-center gap-1">
                        <Radio
                          name={"radio"}
                          checked={item.value === optionItem.value}
                          onChange={(e) => {
                            onChange(
                              e,
                              upperIndex,
                              lowerIndex,
                              optionItem.value
                            );
                          }}
                        />
                        <span>
                          <Typography
                            fontWeight={500}
                            color={"text.secondary"}
                            fontSize={12}
                          >
                            {optionItem.label}
                          </Typography>
                        </span>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </div>
        ) : (
          <div className="py-4">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Typography
                fontSize={18}
                fontWeight={"bold"}
                color={"text.secondary"}
              >
                Based on your answer the rating for this property is :
              </Typography>
              <div className="py-3">
                {Array(sum)
                  .fill()
                  .map((_, index) => (
                    <RatingIcon key={index} />
                  ))}
              </div>
            </div>

            <div>
              <Typography
                fontSize={16}
                fontWeight={600}
                color={"text.secondary"}
              >
                Write your review.
              </Typography>

              <TextField
                placeholder="Write review"
                multiline
                rows={5}
                fullWidth
                className="w-100 mt-3"
                onChange={(e) => {
                  setFormValue((prev) => ({
                    ...prev,
                    review: {
                      value: e.target.value,
                      warning: e.target.value == "",
                    },
                  }));
                }}
              />
              {formValue.review.warning && (
                <span className="text-danger">Enter the review</span>
              )}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="d-flex justify-content-center align-items-center">
        <ButtonComponent
          onClick={() => {
            getRating();
          }}
          sx={{ width: 311, height: 64 }}
          title={isNextConent ? "Submit" : "Next"}
          loading={isNextConent && loading}
          disabled={isNextConent && loading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReviewPropertiesModal;
