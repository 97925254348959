import { Box, Card, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DownloadIcon, eyeViewIcon } from "../../../assets/icons";
import { WalletIcon } from "../../../assets/svg";
import ButtonComponent from "../../../components/Button";
import Loader from "../../../components/loader";
import TransactionhistoryTable from "../../../components/transactionhistory";
import { GETRentTransactionList } from "../../../services/transactionService";
import { fDateTime } from "../../../utils/format-time";
import WalletPopup from "../../wallet/walletPopup";
import { formatCurrency } from "../../../utils/helperFunctionForValidte";
import { useNavigate } from "react-router-dom";
import { GetWalletData } from "../../../services/walletServices";
const columns = [
  { title: "Date & Time", key: "date" },
  { title: "Description", key: "description" },
  { title: "Payment mode", key: "paymentMode" },
  { title: "Amount", key: "amount" },
  { title: "Action", key: "action" },
];

const RentTransactions = () => {
  const navigate = useNavigate();
  const [openTopupDialog, setOpenTopupDialog] = useState(false);
  const [withdrawDialog, setWithdrawDialog] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [walletDetail, setWalletDetail] = useState({
    walletPoints: 0,
    Deposited: 0,
    Withdrawn: 0,
    RentCollected: 0,
    EarnedRewards: 0,
    deposite_percentage: 0,
    withdrawn_percentage: 0,
    rent_collected_percentage: 0,
    earned_rewards_percentage: 0,
  });
  // GET THE TRANSACTION DATA AS PER THE BACKEND
  const getTheTransactionData = async () => {
    try {
      setLoading(true);
      const getData = await GETRentTransactionList();
      console.log("777", getData);
      if (getData?.status) {
        const data = getData?.data[0]?.data?.map((item) => {
          return {
            date: fDateTime(item?.createdAt),
            description: `Payment Credit from the ${item?.renter}`,
            paymentMode: `Credit`,
            amount: formatCurrency(item?.amount),
            action: () => {
              return (
                <>
                  <button
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                    }}
                    onClick={() =>
                      navigate(`/transaction-history-Detail/${item._id}`)
                    }
                  >
                    {eyeViewIcon()}
                  </button>
                  <DownloadIcon />
                </>
              );
            },
          };
        });
        setTransactionData(data);
      }
      setLoading(false);
    } catch (error) {
      console.log("er", error);
    }
  };
  // get the wallet detail from the backend
  const getWalletDetails = async () => {
    const getData = await GetWalletData();
    if (getData?.status) {
      setWalletDetail(getData?.data);
    }
    console.log("getData", getData);
  };
  useEffect(() => {
    getTheTransactionData();
    getWalletDetails();
  }, []);
  return (
    <Container maxWidth="xl">
      <div className="py-4">
        <Typography
          className="phone-heading-30"
          component={"h2"}
          color={"text.primary"}
          fontWeight={700}
          fontSize={40}
        >
          Rent Transactions
        </Typography>

        <Card
          elevation={0}
          sx={{
            p: 2,
            mt: 3,
            borderRadius: 3,
            boxShadow: "5px 5px 19px 4px #0000000d",
          }}
        >
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center gap-2 flex-wrap phone-full-width">
              <WalletIcon width={50} height={40} />

              <div className="">
                <Typography color="#C8D736" fontSize={14} fontWeight={500}>
                  Wallet Balance
                </Typography>
                <Typography
                  className="phone-heading-30"
                  component={"h2"}
                  color={"text.secondary"}
                  fontWeight={"bold"}
                  fontSize={40}
                >
                  {formatCurrency(walletDetail?.walletPoints)}
                </Typography>
              </div>
            </div>

            <div className="d-flex gap-2 wallet-btn">
              <ButtonComponent
                variant="secondary"
                sx={{
                  background: "transparent",
                  border: "2px solid #C8D736",
                  borderRadius: 3,
                  height: 69,

                  width: 227,
                }}
                title={"Withdraw Amount"}
                onClick={() => {
                  setWithdrawDialog(true);
                  setDialogType("withdraw");
                }}
              />
              <ButtonComponent
                sx={{
                  background: "transparent",
                  border: "2px solid #C8D736",
                  borderRadius: 3,
                  height: 69,
                  width: 227,
                }}
                variant="secondary"
                title={"Top-up Wallet"}
                onClick={() => {
                  setOpenTopupDialog(true);
                  setDialogType("");
                }}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center gap-3 mt-3 flex-wrap ">
            <Box
              className="wallet-cards"
              sx={{
                p: 2,
                borderRadius: 3,
                background: "#e6f5f6",
              }}
              display={"flex"}
              gap={2}
            >
              <div
                style={{ border: "5px solid rgb(0 153 161)" }}
                className="step-left-text d-flex justify-content-center align-items-center"
              >
                <b>{walletDetail?.deposite_percentage}%</b>
              </div>
              <div>
                <Typography
                  fontWeight={"bold"}
                  fontSize={18}
                  color={"rgb(7 38 46)"}
                >
                  {"Deposited"}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                  //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                >
                  {formatCurrency(walletDetail?.Deposited)}
                </Typography>
              </div>
            </Box>
            <Box
              className="wallet-cards"
              sx={{
                p: 2,
                borderRadius: 3,
                background: "#EAFFD2",
              }}
              display={"flex"}
              gap={2}
            >
              <div
                style={{ border: "5px solid rgb(0 153 161)" }}
                className="step-left-text d-flex justify-content-center align-items-center"
              >
                <b>{walletDetail?.rent_collected_percentage}%</b>
              </div>
              <div>
                <Typography
                  fontWeight={"bold"}
                  fontSize={18}
                  color={"rgb(7 38 46)"}
                >
                  {"Rent Collected"}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                  //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                >
                  {formatCurrency(walletDetail?.RentCollected)}
                </Typography>
              </div>
            </Box>
            <Box
              className="wallet-cards"
              sx={{
                p: 2,
                borderRadius: 3,
                background: "#EEF3C1",
              }}
              display={"flex"}
              gap={2}
            >
              <div
                style={{ border: "5px solid rgb(0 153 161)" }}
                className="step-left-text d-flex justify-content-center align-items-center"
              >
                <b>{walletDetail?.earned_rewards_percentage}%</b>
              </div>
              <div>
                <Typography
                  fontWeight={"bold"}
                  fontSize={18}
                  color={"rgb(7 38 46)"}
                >
                  {"Earned Rewards"}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                  //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                >
                  0
                </Typography>
              </div>
            </Box>
            <Box
              className="wallet-cards"
              sx={{
                p: 2,
                borderRadius: 3,
                background: "#b6cad2",
              }}
              display={"flex"}
              gap={2}
            >
              <div
                style={{ border: "5px solid rgb(0 153 161)" }}
                className="step-left-text d-flex justify-content-center align-items-center"
              >
                <b>{walletDetail?.withdrawn_percentage}%</b>
              </div>
              <div>
                <Typography
                  fontWeight={"bold"}
                  fontSize={18}
                  color={"rgb(7 38 46)"}
                >
                  {"Withdrawn"}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "14px", sm: "14px", xs: "12px" } }}
                  //   color={status ? "rgb(58 168 175)" : "#8e9926"}
                >
                  {formatCurrency(walletDetail?.Withdrawn)}
                </Typography>
              </div>
            </Box>
          </div>
        </Card>

        <div className="my-4">
          <Typography color="text.primary" fontSize={24} fontWeight={500}>
            All Transactions
          </Typography>

          <div className="mt-3">
            {loading ? (
              <Loader />
            ) : transactionData?.length === 0 ? (
              <Typography
                textAlign={"center"}
                color="text.primary"
                fontSize={24}
                fontWeight={500}
              >
                NO Transaction
              </Typography>
            ) : (
              <TransactionhistoryTable
                tableHeader={columns}
                tableRow={transactionData}
              />
            )}
          </div>
        </div>
      </div>
      <WalletPopup
        open={openTopupDialog}
        setOpen={setOpenTopupDialog}
        type={dialogType}
      />
      <WalletPopup
        open={withdrawDialog}
        setOpen={setWithdrawDialog}
        type={dialogType}
      />
    </Container>
  );
};

export default RentTransactions;
