import { Container, Typography } from "@mui/material";
import React from "react";

const DataProtection = () => {
  return (
    <Container maxWidth="xl">
      <div className="my-5">
        <Typography color="text.secondary" fontSize={18} fontWeight={500}>
          DATA PROTECTION
        </Typography>
        <p className="mt-2">
          At Rentranzact, your privacy and data security are of utmost
          importance to us.To begin with, we collect various types of
          information to provide and enhance our services. Personal
          identification information, such as your name, email address, phone
          number, and postal address, is collected when you create an account,
          make a purchase, or interact with us in other ways. This data is
          fundamental for fulfilling orders, managing your account, and
          communicating effectively. Technical data, including your IP address,
          browser type, operating system, and other details related to your use
          of our website and services, is also collected. This information helps
          us understand how our services are used and enables us to improve the
          user experience. Additionally, we collect transactional data related
          to your purchases, such as order history, payment details, and product
          preferences. This data is crucial for processing and fulfilling your
          orders efficiently. Finally, communication data encompasses any
          correspondence you have with us, including customer service
          interactions, feedback, and contact form submissions. This information
          allows us to provide better support and address your concerns
          promptly. We use the information we collect for several purposes, each
          designed to enhance your experience and ensure the smooth operation of
          our services. Primarily, your data is used to provide and improve our
          services. This includes processing orders, managing your account, and
          delivering the products or services you request. By analyzing your
          preferences and behavior, we can also personalize your experience,
          tailoring content, recommendations, and offers to better suit your
          interests. This personalization helps us provide a more relevant and
          engaging experience. Additionally, we use your contact information to
          communicate with you, sending updates about your orders, responding to
          inquiries, and providing information about new products or services.
          If you have opted in, we may also send promotional materials and
          newsletters to keep you informed about special offers and company
          news. Ensuring compliance with legal obligations is another critical
          use of your data. We use it to meet legal requirements, protect our
          rights and interests, and enforce our terms of service. This includes
          handling disputes, preventing fraudulent activities, and responding to
          regulatory inquiries.
        </p>
       
        <p>
          While we are committed to protecting your privacy, there are certain
          situations in which we may need to share your information. We do not
          sell or trade your personal data. However, we may share your
          information with trusted third-party service providers who assist us
          in operating and improving our services. These providers include
          payment processors, data analysts, and customer support teams. They
          are bound by confidentiality agreements and are required to protect
          your data and use it only for the purposes specified. We may also
          disclose your information to comply with legal requirements or to
          respond to lawful requests from government authorities. This includes
          responding to subpoenas, court orders, or regulatory inquiries.
          Additionally, in the event of a merger, acquisition, or sale of
          assets, your data may be transferred as part of the transaction. We
          will ensure that the new entity continues to protect your data in
          accordance with our privacy practices.
        </p>

        <p>
          You have several rights concerning your personal data, and we are
          committed to helping you exercise them. You can request access to the
          personal data we hold about you and obtain a copy of that information.
          If you believe that any of your data is inaccurate or incomplete, you
          have the right to request corrections or updates. You can also request
          the deletion of your personal data, subject to any legal or
          contractual obligations we may have. Additionally, you may request a
          restriction on the processing of your data in certain circumstances,
          such as when you contest the accuracy of your information or object to
          its processing. If you do not wish to receive direct marketing
          communications, you can object to the processing of your data for
          these purposes. To exercise any of these rights, please contact us
          using the details provided below. We will respond to your request in
          accordance with applicable laws and within a reasonable timeframe. We
          also use cookies and similar tracking technologies to enhance your
          experience on our website. Cookies are small data files stored on your
          device that help us remember your preferences and analyze website
          traffic.
        </p>

        <p>
          For any inquiries or to exercise your rights, please reach out to us
          at rentranzactt@gmail.com or +912084422881. We appreciate your trust
          and are committed to protecting your personal information with the
          highest standards of security and transparency
        </p>
      </div>
    </Container>
  );
};

export default DataProtection;
