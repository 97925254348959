import { Card, Collapse, Container, Grid, Typography } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";
import { FaCircleCheck } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { PaystackButton } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckIcon } from "../../assets/icons";
import etranzact from "../../assets/icons/etranzact.png";
import paystack from "../../assets/icons/paystack.png";
import rentranzact from "../../assets/icons/rentranzact.png";
import stripeIcon from "../../assets/icons/stripe.png";
import ButtonComponent from "../../components/Button";
import CollapseWithTitle from "../../components/Collapse";
import Loader from "../../components/loader";
import OrderSummary from "../../components/orderSummary";
import StripeModal from "../../components/stripePopup/stripe";
import SuccessFullySubmitModal from "../../components/successFullySubmittedModal";
import {
  paymentInitialState,
  paymentOptionSlice,
} from "../../redux/paymentOption/paymentOptionSlice";
import {
  configFluterWaveObj,
  getCardService,
  getTheClientSecretKey,
  payStackObj,
  payWithWallet,
} from "../../services/paymentGatewayServices";
import { RetrieveLocalUser } from "../../utils/authService";
import { PAYMENT_METHOD } from "../../utils/CONSTANTS";
import CardTable from "./cardTable";
const paymentMethods = [
  {
    title: PAYMENT_METHOD.wallet,
    icon: rentranzact,
  },
  {
    title: PAYMENT_METHOD.flutterwave,
    icon: etranzact,
  },
  {
    title: PAYMENT_METHOD.payStack,
    icon: paystack,
  },
  {
    title: PAYMENT_METHOD.stripe,
    icon: stripeIcon,
  },
];
const PaymentMethod = () => {
  const location = useLocation();
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [infoModal, setInfoModal] = useState(false);
  const [loading, setLoading] = useState({
    cardBtn: false,
  });
  const [eWalletsCollapse, setEWalletsCollapse] = useState(true);
  const [cardCollapse, setCardCollapse] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [stripeModal, setStripeModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [paymentDone, setPaymentDone] = useState(false);
  const { API_CALL, CARD_DETAIL } = useSelector((state) => state.PAYMENTOPTION);
  console.log("card_id", CARD_DETAIL);

  const userData = RetrieveLocalUser("user"); // retireve the data from the storage of browser
  const payStackConfig = payStackObj({
    ...userData,
    amount: location?.state?.amount,
    propertyID: location?.state?.propertyID,
    userID: userData._id,
    wallet: location?.state?.wallet ? "true" : "false",
  });
  console.log("property", location);
  // fluter wave function
  const FluterWavePaymentGatewayIntigration = () => {
    const fluterWaveConfig = configFluterWaveObj({
      ...userData,
      amount: location?.state?.amount,
      meta: {
        propertyID: location?.state?.propertyID,
        wallet: location?.state?.wallet ? "true" : "false",
        userID: userData._id,
      },
    });
    console.log("fluterwave", fluterWaveConfig);
    const handleFlutterPayment = useFlutterwave(fluterWaveConfig);
    handleFlutterPayment({
      callback: async (response) => {
        console.log("flutterwaveresponse", response);
        setTimeout(() => {
          setLoading((prev) => ({
            ...prev,
            cardBtn: false,
          }));
          closePaymentModal();
          // navigate(-1);
        }, 2000);
        setTimeout(() => {
          setPaymentDone(true);
        }, 2000);
      },
      onClose: () => {
        // console.log("Payment closed without completion");
      },
    });
  };

  // you can call this function anything for paystack
  const handlePaystackSuccessAction = async (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    setTimeout(() => {
      setPaymentDone(true);
    }, 2000);
  };
  // you can call this function anything  for paystack
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  // paystack config obj
  const componentProps = {
    ...payStackConfig,
    text: "Proceed",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  // payment viva wallet of the app
  const paymentWithWallet = async () => {
    const body = {
      propertyID: location?.state?.propertyID,
    };
    setLoading((prev) => ({
      ...prev,
      cardBtn: true,
    }));
    const postData = await payWithWallet(body);
    console.log("postDaata", postData);
    if (postData?.status) {
      // navigate(-1);
      setPaymentDone(true);
    } else if (postData?.response?.status === 400) {
      NotificationManager.error(postData?.response?.data?.message);
    }
    setLoading((prev) => ({
      ...prev,
      cardBtn: false,
    }));
  };

  // payment intigration function
  const PaymentIntigration = async () => {
    // this check will handle the if user select the saved card and payment method together
    if (
      (CARD_DETAIL?._id && paymentMethod) ||
      (CARD_DETAIL?._id && paymentMethod === PAYMENT_METHOD.payStack)
    ) {
      return setInfoModal(true);
    }
    // this will run when user selected the saved card for the payment
    if (CARD_DETAIL?._id) {
      const payload = {
        ...location.state,
        payment_card_id: CARD_DETAIL?._id,
      };
      setLoading((prev) => ({
        ...prev,
        cardBtn: true,
      }));
      const result = await getTheClientSecretKey(payload); // hit the api to backend after that get an client secert id to confirm the payment
      console.log("--===", result);
      if (result.status) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          result?.data?.client_secret
        );
        console.log("pausghdf", paymentIntent, error);
        if (error) {
          setLoading((prev) => ({
            ...prev,
            cardBtn: false,
          }));
          return NotificationManager.error(error.message);
        } else {
          setLoading((prev) => ({
            ...prev,
            cardBtn: false,
          }));
          // navigate(-1);
          setPaymentDone(true);
        }
      }
    } else {
      // give notification to the user if he/she doesnot select the payment method
      if (paymentMethod === "") {
        return NotificationManager.error("Select the payment method");
      }
      // cases handle as per the user selected the payment method for payment
      switch (paymentMethod) {
        case PAYMENT_METHOD.wallet:
          paymentWithWallet();
          break;
        case PAYMENT_METHOD.flutterwave:
          FluterWavePaymentGatewayIntigration();
          break;
        case PAYMENT_METHOD.stripe:
          setStripeModal(true);
          dispatch(paymentOptionSlice.actions.setTheAddCardTrack(false));
          break;
        default:
          break;
      }
    }
  };

  // get the save card data from the backend
  const getTheSaveCardFromBackend = async () => {
    const getData = await getCardService();
    if (getData?.status) {
      setCardData(getData?.data[0].data);
    }
  };

  useEffect(() => {
    getTheSaveCardFromBackend();
  }, [API_CALL]);
  console.log("getdatacard", cardData);

  useEffect(() => {
    dispatch(
      paymentOptionSlice.actions.setTheSavedCardDetail(
        paymentInitialState?.CARD_DETAIL
      )
    );
  }, []);

  return loading.cardBtn ? (
    <Loader />
  ) : (
    <Container maxWidth="xl">
      <Grid container spacing={4} my={3}>
        <Grid item md={8}>
          <Card
            sx={{ p: 2, border: "1px solid #DBDBDB", borderRadius: 2 }}
            elevation={0}
          >
            <Typography
              fontSize={24}
              fontWeight={"bold"}
              color={"text.primary"}
            >
              Select a payment method
            </Typography>
            <div className="mt-3">
              <CollapseWithTitle
                title={"E-Wallets"}
                open={eWalletsCollapse}
                setOpen={setEWalletsCollapse}
              />

              <Collapse in={eWalletsCollapse}>
                {paymentMethods.map((x, i) => {
                  return location?.state?.wallet == "true" &&
                    x.title === PAYMENT_METHOD.wallet ? null : (
                    <div
                      className="d-flex justify-content-between  align-items-center gap-2 py-4"
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (x?.title == paymentMethod) {
                          setPaymentMethod("");
                        } else {
                          setPaymentMethod(x?.title);
                        }
                      }}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <Card
                          className="d-flex justify-content-center align-items-center"
                          elevation={0}
                          sx={{
                            width: 80,
                            height: 50,
                            border: "1px solid #F6F6F6",
                            background: i === 3 ? "#635BFF" : "white",
                          }}
                        >
                          <img src={x.icon} />
                        </Card>
                        <Typography
                          color="#07262E"
                          fontSize={16}
                          fontWeight={500}
                        >
                          {x.title}
                        </Typography>
                      </div>
                      <div className="checked_icon">
                        {x.title === paymentMethod ? CheckIcon() : null}
                      </div>
                    </div>
                  );
                })}

                <hr style={{ color: "#f3f3f3" }} />

                <Typography
                  fontSize={18}
                  fontWeight={"bold"}
                  color={"text.secondary"}
                >
                  Saved Cards ({cardData?.length})
                </Typography>
                <Typography textAlign={"right"}>
                  <ButtonComponent
                    title={"Add Card"}
                    onClick={() => {
                      setStripeModal(true);
                      dispatch(
                        paymentOptionSlice.actions.setTheAddCardTrack(true)
                      );
                    }}
                  />
                </Typography>
                <hr style={{ color: "#f3f3f3" }} />
              </Collapse>
              <CollapseWithTitle
                title={"Add Credit or Debit Card"}
                open={cardCollapse}
                setOpen={setCardCollapse}
              />
              <Collapse in={cardCollapse}>
                <CardTable cardDataArr={cardData} />
              </Collapse>
            </div>
          </Card>
        </Grid>
        <Grid item md={4}>
          <OrderSummary
            title={"Order Summary"}
            items={[{ title: "Total Amount", amount: location.state.amount }]}
          />
          {!CARD_DETAIL._id && paymentMethod === PAYMENT_METHOD.payStack ? (
            <PaystackButton className="paystack-btn mt-3" {...componentProps} />
          ) : (
            <ButtonComponent
              sx={{ width: 264, height: 51 }}
              title={"Proceed"}
              className={"mt-3"}
              onClick={() => PaymentIntigration()}
            />
          )}
        </Grid>
      </Grid>
      {infoModal && (
        <SuccessFullySubmitModal
          open={infoModal}
          headingAndText={{
            mainHeading: "Doesnot proceed with your payment",
            middleText:
              "Select the Payment method or select the saved card once at a time",
          }}
          setOpen={setInfoModal}
          onCLick={() => {
            setInfoModal(false);
          }}
          btnText="Close"
        />
      )}
      {paymentDone && (
        <SuccessFullySubmitModal
          open={paymentDone}
          headingAndText={{
            mainHeading: <FaCircleCheck size={100} />,
            middleText: "Your Payment is successfully Done",
          }}
          setOpen={setPaymentDone}
          onCLick={() => {
            navigate(-1);
          }}
          btnText="Ok"
        />
      )}
      <StripeModal
        open={stripeModal}
        setOpen={setStripeModal}
        setPaymentDoneModal={setPaymentDone}
      />
    </Container>
  );
};

export default PaymentMethod;
