import React from "react";
import { API_Endpoints } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const AddRentApplications = (values) => {
  return axiosInstance
    .post(API_Endpoints.rentApplications.ADD_RENT_APPLICATION, values)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetRentProperties = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);
};
export const GetRentApplicationsService = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetRentApplicationDetail = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.rentApplications.GET_RENT_APPLICATION}/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const EditRentApplicationId = (payload) => {
  return axiosInstance
    .put(API_Endpoints.rentApplications.ADD_RENT_APPLICATION, payload)
    .then((res) => res.data)
    .catch((err) => err);
};
