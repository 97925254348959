import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

// function set the config file of the fluterwave
export const configFluterWaveObj = (data) => {
  const config = {
    public_key: "FLWPUBK_TEST-1a2acf36db3cb4dacc1d4500877ef233-X", // this is the test mode public key of the fluterwave gateway intigration
    tx_ref: Date.now(),
    amount: data?.amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: data?.email,
      phone_number: data?.phone,
      name: data?.fullName,
      role: data?.role,
    },
    customizations: {
      title: "Test Payment",
      description: "Payment for items in cart",
      logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
    meta: data?.meta,
  };
  return config;
};

// function set the config file of the paystack
export const payStackObj = (data) => {

  const config = {
    reference: new Date().getTime().toString(),
    email: data?.email,
    amount: data?.amount,
    publicKey: "pk_test_db9e3e625d89f39ace0be33b1550218e7603ed96",
    currency: "NGN",
    metadata: {
      propertyID: data?.propertyID,
      userID: data?.userID,
      wallet: data?.wallet
    },
  };
  return config;
};

//get the client scret key from the backend
export const getTheClientSecretKey = (body) => {
  return axiosInstance
    .post(API_Endpoints.payment.getClientSecretId, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// dummy api for the transaction for both the fulterwave and paystack
export const dummyPostRequestForGateway = (body) => {
  return axiosInstance
    .post(`/dummy/transaction`, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// add card api
export const addCardService = (body) => {
  return axiosInstance
    .post(API_Endpoints.payment.addCard, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// get card
export const getCardService = () => {
  return axiosInstance
    .get(`${API_Endpoints.payment.addCard}s?page=1&count=10&search`)
    .then((res) => res.data)
    .catch((err) => err);
};

//delete the card
export const deteleTheCardService = (id) => {
  return axiosInstance
    .delete(`${API_Endpoints.payment.addCard}?id=${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

// pay with wallet 
export const payWithWallet = (body) => {
  return axiosInstance
    .post(API_Endpoints.payment.payViaCard, body)
    .then((res) => res.data)
    .catch((err) => err);
};
