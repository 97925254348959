import { API_Endpoints } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const addTheReview = (body) => {
  return axiosInstance
    .post(API_Endpoints.review.Add_review, body)
    .then((res) => res.data)
    .catch((err) => err);
};

// this api service will work with dynamic url as per the requirement of the review like by user by property
export const getReview = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);
};

export const changeTheStatusOfReview= (body)=>{
  return axiosInstance
    .put(API_Endpoints.review.CHANGE_STATUS,body)
    .then((res) => res.data)
    .catch((err) => err);
}

export const deleteReview = (id)=>{
  return axiosInstance
  .delete(`${API_Endpoints.review.Add_review}?id=${id}`)
  .then((res) => res.data)
  .catch((err) => err);
}
