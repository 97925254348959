import { TextareaAutosize, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../assets/theme";
import ButtonComponent from "./Button";

export default function DeleteSuccessFullyModal({
  open,
  setOpen,
  headingAndText = {
    mainHeading: "",
    middleText: "",
  },
  onCLick = () => {},
  loading = false,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={30}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          {headingAndText?.mainHeading}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          fontSize={18}
          fontWeight={400}
          color={theme.palette.text.primary}
          textAlign={"center"}
          marginBottom={"35px"}
        >
          {headingAndText?.middleText}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "16px" }}>
        <ButtonComponent title={"Cancel"} onClick={() => setOpen(false)} />
        <ButtonComponent
          title={"Delete"}
          onClick={() => onCLick && onCLick()}
          loading={loading}
          disabled={loading}
        />
      </DialogActions>
    </Dialog>
  );
}
