import { useState } from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import Iconify from "../../components/iconify";

// ----------------------------------------------------------------------

export default function UserTableRow({
  _id,
  selected,
  name,
  avatarUrl,
  email,
  mobile,
  status,
  setDeleteDialog,
  setDisableUser,
  naviagteType,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>{email}</TableCell>

        <TableCell>{mobile}</TableCell>

        {location.pathname === "/manage-users" ? (
          <TableCell align="left">
            {name.startsWith("A")
              ? "Property Manager"
              : name.startsWith("D")
              ? "Landlord"
              : name.startsWith("B")
              ? "Renter"
              : "Landlord"}
            {name}
          </TableCell>
        ) : null}

        <TableCell>
          <Switch
            color="primary"
            checked={status}
            onChange={(e, checked) => {
              setDisableUser(_id, status);
            }}
          />
        </TableCell>

        <TableCell align="left">
          <Iconify
            onClick={() => {
              localStorage.setItem("userNavigate", naviagteType);
              navigate("/admin/manage-users/user-details", {
                state: { id: _id, type: naviagteType },
              });
            }}
            icon="solar:eye-linear"
          />
          &nbsp;
          <Iconify
            onClick={() => setDeleteDialog(_id)}
            icon="mingcute:delete-line"
          />
        </TableCell>
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  avatarUrl: PropTypes.any,
  email: PropTypes.any,
  handleClick: PropTypes.func,
  isVerified: PropTypes.any,
  name: PropTypes.any,
  mobile: PropTypes.any,
  selected: PropTypes.any,
  status: PropTypes.string,
};
