import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rentDetail: {},
  withDrawModalStatus: false,
};

export const rentApplicationSlice = createSlice({
  name: "rentApplication",
  initialState: initialState,
  reducers: {
    rentApplicationModal: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        withDrawModalStatus: !data.withDrawModalStatus,
      };
      return data;
    },
    setTheRentApplicationData: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        rentDetail: action.payload,
      };
      return data
    },
  },
});
