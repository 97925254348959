import axiosInstance from "../../utils/axios";
import { ADMIN_API_ENDPOINTS } from "../API_Endpoints";

export const adminManageaddRole = (body) => {
  return axiosInstance
    .post(ADMIN_API_ENDPOINTS.manageRole.addPermissions, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const adminManagegetRole = (params) => {
  return axiosInstance
    .get(
      params
        ? `${ADMIN_API_ENDPOINTS.manageRole.addPermissions}?${params}`
        : ADMIN_API_ENDPOINTS.manageRole.addPermissions
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};

export const adminManagedeleteRole = (id) => {
  return axiosInstance
    .delete(`${ADMIN_API_ENDPOINTS.manageRole.addPermissions}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};
export const adminManageeditRole = (body, id) => {
  return axiosInstance
    .put(`${ADMIN_API_ENDPOINTS.manageRole.addPermissions}/${id}`, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};
