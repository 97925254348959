import { createSlice } from "@reduxjs/toolkit";

export const paymentInitialState = {
  ADD_CARD: false,
  API_CALL: false,
  CARD_DETAIL: {
    card_id: "",
    createdAt: "",
    customer_id: "",
    exp_month: 0,
    exp_year: 0,
    gateway_type: "",
    id: "",
    isPrimary: false,
    last4: "",
    name: null,
    updatedAt: "",
    user_id: "",
    _id: "",
  },
};

export const paymentOptionSlice = createSlice({
  name: "paymentOption",
  initialState: paymentInitialState,
  reducers: {
    // this function will have boolean value
    //true value user click on the add card button and false user didnot click
    setTheAddCardTrack: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        ADD_CARD: action.payload,
      };
      return data;
    },
    setTheGetApiModalCallOntheDeleteOrAddCard: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        API_CALL: !data?.API_CALL,
      };
      return data;
    },
    setTheSavedCardDetail:(state,action)=>{
      let data ={...state}
      data={
        ...data,
        CARD_DETAIL: action.payload
      }
      return data
    }
  },
});
