import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import commercial from "../../assets/images/commercial.png";
import renterHomeBanner from "../../assets/images/renterHomeBanner.png";
import residential from "../../assets/images/residential.png";
import shortTerm from "../../assets/images/shortTerm.png";
import { theme } from "../../assets/theme";
import ButtonComponent from "../../components/Button";
import MarqueText from "../../components/marqueeText";
import Property from "../../components/Property";
import { PropertyListService } from "../../services/properties.services";
import { FaAngleDoubleUp } from "react-icons/fa";
import { FormikProvider, useFormik } from "formik";
import PlacesAutoComplete from "../../components/PlacesAutocomplete";
import { propertyType } from "../../sections/user/utils";
import { RetrieveLocalUser } from "../../utils/authService";
import { baseURL } from "../../utils/axios";
import { budgetDropDownArray, propertyTypeCategory, ROLES } from "../../utils/CONSTANTS";
import useGeolocation from "../../utils/geoLocation";
import GlobalSearchComponent from "../../components/globalSearchComponent";
const RenterLandingPage = () => {
  const user = RetrieveLocalUser("user");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      address: "",
    },
  });
  const { location, error, getLocation } = useGeolocation();

  console.log(location, "lsssocation");
  const [loading, setLoading] = useState(true);
  const [propertiesInDemand, setPropertiesInDemand] = useState([]);

  const [propertiesAroundYou, setPropertiesAroundYou] = useState([]);
  const [type, setType] = useState("");

  const [latlng, setLatLng] = React.useState({ lat: "", lng: "" });
  console.log("setfield", latlng, formik.values);
  const [filterProperties, setFilterProperties] = useState({
    latitude: latlng.lat,
    longitude: latlng.lng,
    maxDistance: 200,
    budget: {
      min: 0,
      max: 0,
    },
    type: "",
    address: "",
  });

  const GetPropertiesInDemand = async () => {
    setLoading(true);
    let object = {
      filters: {
        inDemand: true,
      },
    };
    const response = await PropertyListService(object);
    console.log("Response", response);
    if (response.status) {
      setPropertiesInDemand(response.data);
    }
    setLoading(false);
  };

  const FindPropertiesAroundYou = async () => {
    getLocation();
    setLoading(true);
    const response = await PropertyListService({
      latitude: "30.705977",
      longitude: "76.707659",
      // nearByProperty: error === "User denied Geolocation" ? false : true,
      nearByProperty: false,
      maxDistance: 2,
    });
    console.log("Response", response);
    if (response.status) {
      setPropertiesAroundYou(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetPropertiesInDemand();
    FindPropertiesAroundYou();
  }, []);

  useEffect(() => {
    if (type) {
      setFilterProperties((pre) => {
        return { ...pre, type: type.toLocaleLowerCase() };
      });
    }
  }, [type]);

  useEffect(() => {
    setFilterProperties((prev) => ({
      ...prev,
      longitude: latlng.lng,
      latitude: latlng.lat,
      address: formik.values.address,
    }));
  }, [latlng]);
  return (
    <>
      {user?.kinDetails?.identificationType &&
      user?.role === ROLES.renter ? null : (
        <MarqueText />
      )}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        px={6}
        alignItems={"center"}
        sx={{
          backgroundImage: `url(${renterHomeBanner})`,
          width: "100%",
          backgroundSize: "cover",
          height: 497,
        }}
      >
        <div className="renterhomepage">
          <Typography
            sx={{
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "3rem",
                xl: "4rem",
              },
            }}
            fontWeight={"800"}
            color={"#13556D"}
            className="renter-banner-heading"
          >
            Unlocking the Door to
            <br /> Your Perfect Home
          </Typography>
          <Typography
            fontSize={24}
            color={"#07262E"}
            className="renter-banner-subheading"
          >
            Discover, Explore, and Find Your Dream House Today!
          </Typography>
        </div>
      </Box>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-center  text-center mt-3 renter-form"
      >
        <Card
          elevation={0}
          sx={{
            mt: -7,
            position: "relative",
            zIndex: 1,
            boxShadow: "5px 5px 19px 4px #0000000d",
            width: "70%",
            overflow: "visible",
          }}
        >
          <GlobalSearchComponent/>
        </Card>
      </div>
      <Container maxWidth="xl">
        <div className="d-flex align-items-center gap-2 mt-5">
          <hr style={{ border: "1px solid #008F97", width: 40 }} />
          <Typography color="#009DA6" fontSize={24}>
            Explore
          </Typography>
        </div>
        <Typography
          fontSize={41}
          fontWeight={700}
          color={"#2A2A2A"}
          className="phone-heading-30"
        >
          Properties In Demand
        </Typography>

        <Grid container spacing={4} className="mt-3">
          {propertiesInDemand?.map((x, i) => {
            return (
              <Grid item md={4} sm={12} key={i} className="phone-full-width">
                <Property {...x} img={x.images[0].url} />
              </Grid>
            );
          })}
        </Grid>

        <div className="text-center">
          <ButtonComponent
            onClick={() => navigate("/properties-in-demand")}
            sx={{ width: 144, height: 55 }}
            title="Explore"
          />
        </div>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div className="d-flex align-items-center gap-2 mt-5">
            <hr style={{ border: "1px solid #008F97", width: 40 }} />
            <Typography color="#009DA6" fontSize={24}>
              Categories
            </Typography>
          </div>
          <Typography
            fontSize={40}
            fontWeight={500}
            color={theme.palette.text.primary}
            className="phone-heading-30"
          >
            Our Property Categories
          </Typography>
          <Typography color="text.secondary" fontSize={18} fontWeight={500}>
            Find Your Fit: Whether short-term stays, cozy homes, workspaces, or
            retail spots – our categories cover it all for your necessities.
          </Typography>
        </div>
        <Grid container spacing={3} className="mt-4">
          <Grid item md={6} className="property-type">
            <Grid container spacing={3}>
              <Grid item md={12} className="property-type">
                <Box
                  onClick={() =>
                    navigate(`/filter-properties`, {
                      state: "commercial",
                    })
                  }
                  sx={{
                    backgroundImage: `url(${commercial})`,
                    height: 302,
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Typography
                    position="absolute"
                    bottom={0}
                    right={0}
                    margin={1}
                    left={0}
                    fontWeight={"bold"}
                    fontSize={32}
                    color={"white"}
                  >
                    Commercial
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={12} className="property-type">
                <Box
                  onClick={() =>
                    navigate(`/filter-properties`, {
                      state: "short stay",
                    })
                  }
                  sx={{
                    backgroundImage: `url(${shortTerm})`,
                    height: 302,
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                  }}
                >
                  <Typography
                    position="absolute"
                    bottom={0}
                    right={0}
                    margin={1}
                    left={0}
                    fontWeight={"bold"}
                    fontSize={32}
                    color={"white"}
                  >
                    Short-term Stays
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} className="property-type residental-img">
            <Box
              onClick={() =>
                navigate(`/filter-properties`, {
                  state: "residential",
                })
              }
              sx={{
                backgroundImage: `url(${residential})`,
                height: 630,
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: "relative",
              }}
            >
              <Typography
                position="absolute"
                bottom={0}
                right={0}
                margin={1}
                left={0}
                fontWeight={"bold"}
                fontSize={32}
                color={"white"}
              >
                Residential
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <div className="d-flex align-items-center gap-2 mt-5">
          <hr style={{ border: "1px solid #008F97", width: 40 }} />
          <Typography color="#009DA6" fontSize={24}>
            Nearby
          </Typography>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <Typography
            fontSize={41}
            fontWeight={700}
            color={"#2A2A2A"}
            className="phone-heading-30"
          >
            Properties Around You
          </Typography>
          <div className="text-end">
            <Typography
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/filter-properties`, {
                  state: { filters: { category: "" } },
                });
              }}
              fontSize={16}
              color="#C8D736"
              fontWeight={"bold"}
              textAlign={"center"}
            >
              <FaAngleDoubleUp />
              <br />
              <span className="d-block">View More</span>
            </Typography>
          </div>
        </div>

        <Grid container spacing={4} className="mt-3 mb-5">
          {propertiesAroundYou?.slice(0, 9)?.map((x, i) => {
            let img = "";
            x.images.map((file) => {
              return baseURL.includes("ngrok")
                ? (img =
                    "https://ce98-2401-4900-1c2b-4402-11a9-f793-4238-349b.ngrok-free.app/property" +
                    file.url.split("property")[1])
                : (img = file.url.replace("https:", "https:/"));
            });

            return (
              <Grid item md={4} sm={12} key={i} className="phone-full-width">
                <Property isFavourite={x?.liked} {...x} img={img} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};
export default RenterLandingPage;
