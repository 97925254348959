import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import Loader from "../../../components/loader";
import {
  ApplicationRequestStatusChangedService,
  GetApplicationListService,
} from "../../../services/application.services";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import InspectionApplicationRequestModal from "../../common/inspection-request/inspectionRequestModal";
import "./style.css";
import { fDate } from "../../../utils/format-time";
const ApplcationRecieved = () => {
  const [cancelModal, setCancelModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [filterValue, setFilterValue] = useState(
    inspectionApplicationRequestStatus.pending
  );
  const [apiNotCallOnCancel, setApiNotCallOnCancel] = useState("");
  const [modalData, setModalData] = useState({});
  const [applicationListing, setApplicationListing] = useState([]);
  const [mainApiArray, setMainApiArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate();
  // get the list of application from the backend
  const getTheListofApplication = async () => {
    setLoading(true);
    const getData = await GetApplicationListService();
    setMainApiArray(getData?.data);
    if (getData?.data?.length > 0 && getData?.status) {
      const dataFilter = getData?.data?.filter(
        (item) =>
          item.applicationStatus == inspectionApplicationRequestStatus?.pending
      );
      setApplicationListing(dataFilter);
    }
    console.log("getData", getData);
    setLoading(false);
  };

  // accept the request of application
  const applicationReqAccept = async (data) => {
    const body = {
      status: inspectionApplicationRequestStatus?.accept,
      rentApplicationID: data?._id,
    };
    setBtnLoader(true);
    const changeStatus = await ApplicationRequestStatusChangedService(body);
    if (changeStatus.status) {
      setModalData(data);
      setAcceptModal(true);
    } else {
      alert("Some thing went wrong");
    }
    setBtnLoader(false);
  };

  // const filter the data
  const filterValueData = (value) => {
    setFilterValue(value);
    let filterArr = [...mainApiArray];
    if (value === inspectionApplicationRequestStatus.accept) {
      const data = filterArr?.filter(
        (item) =>
          item.applicationStatus === inspectionApplicationRequestStatus.accept
      );
      setApplicationListing(data);
      console.log("-data", data);
    } else if (value === inspectionApplicationRequestStatus.pending) {
      const data = filterArr?.filter(
        (item) =>
          item.applicationStatus === inspectionApplicationRequestStatus.pending
      );
      setApplicationListing(data);
      console.log("-ssssdata", data);
    }
  };

  useEffect(() => {
    if (!cancelModal && !acceptModal) {
      // this check is implemented if user click on cancel button and outside of the modal
      if (apiNotCallOnCancel === "cancel") {
        return;
      }
      getTheListofApplication();
    }
  }, [cancelModal, acceptModal]);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container maxWidth={"xl"}>
          <Box>
            <Typography
              fontSize={40}
              fontWeight={700}
              color={theme.palette.text.primary}
              marginBottom={"20px"}
              className="title_page pt-4 phone-heading-30"
            >
              Applications Received
            </Typography>
          </Box>
          <Box>
            <Grid container className="top_row align-items-center">
              <Grid md={8}>
                <h5 className="h5_title">List of rent applications</h5>
              </Grid>
              <Grid md={4}>
                <div className="tabs_style">
                  <button
                    onClick={() => {
                      filterValueData(
                        inspectionApplicationRequestStatus.pending
                      );
                    }}
                    className={
                      filterValue === inspectionApplicationRequestStatus.pending
                        ? `btn_tab active`
                        : "btn_tab"
                    }
                  >
                    Pending
                  </button>
                  <button
                    onClick={() => {
                      filterValueData(
                        inspectionApplicationRequestStatus.accept
                      );
                    }}
                    className={
                      filterValue === inspectionApplicationRequestStatus.accept
                        ? `btn_tab active`
                        : "btn_tab"
                    }
                  >
                    Accepted
                  </button>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box className="mb-5">
            {applicationListing?.length === 0 ? (
              <h3>No Application Received</h3>
            ) : (
              applicationListing &&
              applicationListing?.map((applicationData) => (
                <Grid container className="pt-3">
                  <Grid
                    className="applications_card my-1"
                    sx={{
                      borderRadius: 3,
                      boxShadow: "5px 5px 19px 4px #0000000d",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Grid className="left_detail">
                      <div
                        className="img_block"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/applicant-details/${applicationData?._id}`)
                        }
                      >
                        <img
                          style={{
                            width: "180px",
                            height: "180px",
                            borderRadius: "50%",
                          }}
                          src={applicationData?.renter_info[0]?.picture}
                          alt="no img"
                        />
                      </div>
                      <div className="app_person_details">
                        <h3>{applicationData?.renter_info[0]?.fullName}</h3>
                        <h5>
                          {applicationData?.property_info[0]?.propertyName}
                        </h5>
                        <p>{applicationData?.renter_info[0]?.phone}</p>
                        <span>{applicationData?.checkinDate}</span>
                      </div>
                    </Grid>
                    {/* this conditional rendering is for the that these button will only show when the applicationstatus is pending from the backend */}
                    {applicationData.applicationStatus ===
                      inspectionApplicationRequestStatus.pending && (
                      <Grid className="right_btns text-end">
                        <button
                          onClick={() => {
                            setCancelModal(true);
                            setModalData(applicationData);
                          }}
                          className="btn_common reject"
                        >
                          Reject
                        </button>
                        <ButtonComponent
                          onClick={() => applicationReqAccept(applicationData)}
                          className="btn_common"
                          title={"Accept"}
                          loading={btnLoader}
                          disabled={btnLoader}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))
            )}
          </Box>
          <LandlordCommonModal
            open={cancelModal}
            setOpen={setCancelModal}
            heading={"Reason for Rejection"}
            heading2={"Reason for Rejection"}
            detailData={modalData}
            apiNotCallOnCancel={setApiNotCallOnCancel}
            notificationText={`A notification has been sent to ${ modalData?.renter_info && modalData?.renter_info[0]?.fullName} regarding his Rent Application`}
          />
          <InspectionApplicationRequestModal
            open={acceptModal}
            setOpen={setAcceptModal}
            heading={"Request Accepted"}
            heading2={`You have accepted the Application request of ${
              modalData?.renter_info && modalData?.renter_info[0]?.fullName
            } for “${
              modalData?.property_info &&
              modalData?.property_info[0]?.propertyName
            }” on ${fDate(modalData?.checkinDate)}`}
            Customfunction={() => setApiNotCallOnCancel("")}
          />
        </Container>
      )}
    </div>
  );
};

export default ApplcationRecieved;
