import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const getTheDashboardData = ()=>{
    return axiosInstance 
    .get(API_Endpoints.dashboard.landlordDashoard)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}