import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextareaAutosize, Typography } from "@mui/material";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { CancelInspectionRequestByRenterService } from "../../../services/inspections.services";
import { NotificationManager } from "react-notifications";

export default function CancelInspectionModal({
  open,
  setOpen,
  inspectionId,
  setInspections,
  inspections,
}) {
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const handleSubmitCancelRequest = async () => {
    setLoading(true);
    const response = await CancelInspectionRequestByRenterService({
      status: "canceled",
      inspectionID: inspectionId,
      reason: reason,
    });

    if (response.status) {
      NotificationManager.success("", response.message);
      let newInspections = inspections.map((x) => {
        if (x._id === inspectionId) {
          x = { ...x, inspectionStatus: "canceled" };
        }
        return x;
      });
      setInspections(newInspections);
    } else {
      NotificationManager.error("", response.message);
    }
    setLoading(false);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            fontSize={32}
            fontWeight={700}
            color={theme.palette.text.primary}
            textAlign={"center"}
          >
            Inspection Cancel Reason
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextareaAutosize
            onChange={(event) => setReason(event.target.value)}
            size="lg"
            name="Size"
            placeholder="Type Here.."
            minRows={3}
            className="p-1"
            style={{
              width: "100%",
              border: "1px solid #B2BCBE",
              borderRadius: "7px",
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <ButtonComponent
            loading={loading}
            disabled={loading || reason.length < 1}
            onClick={handleSubmitCancelRequest}
            title={"Submit"}
            color={"secondary"}
            sx={{ marginBottom: "20px" }}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
