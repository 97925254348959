import React, { useState } from "react";
import AuthenticationLayout from ".";
import { Typography } from "@mui/material";
import OTPInput from "react-otp-input";
import ButtonComponent from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailVerificationService } from "../../services/authServices";
import { NotificationSnackbar } from "../../components/notificationSnackbar";
import { setLocalUser } from "../../utils/authService";
import axios from "axios";
import { baseURL } from "../../utils/axios";
import { API_Endpoints } from "../../services/API_Endpoints";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { authSlice } from "../../redux/auth/authSlice";

const EmailVerification = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { forgotPassword,USER_ID,FCM_TOKEN } = useSelector((state) => state.AUTH);
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleEmailVerification = async () => {
    await axios
      .post(baseURL + API_Endpoints.auth.OTP_VERIFICATION, {
        id: USER_ID,
        otp: otp,
        fcmToken: FCM_TOKEN
      })
      .then((res) => {
        // NotificationManager.success("", res.data.message);
        console.log(res, "ressssss");
        if (res.data.data.status) {
          setLocalUser({ ...res.data.data, accessToken: res.data.accessToken });
          if (res.data.data.role === "Renter") {
            navigate("/renter");
          } else if (res.data.data.role === "Landlord") {
            navigate("/landlord-dashboard");
          } else {
            navigate("/property-manager");
          }
        }
      })
      .catch((err) => {
        // NotificationManager.error("", err.response.data.message);
      });
  };

  const handleResendOTP = async () => {
    await axios
      .post(baseURL + API_Endpoints.auth.RESEND_OTP, { _id: state?.data?.id || forgotPassword?.id})
      .then((res) => {
        console.log(res, "resssss");
        NotificationManager.success("", res.data.message);
      })
      .catch(() => {});
  };

  // email verfication for the forgotpassword
  const emailVerficationForgotPassword = () => {
    if (otp?.length != 4) {
      return NotificationManager.error("", "Check your OTP");
    }
    setLoading(true);
    axios
      .post(baseURL + API_Endpoints.auth.VERIFY_OTP_FORGOTPASSWORD, {
        id: forgotPassword?.id,
        otp: otp,
      })
      .then((res) => {
        console.log("re", res);
        if (res?.data?.status) {
          NotificationManager.success("", res?.data?.message);
          navigate("/create-password");
          dispatch(
            authSlice.actions.setforgotPasswordUserId({
              ...forgotPassword,
              accessToken: res?.data?.data?.accessToken,
            })
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        if (!err?.response?.data?.status) {
          NotificationManager.error("", err?.response?.data?.message);
        }
        setLoading(false);
      });
  };

  return (
    <AuthenticationLayout>
      <div>
        <Typography
          fontSize={24}
          fontWeight={"bold"}
          color="#111111"
          className="text-center"
          mb={2}
        >
          Email Verification
        </Typography>
        <Typography fontSize={16} color="#8D9B9F" className="text-center">
          Please enter the 4 digit code to verify your email
        </Typography>
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputStyle={{
            height: 80,
            width: 80,
            margin: 10,
            marginTop: 30,
            borderRadius: 5,
            fontSize: 28,
            fontWeight: "bold",
            border: "1px solid #B2BCBE",
            outlineColor: "#13556D",
          }}
          renderSeparator={null}
          renderInput={(props) => <input {...props} />}
        />
        <ButtonComponent
          onClick={
            forgotPassword?.id
              ? emailVerficationForgotPassword
              : handleEmailVerification
          }
          title="Confirm"
          sx={{ width: "100%", mt: 3, height: 53 }}
          color="secondary"
          loading={loading}
          disabled={loading}
        />
        <Typography
          onClick={handleResendOTP}
          sx={{ cursor: "pointer" }}
          className="text-center mt-4"
          color="#395158"
          fontSize={14}
          fontWeight={400}
        >
          Resend Code
        </Typography>
      </div>
    </AuthenticationLayout>
  );
};

export default EmailVerification;
