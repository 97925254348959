import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LocationIcon, TelePhone } from "../../../assets/svg";
import { theme } from "../../../assets/theme";
import PropertyDetailCard from "../../../components/propertyDetailCard";
import { myRenterSlice } from "../../../redux/my-renters/myRentersSlice";
import {
  GetRentedPropetyById,
  getRenterHistory,
  GetTheRenterPastHIstory,
} from "../../../services/renterService";
import { PropertyPropsObjEnum } from "../../../utils/appConstants";
import { userDetail } from "../../../services/userServices";

export default function RenterDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { renterStatus } = useSelector((state) => state.MYRENTERS);
  const [renterHistoryData, setRenterHistoryData] = useState({
    properties: [],
    renter: {
      email: "",
      fullName: "",
      phone: "",
      picture: "",
      _id: "",
    },
  });
  // get the renter history
  const getTheRenterHistory = async () => {
    const getData = await userDetail(id);
    console.log("getDatauser", getData);
    if (getData?.status) {
      setRenterHistoryData((prev) => ({
        ...prev,
        renter: getData?.data,
      }));
      dispatch(
        myRenterSlice.actions.setTerminateTenancyRenterDetail({
          picture: getData?.data?.picture,
          fullName: getData?.data?.fullName,
        })
      );
    }
  };

  //get the rented property
  const getTheRentedProperty = async () => {
    const getData = renterStatus
      ? await GetTheRenterPastHIstory(id)
      : await GetRentedPropetyById(id);
    console.log("getData", getData);
    if (getData?.status) {
      setRenterHistoryData((prev) => ({
        ...prev,
        properties: getData?.data[0]?.properties,
      }));
    }
  };

  useEffect(() => {
    getTheRenterHistory();
    getTheRentedProperty();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box marginTop={"23px"}>
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          My Renters
        </Typography>
      </Box>
      <Grid container>
        <Grid
          item
          md={6}
          border={"1px solid #DBDBDB"}
          padding={"14px"}
          borderRadius={"10px"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={6}
            alignItems={"center"}
            sx={{
              backgroundImage: "url(/images/header-back.png)",
              height: 250,
              backgroundRepeat: "repeat",
              borderRadius: 2,
            }}
          >
            <Box
              height={167}
              width={167}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"50%"}
              border={"1px solid white"}
            >
              <img
                src={renterHistoryData?.renter?.picture}
                style={{ height: "155px", width: "155px", borderRadius: "50%" }}
              />
            </Box>
            <div>
              <div className="d-flex align-items-center  gap-2">
                <Typography color={"white"} variant="h3">
                  {renterHistoryData?.renter?.fullName}
                </Typography>

                <img
                  src={"/images/user-verified.png"}
                  style={{ height: 18, width: 62 }}
                />
              </div>

              <Typography
                color="#C8D736"
                fontSize={18}
                fontWeight={"bold"}
                variant="body1"
              >
                Renter
              </Typography>
              <Typography
                color="#C8D736"
                fontSize={18}
                fontWeight={"bold"}
                variant="body1"
              >
                Chat Now
              </Typography>
            </div>
          </Box>
        </Grid>
        <Grid item md={6} paddingLeft={"30px"} paddingRight={"30px"}>
          {/* <Box
            border={"1px solid #009DA6"}
            padding={"18px"}
            borderRadius={"10px"}
          >
            <div className="d-flex justify-content-between">
              <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                Property rented on
              </Typography>
              <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                Lease will end on
              </Typography>
            </div>
            <div className="d-flex justify-content-between">
              <Typography color={"#13556D"} fontWeight={700} fontSize={24}>
                Property rented on
              </Typography>
              <Typography color={"#13556D"} fontWeight={700} fontSize={24}>
                Lease will end on
              </Typography>
            </div>
          </Box> */}
        </Grid>
      </Grid>
      <Box paddingLeft={"16px"} marginTop={"30px"}>
        <Typography color={"#07262E"} fontWeight={700} fontSize={24}>
          Contact Info
        </Typography>
        <Typography
          className="mt-3 mb-3"
          color={"#07262E"}
          fontWeight={400}
          fontSize={18}
        >
          <span style={{ marginRight: "10px" }}>{TelePhone()}</span>{" "}
          {renterHistoryData?.renter?.phone}
        </Typography>
        <Typography
          className="mt-3 mb-3"
          color={"#07262E"}
          fontWeight={400}
          fontSize={18}
        >
          {/* <span style={{ marginRight: "10px" }}>{LocationIcon()}</span> 6th
          Floor Tower A, iTech Spaze Building, */}
        </Typography>
      </Box>
      <Box marginTop={"39px"}>
        <Typography color={"#000"} fontWeight={700} fontSize={32}>
          Rented Property
        </Typography>
        {renterHistoryData?.properties?.length != 0 &&
          renterHistoryData?.properties?.map((item) => (
            <PropertyDetailCard
              data={{ ...item, ...{ rented: true } }}
              buttonShow={PropertyPropsObjEnum.terminateBtnShow}
            />
          ))}
      </Box>
    </Container>
  );
}
