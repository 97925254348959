import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CustomTextField from "../TextField";

const PlacesAutoComplete = ({
  setFieldValue,
  value,
  setLatLng,
  placeholder = "Enter address",
}) => {
  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      console.log("results", results);
      const addressComponents = results[0].address_components;
      console.log("addressComponents", addressComponents);
      const cityComponent = addressComponents?.find((component) =>
        component?.types?.includes("locality")
      );
      const city = cityComponent ? cityComponent.long_name : value;
      // setFieldValue("city", city);
      setFieldValue("address", value.trimStart());
      setLatLng({ lat: latLng.lat, lng: latLng.lng });
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <PlacesAutocomplete
      value={value}
      onChange={(v) => {
        if (!v) {
          setLatLng({ lat: "", lng: "" });
        }
        setFieldValue("address", v);
      }}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="position-relative">
          {console.log(suggestions, "suggestionsss")}
          <CustomTextField
            name={"address"}
            {...getInputProps({ placeholder: placeholder })}
          />
          <div className="list_items">
            {loading ? <div>Loading...</div> : null}

            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                padding: "10px",
              };

              return (
                <div {...getSuggestionItemProps(suggestion, { style })}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default PlacesAutoComplete;
