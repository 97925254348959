import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import PlacesAutoComplete from "./PlacesAutocomplete";
import {
  budgetDropDownArray,
  propertyTypeCategory,
  PRPOPERTY_TYPE,
} from "../utils/CONSTANTS";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ButtonComponent from "./Button";
import { useNavigate } from "react-router-dom";
export default function GlobalSearchComponent() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      address: "",
    },
  });
  const [type, setType] = useState("");
  const [latlng, setLatLng] = React.useState({ lat: "", lng: "" });
  const [filterProperties, setFilterProperties] = useState({
    latitude: latlng.lat,
    longitude: latlng.lng,
    maxDistance: 200,
    budget: {
      min: 0,
      max: 0,
    },
    type: "",
    address: "",
  });
  useEffect(() => {
    if (type) {
      setFilterProperties((pre) => {
        return { ...pre, type: type.toLocaleLowerCase() };
      });
    }
  }, [type]);

  useEffect(() => {
    setFilterProperties((prev) => ({
      ...prev,
      longitude: latlng.lng,
      latitude: latlng.lat,
      address: formik.values.address,
    }));
  }, [latlng]);
  return (
    <div className="row banner_filter d-flex justify-content-center align-items-center p-3">
      <div className="col-md-3">
        <FormikProvider value={formik}>
          <PlacesAutoComplete
            setLatLng={setLatLng}
            value={formik.values.address}
            setFieldValue={(key, value) => formik.setFieldValue(key, value)}
            key={"autoComplete"}
            placeholder="Location"
          />
        </FormikProvider>
      </div>
      <div className="col-md-3">
        {/* <TextField
                fullWidth
                label="Type"
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <HomeTwo />
                    </InputAdornment>
                  ),
                }}
              /> */}

        <FormControl fullWidth>
          <InputLabel>Select type</InputLabel>
          <Select
            fullWidth
            onChange={(e) =>
              setFilterProperties((prev) => ({
                ...prev,
                type: e.target.value,
              }))
            }
            label="Select type"
            placeholder="Select type"
            value={filterProperties.type}
          >
            {PRPOPERTY_TYPE.map((x) => {
              return <MenuItem value={x.value}>{x.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      <div className="col-md-3">
        <FormControl fullWidth>
          <InputLabel>Budget</InputLabel>
          <Select
            fullWidth
            label="Budget"
            placeholder="Budget"
            value={
              filterProperties?.budget?.min === 0
                ? ""
                : `${filterProperties?.budget?.min}-${filterProperties?.budget?.max}`
            }
            onChange={(e) => {
              const splitValue = e.target.value.split("-");
              setFilterProperties((prev) => ({
                ...prev,
                budget: {
                  min: Number(splitValue[0]),
                  max: Number(splitValue[1]),
                },
              }));
            }}

            // inputProps={{
            //   endAdornment: (
            //     <InputAdornment>
            //       <WalletIcon />
            //     </InputAdornment>
            //   ),
            // }}
          >
            {budgetDropDownArray.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-md-3 search-btn">
        <ButtonComponent
          sx={{ width: 126 }}
          title={"Search"}
          onClick={() => {
            navigate("/filter-properties", { state: filterProperties });
          }}
        />
      </div>
    </div>
  );
}
