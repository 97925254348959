import React, { useEffect, useState } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Sidebar from "./layout/Sidebar";
import { usePathname } from "../routes/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutDialog from "../dialogs/logoutDialog";
const Dashboard = ({ children }) => {
  const [sidebarToggleState, setSidebarToggleState] = useState(false);

  const [logoutDialog, setLogoutDialog] = useState(false);
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  console.log("sadas", children);
  return (
    <div className="app-container">
      <header className="header">
        {/* Header content goes here */}
        <Header setOpen={setSidebarToggleState} open={sidebarToggleState} />
      </header>

      <div className="main-container">
        <main className="content">
          {children}
          <aside className="sidebar">
            {/* Sidebar content goes here */}
            <Sidebar
              setLogoutDialog={setLogoutDialog}
              open={sidebarToggleState}
              setOpen={setSidebarToggleState}
            />
          </aside>
        </main>
      </div>

      <footer className="footer">
        <Footer />
      </footer>

      {logoutDialog && (
        <LogoutDialog
          open={logoutDialog}
          setOpen={setLogoutDialog}
          setSidebarToggleState={setSidebarToggleState}
        />
      )}
    </div>
  );
};

export default Dashboard;
