import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../components/iconify";
import { adminManageeditRole } from "../../services/admin/adminManageRoleServices";
import { permissions } from "../../utils/CONSTANTS";
import { toast } from "react-toastify";
const EditNewRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  // main state of the form
  const [formData, setFormData] = useState({
    roleName: { value: "", warning: false },
    selectedPermission: [
      {
        label: "User management ",
        value: permissions.USER_MANGEMENT,
        checked: false,
      },
      {
        label: "Manage Employees ",
        value: permissions.MANAGE_EMPLOYEES,
        checked: false,
      },
      {
        label: "Manage Property ",
        value: permissions.MANAGE_PROPERTY,
        checked: false,
      },
      {
        label: "Manage Role & Permissions ",
        value: permissions.MANAGE_ROLES,
        checked: false,
      },
      {
        label: "Review & Rating Management ",
        value: permissions.MANAGE_REVIEW,
        checked: false,
      },
      {
        label: "FInance Performance ",
        value: permissions.FINANCE_PERFORMANCE,
        checked: false,
      },
      {
        label: "Manage Transaction ",
        value: permissions.MANAGE_TRANSACTION,
        checked: false,
      },
      {
        label: "Activity Logs ",
        value: permissions.MANAGE_TRANSACTION,
        checked: false,
      },
    ],
  });
  
  const changeTheArrayAsValueCHecked = (e, index) => {
    let data = [...formData.selectedPermission];
    data.splice(index, 1, {
      ...data[index],
      checked: e.target.checked,
    });
    setFormData((prev) => ({
      ...prev,
      selectedPermission: data,
    }));
  };

  //onsubmit api intigration
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("--formData", formData);
    let data = { ...formData };
    data = {
      ...data,
      roleName: {
        ...data.roleName,
        warning: data.roleName.value === "",
      },
    };
    setFormData({ ...data });
    if (data.selectedPermission.filter((item) => item.checked).length === 0) {
      return NotificationManager.error("", "Select the permission");
    }
    if (
      !data.roleName.warning &&
      data.selectedPermission.filter((item) => item.checked).length != 0
    ) {
      onSubmitApiCall();
    }
  };

  // api call to save the data into the backend
  const onSubmitApiCall = async () => {
    setLoading(true);
    const body = {
      name: formData.roleName.value,
      permissions: formData.selectedPermission
        .filter((item) => item.checked)
        .map((item) => item.value),
    };
    const postData = await adminManageeditRole(body, location.state?._id);
    if (postData.status) {
      navigate("/admin/roles-and-permissions-management");
      toast.success("Role updated successfully.");
    } else {
      toast.error("Something went wrong please try again.");
    }
    setLoading(false);
    console.log("post", postData);
  };

  // manipulatethe data as per the sleected permisions
  const manipulatePermissons = () => {
    let data = { ...formData };
    data.selectedPermission?.forEach((element, index) => {
      location.state.permissions.forEach((checkValue) => {
        if (element.value === checkValue) {
          return (element.checked = true);
        }
      });
    });
    data = {
      ...data,
      roleName: {
        ...data.roleName,
        value: location.state.name,
      },
    };
    setFormData({ ...data });
  };

  useEffect(() => {
    manipulatePermissons();
  }, []);
  console.log(formData);
  return (
    <div className="px-5">
      <Stack direction="row" alignItems="center" gap={3} mt={3}>
        <Iconify onClick={() => navigate(-1)} icon="zondicons:arrow-left" />
        <Typography variant="h4">Edit Role</Typography>
      </Stack>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <TextField
            style={{ width: "55%" }}
            name="roleName"
            label="Name"
            type={"text"}
            value={formData.roleName.value}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                roleName: { value: e.target.value, warning: false },
              }))
            }
          />
        </Grid>
        {formData.roleName.warning && (
          <span className="text-danger">Enter the role name</span>
        )}
        <Grid item xs={12} className="mt-3">
          {formData.selectedPermission.map((item, index) => (
            <div className="d-flex align-items-center">
              <Checkbox
                checked={item.checked}
                onChange={(e) => changeTheArrayAsValueCHecked(e, index)}
                defaultChecked={item.checked}
              />
              <ListItemText primary={item.label} />
            </div>
          ))}
        </Grid>
      </Grid>
      <Button
        style={{ marginTop: 20 }}
        disabled={loading}
        size="medium"
        type="submit"
        variant="contained"
        color="inherit"
        className="global-button"
        onClick={(e) => {
          //   toast.success("New role added");
          onSubmit(e);
          // navigate(-1);
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : location?.state ? (
          "Update"
        ) : (
          "Save"
        )}
      </Button>
    </div>
  );
};
export default EditNewRole;
