import Dialog from "@mui/material/Dialog";
import * as React from "react";
import { replaceHttpImgUrl } from "../utils/helperFunctionForValidte";

export default function ViewPdfModal({ open, setOpen, data = {} }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="div_iframe">
        <iframe src={replaceHttpImgUrl(data?.url)} width="100%" height="100%" />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
