import React from "react";
import { Link } from "react-router-dom";
import {
  FacebookSidebarIcon,
  TwitterSidebarIcon,
  InstagramSidebarIcon,
  PinIntrestSidebarIcon,
  LinkedinSidebarIcon,
  DownloadFromGooglePlay,
  DownloadFromAppleStore,
} from "../../assets/svg";

const SidebarFooter = () => {
  return (
    <div className="py-3">
      <div className="d-flex align-items-center gap-4">
        <Link>
          <FacebookSidebarIcon />
        </Link>
        <Link>
          <TwitterSidebarIcon />
        </Link>
        <Link>
          <InstagramSidebarIcon />
        </Link>
        <Link>
          <LinkedinSidebarIcon />
        </Link>
        <Link>
          <PinIntrestSidebarIcon />
        </Link>
      </div>

      <div className="d-flex align-items-center gap-3 mt-4">
        <DownloadFromGooglePlay />
        <DownloadFromAppleStore />
      </div>
    </div>
  );
};

export default SidebarFooter;
