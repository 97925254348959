import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terminateTenancyRenterDetail: {
    picture: "",
    fullName: "",
  },
  renterStatus: ""
};

export const myRenterSlice = createSlice({
  name: "myRenters",
  initialState: initialState,
  reducers: {
    setTerminateTenancyRenterDetail: (state, action) => {
      let data = { ...state };
      data = {
        ...data,
        terminateTenancyRenterDetail: action.payload,
      };
      return data;
    },
    setTheStatusOfPastRenter: (state, action) => {
      let data = { ...state }
      data = {
        ...data,
        renterStatus: action.payload
      }
      return data
    }
  },
});
