import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const GetApplicationListService = () => {
  return axiosInstance
    .get(API_Endpoints.application_recived.GET_APPLICATION_LIST)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const ApplicationRequestStatusChangedService = (values) => {
  return axiosInstance
    .post(API_Endpoints.application_recived.STATUS_CHANGED_REQ, values)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const ApplicationDetail = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.application_recived.GET_APPLICATION_LIST}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const ApplicationListAsperTheProperty = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
