import axiosInstance from "../../utils/axios";
import { API_Endpoints } from "../API_Endpoints";

export const addNewsLetter = (body) => {
  return axiosInstance
    .post(API_Endpoints.manageNewsLetter.addNewsLetterSubscriptions, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
