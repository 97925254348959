import {
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/theme";
import {
  DownloadIcon,
  SearchIcon,
  eyeIcon,
  eyeViewIcon,
} from "../../../assets/icons";
import TransactionhistoryTable from "../../../components/transactionhistory";
import { useNavigate } from "react-router-dom";
import { GetTransactionListData } from "../../../services/transactionService";
import Loader from "../../../components/loader";
import { fDateTime } from "../../../utils/format-time";
import { creditDebitValue, ROLES } from "../../../utils/CONSTANTS";
import { API_Endpoints } from "../../../services/API_Endpoints";
import useDebounceSearch from "../../../services/globalSearchService";
import { formatCurrency } from "../../../utils/helperFunctionForValidte";
import { RetrieveLocalUser } from "../../../utils/authService";

export default function TransactionHistory() {
  const navigate = useNavigate();
  const user = RetrieveLocalUser("user");
  const [allStatusSearchState, setAllStatusSearchState] = useState({
    search: "",
  });
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableHeader = [
    { title: "Date & Time", key: "date" },
    { title: "Description", key: "description" },
    { title: "Payment Mode", key: "debit_credit" },
    { title: "Amount", key: "amount" },
    { title: "Action", key: "action" },
  ];

  //GET THE DATA OF TRANSACTION FROM THE BACKEND CALL THE TRANSACTION SERVICE
  const getTheTransaction = async () => {
    try {
      setLoading(true);
      const getData = await GetTransactionListData(
        `${API_Endpoints.transaction.getTransactionist}?search=${allStatusSearchState.search}`
      );
      console.log("--get", getData);
      if (getData?.status) {
        const data = getData?.data?.map((item) => {
          return {
            date: fDateTime(item?.createdAt),
            transactionId: "ORD-1562792778679",
            description:
              user?.role === ROLES.renter
                ? `Transfer to ${item?.landlord || item?.property_manager_name}`
                : `Recevied from the ${item?.renter}`,
            debit_credit:
              item?.type === creditDebitValue.credit ||
              [ROLES.landlord, ROLES.propertyManager].includes(user?.role)
                ? "Credit"
                : "Debit",
            status: "Success",
            amount: `${formatCurrency(item?.amount)}`,
            type: item?.type,
            action: (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/transaction-history-Detail/${item._id}`)
                  }
                >
                  {eyeViewIcon()}
                </span>
                {DownloadIcon()}
              </>
            ),
          };
        });

        setTransactionData(data);
      }
      setLoading(false);
    } catch (error) {
      console.log("exception error", error);
    }
  };

  // call the debounce hook
  useDebounceSearch(allStatusSearchState.search, getTheTransaction, 1000);

  return (
    <Container maxWidth="xl" className="mt-3">
      <div className="py-4">
        <Box>
          <Typography
            fontSize={40}
            className="phone-heading-30"
            fontWeight={700}
            color={theme.palette.text.primary}
          >
            Transaction History
          </Typography>
        </Box>

        <Box className="mb-4 mt-4">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                type="search"
                variant="outlined"
                className="w-100"
                placeholder="search"
                onChange={(e) =>
                  setAllStatusSearchState((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className="table-section">
          {loading ? (
            <Loader />
          ) : transactionData?.length === 0 ? (
            <h2 className="property_not_found w-100">No Transaction Found</h2>
          ) : (
            <TransactionhistoryTable
              tableHeader={tableHeader}
              tableRow={transactionData}
            />
          )}
        </Box>
      </div>
    </Container>
  );
}
