import {
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonComponent from "../components/Button";
import { useNavigate } from "react-router-dom";
import { LogoutPopupIcon } from "../assets/svg";
import { RemoveLocalUser } from "../utils/authService";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LogoutDialog = ({ open, setOpen ,setSidebarToggleState }) => {
  const navigate = useNavigate();
  const handleOnLogout = () => {
    localStorage.removeItem("user", "null");
    RemoveLocalUser()
    setOpen(false);
    navigate("/login");
    setSidebarToggleState(false)
  };
  return (
    <Dialog
      open={open}
      className="modal-section-area"
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-paper": {
          padding: "50px", 
        },
      }}
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="text-center">
        <LogoutPopupIcon />
      </div>
      <DialogTitle>
        <Typography
          textAlign={"center"}
          fontSize={40}
          fontWeight={"bold"}
          color="text.primary"
        >
          Are you sure you want <br />
          to logout?
        </Typography>
      </DialogTitle>

      <DialogActions className="d-flex justify-content-center align-items-center gap-3 logout-btns">
        <ButtonComponent
          sx={{
            width: 185,
            height: 64,
            background: "white",
            border: "1px solid #C8D736",
            borderRadius: 2,
          }}
          onClick={() => setOpen(false)}
          title={"No"}
        />
        <ButtonComponent
          sx={{ width: 185, height: 64, borderRadius: 2 }}
          onClick={handleOnLogout}
          title="Yes"
        />
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
