import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { creditDebitValue } from "../utils/CONSTANTS";
export default function TransactionhistoryTable({ tableHeader, tableRow }) {
  return (
    <div sx={{ width: "100%", overflowX: "auto" }}>
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <TableContainer elevation={0} component={Paper}>
            <Table
              sx={{ width: "100%", overflowX: "auto" }}
              size="small"
              style={{ marginBottom: "58px" }}
              aria-label="a dense table"
            >
              <TableHead
                sx={{ border: "1px solid #B9B9B9", borderRadius: "7px" }}
              >
                <TableRow>
                  {tableHeader &&
                    tableHeader?.map((item) => (
                      <TableCell
                        sx={{
                          border: "1px solid #B9B9B9",
                          color: "#07262E",
                          padding: "32px 24px",
                          fontWeight: 700,
                          fontSize: "18px",
                          backgroundColor: "#d8e0e185",
                        }}
                      >
                        {item.title}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody className="mb-4">
                {tableRow &&
                  tableRow.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {tableHeader.map((header, headerIndex) => {
                        return (
                          <TableCell
                            className="transactionHistory"
                            key={headerIndex}
                          >
                            {typeof row[header.key] === "function"
                              ? row[header.key]()
                              : header.title === "Description" &&
                                row.type === creditDebitValue?.credit
                              ? "Money credit to wallet"
                              : row[header.key]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
}
