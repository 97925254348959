import axiosInstance from "../utils/axios";
import { API_Endpoints } from "./API_Endpoints";

export const GetNotificationList = () => {
  return axiosInstance
    .get(API_Endpoints.notifications.getNotification)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};