import { citiesDataOfNigeria } from "./citiesJson";

export const ROLES = {
  propertyManager: "Property Manager",
  landlord: "Landlord",
  renter: "Renter",
};

// added the constant of the payment method
export const PAYMENT_METHOD = {
  flutterwave: "FlutterWave",
  stripe: "Stripe",
  payStack: "Paystack",
  wallet: "Rentranzact Wallet",
};

// permisson values enu as per the backend
export const permissions = {
  USER_MANGEMENT: "userManagement",
  MANAGE_EMPLOYEES: "manageEmployees",
  MANAGE_PROPERTY: "manageProperty",
  MANAGE_ROLES: "manageRoles",
  MANAGE_REVIEW: "manageReview",
  FINANCE_PERFORMANCE: "financePerformance",
  MANAGE_TRANSACTION: "manageTransaction",
  ACTIVITY_LOGS: "activityLogs",
};

// inspectoin enum for the renter
export const InspectionStatus = {
  CANCELED: "canceled",
  COMPLETED: "completed",
  INITIATED: "initiated",
  UPCOMING: "accepted",
};

// maintenance request concern field array
export const maintenanceConcerns = [
  "Leaky faucet in the kitchen",
  "Garden fence needs repair",
  "AC unit not cooling properly",
  "Cracked tiles in the bathroom",
  "Roof tiles need replacement",
  "Clogged drain in the basement",
  "Paint chipping on exterior walls",
  "Squeaky door hinges",
  "Broken window pane in the living room",
  "Faulty electrical outlet in the bedroom",
  "Water heater not heating water",
  "Mold growth in the attic",
  "Flickering lights in the hallway",
  "Leaking pipes under the sink",
  "Pest infestation in the garage",
  "Worn-out carpet in the dining room",
  "Dripping showerhead in the master bathroom",
  "Fence gate stuck and not opening",
  "Air conditioner filter needs replacement",
  "Loose floorboard in the hallway",
  "Siding damage due to recent storm",
  "Plumbing",
  "Electricals",
  "Structural",
];

//for role renter content enum for the submit mainteance request modal
export const contentMaintenance = {
  sendRequest: "Send New Request",
  maintenanceRequest: "Maintenance Request",
};

// creidt and debit value for the transaction tables
export const creditDebitValue = {
  credit: "CREDIT",
  debit: "DEBIT",
};

// dropdown values for the search of home page of the renter
export const budgetDropDownArray = [
  "0-50000",
  "50000-100000",
  "100000-200000",
  "200000-300000",
  "300000-400000",
  "400000-500000",
  "500000-600000",
  "600000-700000",
  "700000-800000",
  "800000-900000",
  "900000-1000000",
];

// manage calender slot array landlord
export const SlotListManageCalender = {
  morningSlot: [
    { id: "morning-1", time: "09:00 AM", checked: false, _id: "" },
    { id: "morning-2", time: "10:00 AM", checked: false, _id: "" },
    { id: "morning-3", time: "11:00 AM", checked: false, _id: "" },
  ],
  afterNoonSlot: [
    { id: "afternoon-1", time: "12:00 PM", checked: false, _id: "" },
    { id: "afternoon-2", time: "01:00 PM", checked: false, _id: "" },
    { id: "afternoon-3", time: "02:00 PM", checked: false, _id: "" },
    { id: "afternoon-4", time: "03:00 PM", checked: false, _id: "" },
  ],
  eveningSlot: [
    { id: "evening-1", time: "04:00 PM", checked: false, _id: "" },
    { id: "evening-2", time: "05:00 PM", checked: false, _id: "" },
    { id: "evening-3", time: "06:00 PM", checked: false, _id: "" },
    { id: "evening-4", time: "07:00 PM", checked: false, _id: "" },
  ],
};

// rent application enum for the verfication
export const IdentificationType = Object.freeze({
  BVN: "BVN",
  NIN_V2: "NIN_V2",
  VOTER_ID: "VOTER_ID",
});

// review question array
export const reviewQuestionArr = [
  {
    id: "1",
    value: 0,
    question: "How was the property infrastructure and environment?",
    mcqOption: [
      {
        label: "Best",
        value: 4,
      },
      {
        label: "Very Good",
        value: 3,
      },
      {
        label: "Good",
        value: 2,
      },
      {
        label: "Bad",
        value: 1,
      },
    ],
  },
  {
    id: "2",
    value: 0,
    question: "How was the property infrastructure and environment?",
    mcqOption: [
      {
        label: "Best",
        value: 4,
      },
      {
        label: "Very Good",
        value: 3,
      },
      {
        label: "Good",
        value: 2,
      },
      {
        label: "Bad",
        value: 1,
      },
    ],
  },
  {
    id: "3",
    value: 0,
    question: "How was the property infrastructure and environment?",
    mcqOption: [
      {
        label: "Best",
        value: 4,
      },
      {
        label: "Very Good",
        value: 3,
      },
      {
        label: "Good",
        value: 2,
      },
      {
        label: "Bad",
        value: 1,
      },
    ],
  },
  {
    id: "4",
    value: 0,
    question: "How was the property infrastructure and environment?",
    mcqOption: [
      {
        label: "Best",
        value: 4,
      },
      {
        label: "Very Good",
        value: 3,
      },
      {
        label: "Good",
        value: 2,
      },
      {
        label: "Bad",
        value: 1,
      },
    ],
  },
];

// global accepted and pending enum
export const globalAcceptPendingEnum = {
  accepted: "accepted",
  pending: "pending",
  reject: "rejected",
};

// property type object
export const propertyTypeCategory = {
  shortStay: "short stay",
};

// city data for the nigeira
export const citiesData = [
  "Aba South",
  "Arochukwu",
  "Bende",
  "Ikwuano",
  "Isiala Ngwa North",
  "Isiala Ngwa South",
  "Isuikwuato",
  "Obi Ngwa",
  "Ohafia",
  "Osisioma",
  "Ugwunagbo",
  "Ukwa East",
  "Ukwa West",
  "Umuahia North",
  "Umuahia South",
  "Umu Nneochi",
];

// maintaince obj for filter
export const mantainanceFilter = {
  pending: "pending",
  resolved: "resolved",
  remarked: "remarked",
};

// MARTIAL STATUS ARRAy
export const martialStatusArr = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
];

// kin identity type
export const kinIdentityType = [
  { label: "Voter Id", value: IdentificationType.VOTER_ID },
  {
    label: "Bank Verification Number (BVN)",
    value: IdentificationType.BVN,
  },
  {
    label: "National Identification Number (NIN)",
    value: IdentificationType.NIN_V2,
  },
];

// EMPLOYEE DETAIL status
export const employementStatus = [
  { label: "Employed", value: "employed" },
  { label: "Unemployed", value: "unemployed" },
  { label: "Self employed", value: "self_employed" },
];

// property type array
export const PROPERTY_CATEGORY_ARRAY = [
  { label: "Residential", value: "residential" },
  { label: "Short-Stays", value: "short stay" },
  { label: "Commercial", value: "commercial" },
];

//object for the property category
export const PROPERTY_CATEGORY = {
  resisdential: "residential",
  shortStay: "short stay",
  commerical: "commercial",
};

// avaliablity array
export const AVALIABLITY_ARRAY = [
  { label: "Immediate", value: "0" },
  { label: "< 15 Days", value: 15 },
  { label: "< 30 Days", value: 30 },
  { label: "> 30 Days", value: 30 },
];

// age of construction array
export const AGE_OF_CONSTRUCTION = [
  {
    label: "Newly Build",
    value: "Newly Build",
  },
  {
    label: "Fairly Used",
    value: "Fairly Used",
  },
  {
    label: "Renovated",
    value: "Renovated",
  },
  {
    label: "Old",
    value: "Old",
  },
];

export const PRPOPERTY_TYPE = [
  {
    label: "Apartment",
    value: "Apartment",
    propertyCategory: "residential",
  },
  {
    label: "Duplex",
    value: "Duplex",
    propertyCategory: "residential",
  },
  {
    label: "Mini Flat",
    value: "Mini Flat",
    propertyCategory: "residential",
  },
  {
    label: "Chalet",
    value: "Chalet",
    propertyCategory: "residential",
  },
  {
    label: "Bungalow",
    value: "Bungalow",
    propertyCategory: "residential",
  },
  {
    label: "Detached Bungalow",
    value: "Detached Bungalow",
    propertyCategory: "residential",
  },
  {
    label: "Semi Detached Bungalow ",
    value: "Semi Detached Bungalow ",
    propertyCategory: "residential",
  },
  {
    label: "Maisonette",
    value: "Maisonette",
    propertyCategory: "residential",
  },
  {
    label: "Penthouse",
    value: "Penthouse",
    propertyCategory: "residential",
  },
  {
    label: "Shared Apartment",
    value: "Shared Apartment",
    propertyCategory: "residential",
  },
  {
    label: "Studio Apartment",
    value: "Studio Apartment",
    propertyCategory: "residential",
  },
  {
    label: "Terrace",
    value: "Terrace",
    propertyCategory: "residential",
  },
  {
    label: "Shop",
    value: "Shop",
    propertyCategory: "commercial",
  },
  {
    label: "Warehouse",
    value: "Warehouse",
    propertyCategory: "commercial",
  },
  {
    label: "Open Space",
    value: "Open Space",
    propertyCategory: "commercial",
  },
  {
    label: "Open Floor",
    value: "Open Floor",
    propertyCategory: "commercial",
  },
  {
    label: "Office Space",
    value: "Office Space",
    propertyCategory: "commercial",
  },
];

// furnishing type array
export const FURNISHING_DATA = [
  { label: "No Furnishing", value: "No Furnishing" },
  { label: "Semi Furnished", value: "Semi Furnished" },
  { label: "Furnished", value: "Furnished" },
];

// community dropdown data arrray
export const COMMUNITY_DROPDOWN_DATA = [
  {
    label: "Gated Community (Non estate)",
    value: "Gated Community (Non estate)",
  },
  { label: "Open Community", value: "Open Community" },
  { label: "Estate", value: "Estate" },
];

// community dropdown data arrray
export const SERVICING_DROPDOWN_DATA = [
  {
    label: "Serviced",
    value: "Serviced",
  },
  { label: "Non Serviced/Fully Serviced", value: "Non Serviced/Fully Serviced" },
];

// gender data array
export const GENDER_DATA = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

// martial status
export const MARTIAL_STATUS = [
  { label: "Single", value: "single" },
  { label: "Married", value: "married" },
  { label: "Divorced", value: "divorced" },
];

/// rent application business field dropdown array
export const BUSINESS_NAME_DATA = [
  { label: "Services", value: "Services" },
  { label: "Goods and Trading", value: "Goods and Trading" },
  { label: "Manufacturing", value: "Manufacturing" },
];

// rent and service type dropdown data
export const RENT_SERVICE_DATA = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quaterly" },
  { label: "Yearly", value: "yearly" },
];

// function for extract the cites of the nigeria
export const extractCites = () => {
  return citiesDataOfNigeria.flatMap((item)=>item?.cities)
};


//faq categories values 
export const faqCategories = {
  general:"general",
  service:"service",
  payment:"payment",
  rent:"rent",
  property:"property",
  refund:"refund"
}