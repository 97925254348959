import {
  AppBar,
  Avatar,
  Box,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import {
  AttachmentIcon,
  DownloadFromAppleStore,
  DownloadFromGooglePlay,
  PlainIcon,
} from "../../../assets/svg";
import { useLocation } from "react-router-dom";
import useSocket from "../../../hooks/useSocket";
import { RetrieveLocalUser } from "../../../utils/authService";
import SectionLoader from "../../../components/SectionLoader";
import { getFormattedTime } from "../../../utils/methods";
import InfiniteScroll from "react-infinite-scroll-component";

const Chat = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const userData = RetrieveLocalUser("user");
  const {
    isConnected,
    messages,
    sendMessage,
    chatList,
    joinChatHandler,
    selectedChat,
    setSelectedChat,
    isShowDownloadApp,
    setCurrentRoom,
    chatListLoader,
    messageListLoader,
    getAllChats,
    typingStatus,
    onChatSearchHandler,
    fetchMoreData,
  } = useSocket();

  useEffect(() => {
    if (location.state) {
      joinChatHandler(location.state);
    } else {
      getAllChats();
    }
  }, [location.state]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && message.trim()) {
      sendMessage(message);
      setMessage("");
    }
  };

  return (
    <Grid container mb={3} justifyContent={"unset"} className="mb-5">
      {/* Sidebar */}
      <Grid item md={4} xs={12}>
        <Box
          className="chatsidebar"
          sx={{
            height: "auto",
            width: "100%",
            background: "#F4F4F4",
            borderRadius: 2,
            overflowY: "auto",
          }}
          p={0}
        >
          <Typography
            fontSize={40}
            fontWeight={"bold"}
            color="text.primary"
            p={3}
          >
            Chats
          </Typography>

          <div className="text-center">
            <OutlinedInput
              onChange={onChatSearchHandler}
              className="mt-0"
              placeholder="Search"
              fullWidth
              sx={{ background: "white", maxWidth: "90%" }}
              startAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          </div>

          <List>
            {chatListLoader ? (
              <SectionLoader />
            ) : (
              chatList.length > 0 &&
              chatList.map((item, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Typography
                      mb={3}
                      fontSize={14}
                      color="#596E73"
                      fontWeight={300}
                    >
                      {item.user_details.updatedAt}
                    </Typography>
                  }
                  sx={{
                    background:
                      selectedChat === item.user_details ? "#fff" : "",
                    my: 2,
                    py: 2,
                    gap: 2,
                    cursor: "pointer",
                    ml: 0,
                  }}
                  onClick={() => {
                    setSelectedChat(item.user_details);
                    setCurrentRoom(item._id);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ height: 64, width: 64 }}>
                      <img
                        src={item.user_details.picture}
                        alt={item.user_details.fullName}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.user_details.fullName}
                    secondary={item.last_message}
                    secondaryTypographyProps={{
                      fontSize: 16,
                      color: "#596E73",
                      fontWeight: 300,
                    }}
                    primaryTypographyProps={{
                      fontSize: 18,
                      fontWeight: "bold",
                      color: "text.primary",
                    }}
                  />
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Grid>

      {/* Chat Area */}
      <Grid item md={8} xs={12} className="chat-space">
        {isShowDownloadApp ? (
          <div className="d-flex justify-content-center align-items-center flex-column h-100 chat-section">
            <img
              src={require("../../../assets/images/mobileScreen.png")}
              alt="Mobile App"
            />
            <Typography
              className="mt-3"
              fontSize={24}
              fontWeight={500}
              color="text.primary"
            >
              Get the App
            </Typography>
            <div className="d-flex justify-content-center align-items-center mt-3 gap-3">
              <DownloadFromAppleStore />
              <DownloadFromGooglePlay />
            </div>
          </div>
        ) : (
          <>
            <AppBar
              component="nav"
              elevation={0}
              position="relative"
              sx={{ background: "#F4F4F4", p: 2 }}
            >
              <Toolbar>
                <div className="d-flex gap-3 align-items-center">
                  <Avatar
                    sx={{ height: 64, width: 64 }}
                    src={selectedChat?.picture}
                  />
                  <Typography fontSize={24} fontWeight={"bold"}>
                    {selectedChat?.fullName}
                  </Typography>
                </div>
              </Toolbar>
            </AppBar>

            <div className="main-content px-4 chat-section">
              <div
                className="messages-container"
                id="scrollableDiv"
                style={{
                  height: "calc(100vh - 150px)",
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                {messageListLoader ? (
                  <SectionLoader />
                ) : (
                  <div>
                    <InfiniteScroll
                      dataLength={messages.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            textAlign: "center",
                            padding: "10px",
                            width: "100%",
                            background: "#fff",
                            zIndex: 1,
                          }}
                        >
                          Loading...
                        </div>
                      }
                      scrollableTarget="scrollableDiv"
                      inverse={true}
                    >
                      {messages.map((x, i) => (
                        <div
                          key={i}
                          className={`chat-message ${
                            x.sender_id === userData._id ? "me" : ""
                          }`}
                        >
                          <div className="message-content d-flex">
                            <Typography
                              fontSize={16}
                              fontWeight={400}
                              color="text.secondary"
                            >
                              {x.content}
                            </Typography>
                            <span>{getFormattedTime(x.updatedAt)}</span>
                          </div>
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </div>

              <div className="typing-indicators">
                {Object.keys(typingStatus).map(
                  (userId) =>
                    typingStatus[userId] && (
                      <p key={userId}>{userId} is typing...</p>
                    )
                )}
              </div>
              <div className="input-container">
                <TextField
                  value={message}
                  onKeyUp={handleKeyPress}
                  onChange={(e) => setMessage(e.target.value)}
                  InputProps={{
                    sx: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                    },
                    endAdornment: (
                      <InputAdornment>
                        <AttachmentIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ backgroundColor: "#F6F6F6", height: 56, border: 0 }}
                  fullWidth
                  type="text"
                  placeholder="Type Something..."
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#009DA6",
                    height: 56,
                    width: 56,
                    borderTopRightRadius: 3,
                    borderBottomRightRadius: 3,
                  }}
                  onClick={() => {
                    if (message.trim()) {
                      sendMessage(message);
                      setMessage("");
                    }
                  }}
                >
                  <Icon
                    icon="mdi:send"
                    sx={{ color: "white", width: 24, height: 24 }}
                  />
                </Box>
              </div>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Chat;
