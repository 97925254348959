import {lazy} from 'react'

export const LoginPage = lazy(() => import("../admin-pages/login"));
export const ProductsPage = lazy(() => import("../admin-pages/products"));
export const Page404 = lazy(() => import("../admin-pages/page-not-found"));
export const AdminDashboard = lazy(() => import("../admin-pages/app"));
export const ManageEmployees = lazy(() =>
  import("../admin-pages/manage-employees/index")
);

export const CreateNotification = lazy(() =>
  import("../admin-pages/notifications/create-notifications")
);
export const AddEmployees = lazy(() =>
  import("../admin-pages/manage-employees/add-employee")
);
export const Notifications = lazy(() => import("../admin-pages/notifications"));
export const ManageFaq = lazy(() => import("../admin-pages/manage-faq"));
export const AddFAQ = lazy(() => import("../admin-pages/manage-faq/add-faq"));
export const ManageUsers = lazy(() => import("../admin-pages/manageUsers/index"));
export const AddUsers = lazy(() => import("../admin-pages/manageUsers/add-user"));
export const ManagePrivacyPolicy = lazy(() =>
  import("../admin-pages/manage-privacy-policy")
);
export const UserDetails = lazy(() =>
  import("../admin-pages/manageUsers/user-details")
);
export const AdminPropertyManagement = lazy(() =>
  import("../admin-pages/property-management/index")
);
export const Profile = lazy(() => import("../admin-pages/profile"));
export const Employee = lazy(() =>
  import("../admin-pages/manage-employees/view-employee")
);

export const ManageBlogs = lazy(() => import("../admin-pages/manage-blogs"));
export const Newsletter = lazy(() => import("../admin-pages/newsletter"));
export const AddBlogs = lazy(() => import("../admin-pages/manage-blogs/add-blog"));
export const AdminBlogDetail = lazy(() =>
  import("../admin-pages/manage-blogs/blog-detail")
);

export const ManageAboutUs = lazy(() =>
  import("../admin-pages/manage-aboutUs/index")
);
export const ManageCareers = lazy(() => import("../admin-pages/manage-careers"));
export const AddCareers = lazy(() =>
  import("../admin-pages/manage-careers/add-careers")
);

export const CreateTestimonials = lazy(() =>
  import("../admin-pages/create-testimonials/add-testimonials")
);
export const AdminTestimonials = lazy(() =>
  import("../admin-pages/create-testimonials/index")
);

export const ManageSocialMediaLinks = lazy(() =>
  import("../admin-pages/manage-social-media-links")
);
export const ManageBanners = lazy(() => import("../admin-pages/manage-banners"));
export const AddBanners = lazy(() =>
  import("../admin-pages/manage-banners/add-banners")
);
export const AddSocialMediaLinks = lazy(() =>
  import("../admin-pages/manage-social-media-links/add-social-media-links")
);

export const AdminDataProtection = lazy(() => import("../admin-pages/data-protection"));
export const ManageAggrements = lazy(() =>
  import("../admin-pages/manage-aggrements")
);
export const AddAggrements = lazy(() =>
  import("../admin-pages/manage-aggrements/add-aggrements")
);

export const AddProperty = lazy(() =>
  import("../admin-pages/property-management/add-property")
);
export const ManageAggrementsAndDocuments = lazy(() =>
  import("../admin-pages/property-management/manage-aggrements&documents")
);
export const AdminPropertiesInDemand = lazy(() =>
  import("../admin-pages/properties-in-demand")
);

export const ViewOnboardedProperty = lazy(() =>
  import("../admin-pages/property-management/ViewProperty")
);

export const PropertyRequestDetail = lazy(() =>
  import("../admin-pages/property-management/PropertyDetails")
);

export const LeaseAggrements = lazy(() =>
  import("../admin-pages/property-management/lease-aggrements")
);

export const UpdateProperty = lazy(() =>
  import("../admin-pages/property-management/updateProperty")
);

export const Landlords = lazy(() => import("../admin-pages/manageUsers/landlords"));
export const Renters = lazy(() => import("../admin-pages/manageUsers/renters"));
export const PropertyManagers = lazy(() =>
  import("../admin-pages/manageUsers/property-managers")
);

export const ManageRolesAndPermission = lazy(() =>import(`../admin-pages/rolesandPermissionManagement/index`))

export const AddNewRole = lazy(() =>import(`../admin-pages/rolesandPermissionManagement/add-new-role`))
export const FinancePerformance = lazy(() =>import(`../admin-pages/financePerformance/FinancePerformance`))
export const ManageChat = lazy(() =>import(`../admin-pages/manageChat/manageChat`))
export const ManageTransactions = lazy(() =>import(`../admin-pages/manageTransactions/manageTransaction`))
export const ActivityLog = lazy(() =>import(`../admin-pages/activityLog/activitylog`))
export const ChatPerAsUser = lazy(() =>import(`../admin-pages/manageChat/chatAsPerUser`))

export const NotificationDetail = lazy(() =>import(`../admin-pages/notifications/detail`))
export const ReviewAndRatingMgt =lazy(() => import(`../admin-pages/Review&RatingMgt/Review&RatingMgt`))
const AdminRoutes = () => {
  return (
    <div>AdminRoutes</div>
  )
}

export default AdminRoutes