export const paymentGateWayKey = {
  stripe:
    "pk_test_51MjOBYIqUp9zeP6ExFRYCnHmFuONlU35DksTZA2wLnlk1HU7v605s6kinjtuSFwey6tdrVSJmYSeUIR3Hn5sRI1K00LavAk84O",
};


   ////Your web app's Firebase configuration
//For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC51sXYMiTLkeSCl8-b8r4Tom8eDi8UAW0",
  authDomain: "rentranzactt.firebaseapp.com",
  projectId: "rentranzactt",
  storageBucket: "rentranzactt.appspot.com",
  messagingSenderId: "156878679064",
  appId: "1:156878679064:web:57311b1baaacde86df276d",
  measurementId: "G-4Y3J8C8TXL",
};

export const firebaseValidKey = "BK8KcTFFavxysiea7Pl-7paWyyy6MUNtOhhkeFggWUq0fHj49usmig6MmfOzd5vrU6EZyEJ09gm2OJD0aW4Kiak"