import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectBoxUpIcon, SelectedBoxDownIcon } from "../assets/svg";
import { useField } from "formik";

const CustomSelectBox = ({
  label,
  placeholder,
  sx,
  menu,
  disabled,
  value,
  onChange,
  name,
}) => {
  const [open, setOpen] = useState(false);
  const [field, meta, helpers] = useField(name);

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...field}
        id={name}
        disabled={disabled}
        onChange={handleChange}
        value={value}
        onBlur={() => helpers.setTouched(true)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        placeholder={placeholder}
        IconComponent={open ? SelectedBoxDownIcon : SelectBoxUpIcon}
        sx={{
          borderRadius: 2,
          ...sx,
          outline: "none",
          "& .MuiSvgIcon-root": {
            fontSize: 12,
            left: "auto",
          },
        }}
        labelId={`${name}-label`}
        label={label}
      >
        {menu.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <div style={{ color: "#d32f2f", fontSize: "0.75rem", marginTop: 4 }}>
          {meta.error}
        </div>
      )}
    </FormControl>
  );
};

export default CustomSelectBox;
