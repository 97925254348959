import { API_Endpoints } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const GetTransactionListData = (url) => {
  return axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((err) => err);
};

export const GetTransActionDetailByID = (id) => {
  return axiosInstance
    .get(`${API_Endpoints.transaction.getTransactionDetail}/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};


export const GETRentTransactionList = ()=>{
  return axiosInstance
    .get(API_Endpoints.transaction.rentTransaction)
    .then((res) => res.data)
    .catch((err) => err);
}