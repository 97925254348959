import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { LocationIcon, TelePhone } from "../../../assets/svg";
import ButtonComponent from "../../../components/Button";
import MarkResolvedModal from "./markResolvedModal";

import { useNavigate } from "react-router-dom";
import AddRemarkModal from "./addRemarkModal";
import { markResolvedMaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import {
  changeNumeicFormatDateToStandardDateFromatForSingle,
  fDate,
} from "../../../utils/format-time";
import { mantainanceFilter } from "../../../utils/CONSTANTS";
import { useDispatch } from "react-redux";
import { maintainceRequestSlice } from "../../../redux/maintainceRequest/maintainceRequestSlice";

export default function RequestCard({ cardData }) {
  const [modal, setModal] = useState(false);
  const [markModal, setMarkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // call the api for mark resolved as per the backend
  const markAsResolvedApiCall = async (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      const markResolved = await markResolvedMaintenanceRequest(cardData?._id);
      setLoading(false);
      if (markResolved?.status) {
        setMarkModal(true);
      } else {
        console.log("markResolved", markModal);
      }
    } catch (error) {
      console.log("err");
    }
    dispatch(maintainceRequestSlice.actions.setTheFilterValue());
  };
  return (
    <Grid item md={4}>
      <Card
        elevation={0}
        className="p-4"
        sx={{ borderRadius: 3, boxShadow: "5px 5px 19px 4px #0000000d" }}
      >
        <Grid container>
          <Grid
            style={{ cursor: "pointer" }}
            item
            md={3}
            textAlign={"left"}
            onClick={() => navigate("/maintenance-request-detail")}
          >
            <img
              style={{ height: "100px", width: "100px", borderRadius: "50%" }}
              src={cardData?.renterDetails[0]?.picture}
            />
          </Grid>
          <Grid item md={9} paddingLeft={"15px"}>
            <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
              {cardData?.renterDetails[0]?.fullName}
            </Typography>
            <Typography
              className="mt-3 mb-1"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{TelePhone()}</span>
              {cardData?.renterDetails[0]?.phone}
            </Typography>
            <Typography
              className="mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{LocationIcon()}</span> 6th
              Floor Tower A
            </Typography>
          </Grid>
          <Box>
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              {cardData?.concern}
            </Typography>
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              {cardData?.renterRemark}
            </Typography>
          </Box>
          {cardData?.status === mantainanceFilter?.pending ? (
            <>
              {" "}
              <div className="d-flex justify-content-between w-100 mt-4 mb-1">
                <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                  Status
                </Typography>
                <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                  Requested on
                </Typography>
              </div>
              <div className="d-flex justify-content-between w-100">
                <Typography color={"#ED1717"} fontWeight={600} fontSize={16}>
                  OPEN
                </Typography>
                <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                  {fDate(cardData?.createdAt)}
                </Typography>
              </div>
              <div className="d-flex justify-content-between w-100 mt-4">
                <ButtonComponent
                  title={"Add Remark"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal(true);
                  }}
                  sx={{
                    border: "1px solid #C8D736",
                    backgroundColor: "#fff",

                    marginBottom: "20px",
                  }}
                />
                <ButtonComponent
                  title={"Mark Resolved"}
                  sx={{
                    marginBottom: "20px",
                  }}
                  loading={loading}
                  disabled={loading}
                  onClick={(e) => {
                    markAsResolvedApiCall(e);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between w-100 mt-3">
                <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                  Status
                </Typography>
                <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                  Requested On
                </Typography>
                <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
                  Resolved On
                </Typography>
              </div>
              <div className="d-flex justify-content-between w-100 mt-2">
                <Typography color={"#C8D736"} fontWeight={600} fontSize={16}>
                  RESOLVED
                </Typography>
                <Typography
                  color={"#07262E"}
                  fontWeight={400}
                  fontSize={16}
                  marginRight={"38px"}
                >
                  {fDate(cardData?.createdAt)}
                </Typography>
                <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
                  {changeNumeicFormatDateToStandardDateFromatForSingle(
                    Number(cardData?.resolvedOn) / 1000
                  )}
                </Typography>
              </div>
            </>
          )}
          <AddRemarkModal
            open={modal}
            setOpen={setModal}
            heading={"Add Remark"}
            heading2={"Remark Added"}
            detailData={cardData}
          />
          <MarkResolvedModal
            open={markModal}
            setOpen={setMarkModal}
            detailData={cardData}
          />
        </Grid>
      </Card>
    </Grid>
  );
}
