import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/theme";
import MarkResolvedModal from "./markResolvedModal";
import LandlordCommonModal from "../../../components/landlordCommonModal";
import renterImg from "../../../assets/images/reviewPic.png";
import { LocationIcon, TelePhone } from "../../../assets/svg";
import ButtonComponent from "../../../components/Button";

export default function MaintainceDeatil() {
  const [modal, setModal] = useState(false);
  const [markModal, setMarkModal] = useState(false);
  return (
    <Container className="mt-4">
      <Box marginTop={"23px"}>
        <Typography
          className="phone-heading-30"
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          marginBottom={"20px"}
        >
          Maintenance Requests
        </Typography>
      </Box>
      <Grid md={4} padding={"10px"}>
        <Grid container>
          <Grid item md={1} textAlign={"left"}>
            <img src={renterImg} />
          </Grid>
          <Grid item md={11} paddingLeft={"15px"}>
            <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
              Pery
            </Typography>
            <Typography
              className="mt-3 mb-1"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{TelePhone()}</span> +234
              706 892 9916
            </Typography>
            <Typography
              className="mb-3"
              color={"#8D9B9F"}
              fontWeight={400}
              fontSize={16}
            >
              <span style={{ marginRight: "10px" }}>{LocationIcon()}</span> 6th
              Floor Tower A
            </Typography>
          </Grid>
          <Box>
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              Request for 
            </Typography>
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              Request for tap repairing. Tap is leaking in washroom and water
              wastage is there.
            </Typography>
          </Box>

          <div className="d-flex justify-content-between w-100 mt-4 mb-1">
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              Status
            </Typography>
            <Typography color={"#009DA6"} fontWeight={400} fontSize={14}>
              Requested on
            </Typography>
          </div>
          <div className="d-flex justify-content-between w-100">
            <Typography color={"#ED1717"} fontWeight={600} fontSize={16}>
              OPEN
            </Typography>
            <Typography color={"#07262E"} fontWeight={400} fontSize={16}>
              15/01/2024
            </Typography>
          </div>
          <div className="d-flex justify-content-between w-100 mt-4">
            <ButtonComponent
              title={"Add Remark"}
              onClick={() => setModal(true)}
              sx={{
                border: "1px solid #C8D736",
                backgroundColor: "#fff",

                marginBottom: "20px",
              }}
            />
            <ButtonComponent
              title={"Mark Resolved"}
              sx={{
                marginBottom: "20px",
              }}
              onClick={() => setMarkModal(true)}
            />
          </div>
          <LandlordCommonModal
            open={modal}
            setOpen={setModal}
            heading={"Add Remark"}
            heading2={"Remark Added"}
          />
          <MarkResolvedModal open={markModal} setOpen={setMarkModal} />
        </Grid>
      </Grid>
    </Container>
  );
}
