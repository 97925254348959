import { Box, Card, Container, Grid, Typography } from "@mui/material";

import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";

import inspectionHouse from "../../assets/images/inspectionsHouse.png";
import { LocationIcon } from "../../assets/svg";
import { Link, useNavigate } from "react-router-dom";
import MarqueText from "../../components/marqueeText";
import { getTheDashboardData } from "../../services/dashboard.services";
import Loader from "../../components/loader";
import { replaceHttpImgUrl } from "../../utils/helperFunctionForValidte";
import { fDate } from "../../utils/format-time";
import { useDispatch } from "react-redux";
import { propertyListSlice } from "../../redux/propertyList/propertyListSlice";
import { FaAngleDoubleUp } from "react-icons/fa";

const count = [
  {
    title: `Listed Properties`,
    value: "0",
    link: "/listed-properties",
  },
  {
    title: "Rented Properties",
    value: "0",
    link: "/rented-properties",
  },
  {
    title: "Vacant Properties",
    value: "0",
    link: "/vacant-properties",
  },
  {
    title: "Maintenance Request",
    value: "0",
    link: "/maintenance-request",
  },
];

const transactions = [
  {
    title: "Sapphire Residence",
    subTitle: "Jimmy Barton",
    dateTime: "18 Dec 2023 ; 10:00 AM",
    amount: "₦ 10,000",
    type: "rent",
  },
  {
    title: "Laribonds Palace",
    subTitle: "Jimmy Barton",
    dateTime: "18 Dec 2023 ; 10:00 AM",
    amount: "₦ 9,300",
    type: "rent",
  },
  {
    title: "Sapphire Residence",
    subTitle: "Jimmy Barton",
    dateTime: "18 Dec 2023 ; 10:00 AM",
    amount: "₦ 1,000",
    type: "Maintenance",
  },
];

const LandlordLandingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dashBoardData, setDashBoardData] = useState({
    propertCount: count,
    newestInspectionRequest: [],
  });
  const backgroundColor = (index) => {
    if (index === 0) {
      return "#e6f5f6";
    } else if (index === 1) {
      return "#eaffd2";
    } else if (index === 2) {
      return "#eef3c1";
    } else {
      return "#b6cad2";
    }
  };

  const [barChart] = useState({
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45px", // You can adjust the width here (e.g., '50px', '20%', etc.)
        borderRadius: 10,
      },
    },
    colors: ["#C8D736"],
    grid: {
      show: false,
    },
    series: [
      {
        name: "",
        type: "column",
        data: [200, 170, 100, 190, 210],
      },
      {
        name: "Total Income from Property",
        type: "line",
        data: [200, 170, 100, 190, 210],
      },
    ],
    chart: {
      height: 250,

      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2],
      curve: "straight",
      colors: ["#009DA6"],
    },

    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: ["Aug", "Sept", "Oct", "Nov", "Dec"],
    xaxis: {
      type: "string",
      labels: {
        style: {
          fontSize: 18,
          color: "#8d9b9f",
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: true, // Ensure the axis line is enabled
      },

      type: "string",
      labels: {
        style: {
          fontSize: 18,
          color: "#8d9b9f",
          fontWeight: 500,
        },
        formatter: function (value) {
          return value + "K";
        },
      },
    },
  });

  // get the data of property count from the backend
  const getTheDashboardDataFromBackend = async () => {
    setLoading(true);
    const getData = await getTheDashboardData();
    if (getData?.status && getData?.data) {
      const newdata = dashBoardData?.propertCount?.map((item) => ({
        ...item,
        value:
          item?.title === "Listed Properties"
            ? getData?.data?.count?.total
            : item?.title === "Rented Properties"
            ? getData?.data?.count?.rented
            : item?.title === "Vacant Properties"
            ? getData?.data?.count?.vacant
            : getData?.data?.count.maintenance,
      }));
      setDashBoardData((prev) => ({
        ...prev,
        newestInspectionRequest: getData?.data?.newestInspectionRequest,
        propertCount: newdata,
      }));
    }
    setLoading(false);
  };

  // set the filter as per the lanlord selected list
  const filterSetProperty = (filterValue, link) => {
    if (filterValue === "Rented Properties") {
      dispatch(propertyListSlice.actions.setTheRentedProperty("Rent"));
    } else if (filterValue === "Vacant Properties") {
      dispatch(propertyListSlice.actions.setTheRentedProperty("Vacant"));
    } else {
      dispatch(propertyListSlice.actions.setTheRentedProperty(""));
    }
    navigate(link);
  };
  useEffect(() => {
    getTheDashboardDataFromBackend();
  }, []);
  console.log("dashboarddata", dashBoardData);
  return (
    <div className="bg-white">
      <MarqueText />
      <Container maxWidth="xl">
        <div className="py-4">
          <Typography
            component={"h2"}
            color={"text.primary"}
            fontWeight={500}
            fontSize={40}
          >
            Dashboard
          </Typography>

          <Grid container spacing={4} mt={1}>
            {dashBoardData?.propertCount?.map((x, i) => {
              let breakTitle = x.title.split(" ");
              console.log("ads", x);
              return (
                <Grid item md={3} xs={12} key={i} className="dashboard-cards">
                  <Card
                    onClick={() => filterSetProperty(x.title, x.link)}
                    elevation={0}
                    className="d-flex align-items-center gap-2 p-3"
                    sx={{
                      cursor: "pointer",
                      borderRadius: 4,

                      boxShadow: "5px 5px 19px 4px #0000000d",

                      backgroundColor: backgroundColor(i),
                    }}
                  >
                    <Box
                      sx={{
                        height: 66,
                        width: 66,
                        borderRadius: "50%",
                        background: "white",
                        border: `7px solid ${
                          i === 0
                            ? "#009DA6"
                            : i === 1
                            ? "#C8D736"
                            : i === 2
                            ? "#DAE478"
                            : "#618D9D"
                        } `,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 24,
                        fontWeight: "bold",
                      }}
                    >
                      {x.value}
                    </Box>
                    <Typography
                      fontSize={18}
                      color="text.secondary"
                      fontWeight={"bold"}
                    >
                      {breakTitle[0]}
                      <br />
                      {breakTitle[1]}
                    </Typography>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          <Grid container spacing={4} mt={2}>
            <Grid item md={6} xs={12}>
              <div className="py-3  recent-main">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography
                    fontSize={24}
                    className="recent-transactions"
                    fontWeight={"bold"}
                    color="text.secondary"
                  >
                    Recent transactions
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/rent-transactions-history")}
                    fontSize={16}
                    color="#C8D736"
                    fontWeight={"bold"}
                    textAlign={"center"}
                  >
                    <FaAngleDoubleUp />
                    <br />
                    <span className="d-block">View More</span>
                  </Typography>
                </div>

                {transactions.map((transaction, i) => {
                  return (
                    <Card
                      elevation={0}
                      key={i}
                      className="my-5 recent-cards"
                      sx={{
                        borderRadius: 3,
                        boxShadow: "5px 5px 19px 4px #0000000d",
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <div>
                          <Typography
                            color="#009DA6"
                            fontWeight={"bold"}
                            fontSize={18}
                          >
                            {transaction.title}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            fontWeight={500}
                            fontSize={16}
                          >
                            {transaction.subTitle}
                          </Typography>
                          <Typography
                            className="mt-2"
                            color="#B2BCBE"
                            fontSize={14}
                            fontWeight={100}
                          >
                            {transaction.dateTime}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            fontWeight={"bold"}
                            fontSize={18}
                            color="text.secondary"
                          >
                            {transaction.amount}
                          </Typography>{" "}
                          <Typography
                            fontSize={16}
                            color="#009DA6"
                            fontWeight={500}
                          >
                            {transaction.type}
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </Grid>
            <Grid item md={6}>
              <div className="py-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography
                    fontSize={24}
                    fontWeight={"bold"}
                    color="text.secondary"
                  >
                    Total Income
                  </Typography>
                  <div></div>
                </div>

                <ReactApexChart
                  height={400}
                  type="bar"
                  options={barChart}
                  series={barChart.series}
                />
              </div>
            </Grid>
          </Grid>
          <div className="mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <Typography
                fontSize={24}
                fontWeight={"bold"}
                color="text.secondary"
              >
                Inspection Requests
              </Typography>
              <Typography
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/inspection-requests")}
                fontSize={16}
                color="#C8D736"
                fontWeight={"bold"}
                textAlign={"center"}
              >
                <FaAngleDoubleUp />
                <br />
                <span className="d-block">View More</span>
              </Typography>
            </div>

            <Grid container spacing={4} mt={1}>
              {loading ? (
                <Loader />
              ) : dashBoardData?.newestInspectionRequest.length === 0 ? (
                <h3>No Inspection Request Found</h3>
              ) : (
                dashBoardData?.newestInspectionRequest?.map(
                  (inspection, index) => {
                    return (
                      <Grid key={index} item md={4} p={2}>
                        <Card
                          sx={{ p: 2, boxShadow: "5px 5px 19px 4px #0000000d" }}
                          elevation={0}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/inspection-requests-property/${inspection?.propertyID}`
                              )
                            }
                            className="d-flex align-items-center gap-2"
                          >
                            <img
                              style={{ height: 95, width: 117 }}
                              src={replaceHttpImgUrl(inspection.images[0]?.url)}
                            />
                            <div>
                              <Typography
                                fontSize={18}
                                fontWeight={"bold"}
                                color="text.primary"
                              >
                                {fDate(inspection.inspectionDate)}{" "}
                                {inspection?.inspectionTime}
                              </Typography>
                              <Typography
                                marginTop={1}
                                fontSize={16}
                                color={"text.primary"}
                              >
                                {inspection.propertyName}
                              </Typography>
                              <div className="d-flex gap-2 align-items-center">
                                <LocationIcon />
                                <Typography color="#8D9B9F" fontSize={16}>
                                  {inspection.location}
                                </Typography>
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <Typography
                                fontSize={16}
                                fontWeight={"bold"}
                                color="text.secondary"
                              >
                                {inspection.RenterDetails.fullName}
                              </Typography>
                              <Typography
                                fontSize={16}
                                fontWeight={500}
                                color="text.secondary"
                              >
                                {inspection.email}
                              </Typography>
                            </div>

                            <Link to="/chat">
                              <Typography
                                fontSize={18}
                                color="#C8D736"
                                fontWeight={"bold"}
                              >
                                Chat Now
                              </Typography>
                            </Link>
                          </div>
                        </Card>
                      </Grid>
                    );
                  }
                )
              )}
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LandlordLandingPage;
