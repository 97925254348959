import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import { options } from "./components/stripePopup/stripeCss";
import AppRoutes from "./routes";
import "react-notifications/lib/notifications.css";
import { loadStripe } from "@stripe/stripe-js";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authSlice } from "./redux/auth/authSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { firebaseConfig, firebaseValidKey } from "./utils/keys";
import { io } from "socket.io-client";
import { socketConnection } from "./utils/socketIo";
import { RetrieveLocalUser } from "./utils/authService";
import { useLocation } from "react-router-dom";
function App() {
  const dispatch = useDispatch();
  const user = RetrieveLocalUser("user");

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  // this is for the firebase notification if user is in the differnet tab
  useEffect(() => {
    // Request permission to send notifications
    const requestForToken = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: firebaseValidKey,
        });
        if (token) {
          dispatch(authSlice.actions.setTheFcmToken(token));
          console.log("FCM Token:", token);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      } catch (error) {
        console.error("An error occurred while retrieving token. ", error);
      }
    };
    requestForToken();
    // if (user != null) {
    //   socketConnection();
    // }
  }, []);
 

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        pauseOnFocusLoss={true}
      />
      <AppRoutes />
    </>
  );
}

export default App;
