import { Box, Card, Chip, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocationIcon } from "../assets/icons";
import { PropertyPropsObjEnum, roleType } from "../utils/appConstants";
import { RetrieveLocalUser } from "../utils/authService";
import {
  differenceInDays,
  replaceHttpImgUrl,
} from "../utils/helperFunctionForValidte";
import ButtonComponent from "./Button";
import LandlordCommonModal from "./landlordCommonModal";
import { useSelector } from "react-redux";
import { changeNumeicFormatDateToStandardDateFromatForSingle } from "../utils/format-time";
import { ROLES } from "../utils/CONSTANTS";

export default function PropertyDetailCard({ data, buttonShow }) {
  const navigate = useNavigate();
  const userData = RetrieveLocalUser("user");
  const { terminateTenancyRenterDetail, renterStatus } = useSelector(
    (state) => state.MYRENTERS
  );
  const [modalForTerminate, setModalForTerminate] = useState(false);
  console.log("-dfsdfata", terminateTenancyRenterDetail, modalForTerminate);
  return (
    <>
      <Card
        className="my-4 property-cards"
        elevation={0}
        sx={{ boxShadow: "5px 5px 19px 4px #0000000d", borderRadius: 2 }}
        onClick={() => navigate(`/property-detail/${data._id}`)}
      >
        <Grid
          container
          spacing={3}
          p={2}
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
        >
          <Grid item md={6} sm={12}>
            <Box className="properrty-img " sx={{ width: "100%", height: 258 }}>
              {data?.images === undefined ? null : (
                <img
                  src={replaceHttpImgUrl(data?.images[0]?.url)}
                  alt="Property"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              )}

              <Chip
                label={data.rented ? "Rented" : "Available"}
                className="bg-white property-label"
                sx={{
                  color: "#009DA6",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              />
            </Box>
            {/* <img className="w-100" src={x.img} /> */}
          </Grid>
          <Grid item md={6} sm={12}>
            {/* this ternary operator is work as per the figma design because this card are almost common for all roles */}
            {userData?.role === ROLES?.landlord ||
            userData?.role === ROLES.propertyManager ? null : (
              <div className="d-flex gap-1 py-2">
                <b style={{ color: "#031013" }}>Rented Period:</b>
                <Typography color="#596E73" fontSize={18}>
                  {data.rentPeriod}
                </Typography>
              </div>
            )}
            <Typography
              className="py-1"
              fontSize={24}
              color="#031013"
              fontWeight={"bold"}
            >
              {data?.propertyName}
            </Typography>
            <div className="d-flex gap-1 py-1 align-items-center">
              <LocationIcon />
              <Typography color="#8D9B9F" fontWeight={200}>
                {data?.city} {data?.address?.addressText}
              </Typography>
            </div>
            <Typography
              className="py-2"
              fontWeight={"bold"}
              color="#031013"
              fontSize={18}
            >
              N {data?.rent}/{data?.rentType || data?.rentingType}
            </Typography>
            {/* condtionally rendering the button  */}
            {buttonShow === "" ? null : buttonShow ==
              PropertyPropsObjEnum.terminateBtnShow ? (
              <>
                {!renterStatus && (
                  <ButtonComponent
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalForTerminate(true);
                    }}
                    title={"Terminate Tenancy"}
                  />
                )}
                {!renterStatus && (
                  <span className="text-danger d-block mt-3 mb-2">
                    {" "}
                    Rent Due in {differenceInDays(data?.rent_period_end)} Days
                  </span>
                )}
                <Box
                  border={"1px solid #009DA6"}
                  padding={"18px"}
                  borderRadius={"10px"}
                >
                  <div className="d-flex justify-content-between">
                    <Typography
                      color={"#07262E"}
                      fontWeight={400}
                      fontSize={16}
                    >
                      Property rented on
                    </Typography>
                    <Typography
                      color={"#07262E"}
                      fontWeight={400}
                      fontSize={16}
                    >
                      {renterStatus ? "Lease end on" : "Lease will end on"}
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Typography
                      color={"#13556D"}
                      fontWeight={700}
                      fontSize={18}
                    >
                      {changeNumeicFormatDateToStandardDateFromatForSingle(
                        data?.rent_period_start
                      )}
                    </Typography>
                    <Typography
                      color={"#13556D"}
                      fontWeight={700}
                      fontSize={18}
                    >
                      {changeNumeicFormatDateToStandardDateFromatForSingle(
                        data?.lease_end_timestamp
                      )}
                    </Typography>
                  </div>
                </Box>
              </>
            ) : buttonShow ===
              PropertyPropsObjEnum.application_inspection_btn ? (
              <>
                {" "}
                {!data?.rented && data?.applicationCount > 0 ? (
                  <ButtonComponent
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/application-requests-property/${data?._id}`);
                    }}
                    title={`Application Received (${
                      data?.applicationCount === undefined
                        ? 0
                        : data?.applicationCount
                    })`}
                    sx={{
                      border: "1px solid #C8D736",
                      backgroundColor: "#fff",
                      width: "319px",
                      height: "64px",
                    }}
                  />
                ) : null}
                {!data?.rented && data?.inspectionCount > 0 ? (
                  <ButtonComponent
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/inspection-requests-property/${data?._id}`);
                    }}
                    title={`Inspection Request (${data?.inspectionCount})`}
                    sx={{
                      width: "319px",
                      height: "64px",
                      marginLeft: "10px",
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Card>
      {modalForTerminate && (
        <LandlordCommonModal
          open={modalForTerminate}
          setOpen={setModalForTerminate}
          heading={"Terminate Tenancy"}
          heading2={"Terminate Tenancy"}
          detailData={{
            RenterDetails: {
              fullName: terminateTenancyRenterDetail?.fullName,
              picture: terminateTenancyRenterDetail?.picture,
            },
            property_info: [
              {
                propertyName: data?.propertyName,
                _id: data?._id,
              },
            ],
          }}
          notificationText={` A notification has been sent to ${terminateTenancyRenterDetail?.fullName} regarding his tenancy termination`}
        />
      )}
    </>
  );
}
