import { SOCKET_API_ENGPOINTS } from "./API_Endpoints";
import axiosInstance from "../utils/axios";

export const joinChatRoom = (body) => {
  return axiosInstance
    .post(SOCKET_API_ENGPOINTS.joinChatRoom, body)
    .then((res) => res.data)
    .catch((err) => err);
};


export const getChatRooms = (params) => {
  return axiosInstance
    .get(`${SOCKET_API_ENGPOINTS.getChatRooms}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};


export const getAllMessages = (params) => {
  return axiosInstance
    .get(`${SOCKET_API_ENGPOINTS.getMessages}?${params}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err);
};


