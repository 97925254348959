import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";
import { formatCurrency } from "../utils/helperFunctionForValidte";

const CustomTextField = ({
  placeholder,
  label,
  type,
  className,
  disabled,
  value,
  multiline,
  rows,
  onChange,
  name,
}) => {
  const [field, meta] = useField(name);
  return (
    <TextField
      {...field}
      fullWidth
      value={value}
      helperText={meta.touched && meta.error ? meta.error : ""}
      error={meta.touched && Boolean(meta.error)}
      multiline={multiline}
      rows={rows}
      className={className}
      variant="outlined"
      disabled={disabled}
      color="secondary"
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      label={label}
      
      InputLabelProps={{
        sx: {
          color: "#07262E",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderColor: "#e6e9ea",
          borderWidth: "1px",
          borderRadius: 2,
        },
      }}
    />
  );
};

export default CustomTextField;
