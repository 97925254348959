import { TextareaAutosize, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { addRemarkToMaintenance } from "../../../services/maintenanaceRequestServices";
export default function AddRemarkModal({
  open,
  setOpen,
  heading,
  heading2,
  detailData,
  apiNotCallOnCancel = () => {},
}) {
  const [step, setStep] = React.useState(1);
  const [cancelRequestText, setCancelRequestText] = React.useState({
    value: "",
    warning: false,
  });
  // cancel the request from the backend api intigration
  const onCancelTheRequest = async () => {
    if (cancelRequestText?.value === "") {
      return setCancelRequestText((prev) => ({
        ...prev,
        warning: true,
      }));
    }
    const body = {
      landlordRemark: cancelRequestText.value,
      maintenanceID: detailData?._id,
    };
    const cancelData = await addRemarkToMaintenance(body);
    if (cancelData?.status) {
      setStep(2);
      setCancelRequestText({ value: "", warning: false });
    }
  };
  return (
    <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          apiNotCallOnCancel("cancel");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
            textAlign={step === 1 ? "left" : "center"}
          >
            {step === 1 ? heading : heading2}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex mb-4 align-items-center">
            {step === 1 ? (
              <>
                <img
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                  src={detailData?.renterDetails[0]?.picture}
                />
                <div className="ps-3">
                  {" "}
                  <Typography color={"#13556D"} fontWeight={700} fontSize={18}>
                    {detailData?.renterDetails[0]?.fullName}
                  </Typography>
                  <Typography
                    className="mt-1 mb-1"
                    color={"#07262E"}
                    fontWeight={400}
                    fontSize={18}
                  >
                    {detailData?.message ||
                      (detailData?.property_info &&
                        detailData?.property_info[0]?.propertyName)}
                  </Typography>
                  {/* <Typography
                    className="mt-1 mb-1"
                    color={"#8D9B9F"}
                    fontWeight={400}
                    fontSize={16}
                  >
                    {detailData?.inspectionDate || detailData?.checkinDate}
                  </Typography> */}
                </div>
              </>
            ) : (
              <>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                  color={"#07262E"}
                  textAlign={"center"}
                >
                  Remark has been added to the maintenance request raised by{" "}
                  {detailData?.renterDetails[0]?.fullName}
                </Typography>
              </>
            )}
          </div>
          {step === 1 ? (
            <>
              <TextareaAutosize
                size="lg"
                name="Size"
                placeholder={`Mention reason for ${heading}`}
                minRows={3}
                className="p-1"
                style={{
                  width: "100%",
                  borderRadius: "7px",
                }}
                value={cancelRequestText.value}
                onChange={(e) =>
                  setCancelRequestText((prev) => ({
                    ...prev,
                    value: e.target.value,
                    warning: false,
                  }))
                }
              />
              {cancelRequestText.warning && (
                <span className="text-danger">Enter the Reason</span>
              )}
            </>
          ) : null}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {step === 1 ? (
            <>
              <ButtonComponent
                onClick={() => {
                  setOpen(false);
                  apiNotCallOnCancel("cancel");
                  window.location.reload()
                }}
                title={"Cancel"}
                sx={{
                  border: "1px solid #C8D736",
                  backgroundColor: "#fff",
                  marginLeft: "20px",
                  marginBottom: "20px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
              />
              <ButtonComponent
                onClick={() => onCancelTheRequest()}
                title={"Submit"}
                sx={{
                  marginLeft: "20px",
                  marginBottom: "20px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
              />
            </>
          ) : (
            <ButtonComponent
              onClick={() => {
                setOpen(false);
                apiNotCallOnCancel("");
                setStep(1);
              }}
              title={"Done"}
              sx={{
                marginLeft: "20px",
                marginBottom: "20px",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            />
          )}
        </DialogActions>
      </Dialog>
  );
}
