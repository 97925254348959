import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { inspectionApplicationRequestStatus } from "../../../utils/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { rentApplicationSlice } from "../../../redux/rentApplication/rentApplicationSlice";
import { ApplicationRequestStatusChangedService } from "../../../services/application.services";

export default function WithDrawModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { rentDetail } = useSelector((state) => state.RENTAPPLICATION);
  const applicationReqWithDraw = async () => {
    const body = {
      status: inspectionApplicationRequestStatus?.withDraw,
      rentApplicationID: rentDetail?._id,
    };
    setLoading(true);
    const changeStatus = await ApplicationRequestStatusChangedService(body);
    if (changeStatus.status) {
      setOpen(false);
      dispatch(rentApplicationSlice.actions.rentApplicationModal());
    }else{
      console.log("err",changeStatus)
    }
    setOpen(false)
    setLoading(false);
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          fontSize={32}
          fontWeight={700}
          color={theme.palette.text.primary}
          textAlign={"center"}
        >
          Are you sure you want to withdraw your request.
        </Typography>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonComponent
          onClick={() => setOpen(false)}
          title={"No"}
          sx={{
            marginBottom: "20px",
            width: "179px",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        />
        <ButtonComponent
          onClick={() => applicationReqWithDraw()}
          title={"Yes"}
          loading={loading}
          disabled={loading}
          sx={{
            marginBottom: "20px",
            width: "179px",
            backgroundColor: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
