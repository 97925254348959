import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../App.css";
import { LocationIcon, SearchIcon } from "../../../assets/icons";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import { API_Endpoints } from "../../../services/API_Endpoints";
import { GetInsepctionsListService } from "../../../services/inspections.services";
import { InspectionStatus } from "../../../utils/CONSTANTS";
import "../../common/inspection-request/style.css";
import InspectionRequestModal from "../../common/property-detail/inspectionRequestModal";
import CancelInspectionModal from "./cancelInspectionModal";
import useDebounceSearch, {
  a,
  debounce,
  globalSearchService,
  globalSearchServiceForPost,
} from "../../../services/globalSearchService";
import { fDate } from "../../../utils/format-time";
import { replaceHttpImgUrl } from "../../../utils/helperFunctionForValidte";
let inspectionId = "";
export default function MyInspections() {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState({
    search: "",
  });
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [inspections, setInspections] = useState([]);
  const [inspectionMainData, setInspectionMainData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(
    InspectionStatus.UPCOMING
  );
  //array for the filter with object
  //@@ label to show on frontend and value to match with the backend
  const filters = [
    { label: "Upcoming", value: InspectionStatus.UPCOMING },
    { label: "Completed", value: InspectionStatus.COMPLETED },
    { label: "Pending", value: InspectionStatus.INITIATED },
  ];
  const navigate = useNavigate();

  // filter the data as per the filter
  //@@ filter = UPOMING || COMPLETED || PENDING
  //@@ param = filtervalue for which we can filter the array
  const filterDataAsPerFIlterValue = (filterValue) => {
    setSelectedFilter(filterValue);
  };

  // get the data from the backend
  const GetMyInstepctions = async () => {
    setLoading(true);
    const response = await GetInsepctionsListService();
    if (response.status) {
      filterDataAsPerFIlterValue(InspectionStatus.UPCOMING);
      setInspectionMainData(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetMyInstepctions();
  }, []);

  // get the response of the search value as per the filter value
  const searchInspectoinRequest = async (value) => {
    console.log("*****", value);
    setLoading(true);
    const url = `${API_Endpoints.inspections.searchInspection}?search=${searchValue.search}&status=${selectedFilter}`;
    const getData = await globalSearchServiceForPost(url);
    setLoading(false);
    setInspections(getData?.data);
    console.log("getData", getData);
  };

  useDebounceSearch(searchValue.search, searchInspectoinRequest, 1000, {
    filterStatus: selectedFilter,
  }); // call the deboounce hook

  console.log("modalData", modalData);
  return (
    <Container maxWidth="xl" className="my-3">
      <div className="py-4">
        <Box>
          <Typography
            fontSize={40}
            fontWeight={800}
            className="phone-heading-30"
            color={theme.palette.text.primary}
          >
            My Inspections
          </Typography>
        </Box>
        <Box className="mb-4 mt-4">
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <TextField
                type="search"
                variant="outlined"
                className="w-100"
                value={searchValue.search}
                onChange={(e) => {
                  setSearchValue((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }));
                }}
                placeholder="search"
                sx={{ border: "1px solid #DBDBDB", outline: "none" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <Grid container spacing={3} className="text-end">
                {filters.map((x, i) => {
                  return (
                    <Grid key={i} item md={3}>
                      <div className="tabs_style text-start">
                        <button
                          className={`btn_tab ${
                            x.value === selectedFilter ? "active" : ""
                          }`}
                          onClick={() => filterDataAsPerFIlterValue(x.value)}
                        >
                          {x.label}
                        </button>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <CircularProgress size={"2rem"} />
          </div>
        ) : (
          <Grid container spacing={4} className="mt-2 inspection-card">
            {inspections.length > 0 ? (
              inspections.map((x, i) => (
                <Grid item xs={12} md={4} key={i}>
                  <Card
                    elevation={0}
                    sx={{
                      p: 3,
                      borderRadius: 3,
                      boxShadow: "5px 5px 19px 4px #0000000d",
                    }}
                    onClick={() =>
                      navigate(`/property-detail/${x?.propertyID}`)
                    }
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className="inspection-mobile-space"
                      >
                        <img
                          style={{ maxWidth: "100%", height: "150px" }}
                          src={replaceHttpImgUrl(
                            x?.propertyDetails?.images[0]?.url
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        className="ps-3 pt-1 phone-padding-0"
                      >
                        <Typography
                          fontSize={18}
                          fontWeight={700}
                          color={theme.palette.text.primary}
                        >
                          {fDate(x?.inspectionDate)} {x.inspectionTime}
                        </Typography>
                        <Typography
                          fontSize={16}
                          fontWeight={700}
                          color={theme.palette.text.secondary}
                          className="mt-3 mb-2"
                        >
                          {x?.propertyDetails?.propertyName}
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          color={"#8D9B9F"}
                        >
                          {LocationIcon()}{" "}
                          {x?.propertyDetails?.address?.addressText}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mt-4">
                      <Grid item xs={6}>
                        <Typography
                          fontSize={16}
                          fontWeight={700}
                          color={theme.palette.text.secondary}
                        >
                          {x?.landlordName || "No Landlord Name"}
                        </Typography>
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          color={"#07262E"}
                          className="mb-4"
                        >
                          Landlord
                        </Typography>
                        {selectedFilter == InspectionStatus.INITIATED ||
                        selectedFilter == InspectionStatus.UPCOMING ? (
                          <ButtonComponent
                            disabled={
                              x.inspectionStatus === "canceled" ? true : false
                            }
                            title={
                              x.inspectionStatus === "canceled"
                                ? "Cancelled"
                                : "Cancel"
                            }
                            color={
                              x.inspectionStatus === "canceled"
                                ? "error"
                                : "secondary"
                            }
                            sx={{ width: "155px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              inspectionId = x._id;
                              setOpen(true);
                            }}
                          />
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="text-end"
                        style={{ marginTop: "24px" }}
                      >
                        <Link onClick={(e) => e.stopPropagation()} to="/chat">
                          <Typography
                            textAlign={"right"}
                            fontSize={14}
                            fontWeight={700}
                            color={"#C8D736"}
                          >
                            Chat Now
                          </Typography>
                        </Link>
                        {selectedFilter == InspectionStatus.INITIATED ||
                        selectedFilter == InspectionStatus.UPCOMING ? (
                          <ButtonComponent
                            title={"Edit"}
                            disabled={
                              x.inspectionStatus === "canceled" ? true : false
                            }
                            sx={{ width: "155px" }}
                            className={"mt-4"}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditModal(true);
                              setModalData(x);
                            }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
            ) : (
              <h2 className="text-center w-100">No Inspections found</h2>
            )}
          </Grid>
        )}
      </div>
      {inspectionId && (
        <CancelInspectionModal
          open={open}
          setOpen={setOpen}
          inspectionId={inspectionId}
          setInspections={setInspections}
          inspections={inspections}
        />
      )}
      <InspectionRequestModal
        open={editModal}
        setOpen={setEditModal}
        propertyId={modalData?.propertyID}
        data={{
          message: modalData?.message,
          propertyName: modalData?.propertyDetails?.propertyName,
          _id: modalData?._id,
          landlordPropertyMangerIDforGettheSlot:modalData?.propertyID,
        }}
        heading="Inspection Request"
      />
    </Container>
  );
}
