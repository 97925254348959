import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LocationIcon } from "../../../assets/icons";
import { inspectionHouseImgCard } from "../../../assets/images";
import { theme } from "../../../assets/theme";
import ButtonComponent from "../../../components/Button";
import CustomTextField from "../../../components/TextField";
import { useNavigate } from "react-router-dom";

export default function ELectiricityRecharge() {
  const arr = [1, 2, 3, 4, 5];
  const [billDetail, setBillDetail] = useState(false);

  const navigate = useNavigate()
  return (
    <Container maxWidth="xl" className="mt-3">
      <div className="py-4">
        <Box>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={800}
            color={theme.palette.text.primary}
          >
            Electricity Recharge
          </Typography>
        </Box>

        <Grid className="mt-2" spacing={4} container>
          <Grid item md={4}>
            <Card sx={{ borderRadius: 3,
                        boxShadow: "5px 5px 19px 4px #0000000d", }} elevation={0}>
              <div className="d-flex align-items-center gap-3 p-3 flex-wrap">
                {inspectionHouseImgCard()}
                <Stack>
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    color={theme.palette.text.primary}
                  >
                    Naf Valley Estate 
                  </Typography>
                  <Typography fontSize={14} fontWeight={400} color={"#8D9B9F"}>
                    {LocationIcon()} Naf Valley Estate, Asokoro District, Abuja
                  </Typography>
                </Stack>
              </div>
            </Card>
          </Grid>

          <Grid item md={8}>
            <Card
              sx={{ borderRadius: 3,
                        boxShadow: "5px 5px 19px 4px #0000000d", }}
              elevation={0}
              className="d-flex justify-content-between align-items-center p-3 flex-wrap"
            >
              <Stack>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  color={theme.palette.text.primary}
                >
                  Meter Id : #MD8948292
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  placeholder={"Enter top-up amount"}
                  type={"text"}
                  label={""}
                  InputLabelProps={{
                    sx: {
                      color: "#07262E",
                    },
                    "&.Mui-focused": {
                      color: "#07262E", // Color of the label when focused
                    },
                  }}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    minWidth: "300px",
                    height: 51,
                  }}
                  // focused
                />
              </Stack>
              <div>
                <Typography
                  className="bill-history-text"
                  fontSize={16}
                  fontWeight={700}
                  color={"#C8D736"}
                  textAlign={"right"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setBillDetail(true)}
                >
                  Bill History
                </Typography>
                <ButtonComponent
                onClick={() => navigate('/payment-method')}
                  title={"Recharge"}
                  sx={{ width: "262px", marginTop: "24px" }}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="mt-4 mb-5">
        {billDetail && (
          <Typography color={"#07262E"} fontWeight={700} fontSize={16}>
            Bill History
          </Typography>
        )}
        {billDetail &&
          arr.map((x, i) => (
            <Card className="my-2 p-2" elevation={0} key={i}  sx={{ cursor: "pointer", borderRadius: 3,
                        boxShadow: "5px 5px 19px 4px #0000000d", }}>
              <Grid container className="my-2">
                <Grid item md={12} xs={12}>
                  <Typography color={"#13556D"} fontWeight={700} fontSize={12}>
                    01 Oct to 31 Oct 2023
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    marginTop={1}
                    color={"#07262E"}
                    fontWeight={400}
                    fontSize={12}
                  >
                    Rs. 3000
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} className="text-end">
                  <Typography color={"#009DA6"} fontWeight={400} fontSize={12}>
                    Success
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))}
      </div>
    </Container>
  );
}
