import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/theme";
import "./index.css";
import { useSelector } from "react-redux";
import { fDate } from "../../../utils/format-time";
import ButtonComponent from "../../../components/Button";
import { mantainanceFilter } from "../../../utils/CONSTANTS";
import { cancelMaintenanceRequest } from "../../../services/maintenanaceRequestServices";
import { useNavigate } from "react-router-dom";
export default function MaintainceRequestDetails() {
  const navigate = useNavigate();
  const { maintainceDetail } = useSelector((state) => state.MAINTAINCEREQUEST);
  const [loading, setLoading] = useState(false);
  // cancel the maintainance request
  const cancelMaintainanaceReq = async () => {
    setLoading(true);
    const cancelData = await cancelMaintenanceRequest(maintainceDetail?._id);
    if (cancelData?.status) {
      navigate(-1);
    }
    setLoading(false);
  };
  return (
    <Container maxWidth="xl" className="mt-4">
      <div className="py-4">
        <Box>
          <Typography
            className="phone-heading-30"
            fontSize={40}
            fontWeight={700}
            color={theme.palette.text.primary}
            marginBottom={"20px"}
          >
            Maintenance Requests
          </Typography>
        </Box>

        <Card
          sx={{ borderRadius: 3, boxShadow: "5px 5px 19px 4px #0000000d" }}
          elevation={0}
          className="p-4 mt-2"
        >
          <Grid container mt={2}>
            <Grid item md={10}>
              <Typography
                fontSize={18}
                fontWeight={700}
                color={theme.palette.text.primary}
                marginBottom={"16px"}
              >
                {maintainceDetail?.concern}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.text.primary}
                marginBottom={"10px"}
              >
                Your remark
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={"#395158"}
                marginBottom={"15px"}
              >
                {maintainceDetail?.renterRemark}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color={"#8D9B9F"}
                marginBottom={"20px"}
              >
                {fDate(maintainceDetail?.createdAt)}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={700}
                color={theme.palette.text.primary}
                marginBottom={"10px"}
              >
                Landlord remark
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color={"#395158"}
                marginBottom={"15px"}
              >
                {maintainceDetail?.landlordRemark
                  ? maintainceDetail?.landlordRemark
                  : "No remark yet added by Landord"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {maintainceDetail?.status === mantainanceFilter?.pending ? (
          <Grid textAlign={"right"} marginTop={"10px"}>
            <ButtonComponent
              title={"Cancel"}
              loading={loading}
              disabled={loading}
              onClick={() => cancelMaintainanaceReq()}
            />
          </Grid>
        ) : null}
      </div>
    </Container>
  );
}
