import { Box, Card, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/theme";
import { inspectionHouseImgCard } from "../../../assets/images";
import inspectionHouse from "../../../assets/images/inspectionsHouse.png";
import { LocationIcon } from "../../../assets/icons";

export default function Commission() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <Container maxWidth="xl">
      
        <Typography
          fontSize={40}
          fontWeight={700}
          color={theme.palette.text.primary}
          // marginBottom={"20px"}
          className="title_page pt-4"
        >
          Commission
        </Typography>
     
      <Grid container spacing={3} className="mt-1 mb-5">
        {arr &&
          arr.map(() => (
            <Grid item md={4} xs={12} sm={6}>
              <Card className="p-4" sx={{borderRadius: 3,
                        boxShadow: "5px 5px 19px 4px #0000000d"}}>
                <Box className="d-flex gap-4" >
                  <Grid item md={2}>
                    <img
                      style={{ height: 75, width: 80 }}
                      src={inspectionHouse}
                    />
                  </Grid>
                  <div className="col-md-10 align-items-center d-flex ">
                    <div className="">
                      <Typography
                        fontSize={16}
                        fontWeight={700}
                        textTransform={'uppercase'}
                        color={theme.palette.text.primary}
                      >
                        Naf Valley Estate
                      </Typography>
                      <Typography
                        fontSize={12}
                        fontWeight={400}
                        color={"#8D9B9F"}
                        textTransform={'uppercase'}
                        letterSpacing={2}
                      >
                        {LocationIcon()} Naf Valley Estate, Asokoro District...
                      </Typography>
                    </div>
                  </div>
                </Box>

                <Divider className="mt-3"  />
                <div className="d-flex justify-content-between mt-3">
                  <Typography fontWeight={400} fontSize={16} color={"#07262E"}>
                    Date
                  </Typography>
                  <Typography fontWeight={400} fontSize={16} color={"#07262E"}>
                    2 Jan 2023
                  </Typography>
                </div>
                <div className="d-flex justify-content-between mt-2 mb-2">
                  <Typography fontWeight={400} fontSize={16} color={"#07262E"}>
                    Rent Amount
                  </Typography>
                  <Typography fontWeight={400} fontSize={16} color={"#07262E"}>
                    ₦ 10,600
                  </Typography>
                </div>
                <div className="d-flex justify-content-between">
                  <Typography fontWeight={700} fontSize={16} color={"#1B1B1B"}>
                    Commission Amount
                  </Typography>
                  <Typography fontWeight={700} fontSize={16} color={"#1B1B1B"}>
                    ₦ 5,000
                  </Typography>
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
