import { Container, Divider, Grid, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import * as Yup from "yup";
import AuthenticationLayout from ".";
import { FacebookSocialIcon, GoogleSocialIcon } from "../../assets/icons";
import ButtonComponent from "../../components/Button";
import CustomTextField from "../../components/TextField";
import { authSlice } from "../../redux/auth/authSlice";
import { API_Endpoints } from "../../services/API_Endpoints";
import { setLocalUser } from "../../utils/authService";
import { baseURL } from "../../utils/axios";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState(true);
  const { FCM_TOKEN } = useSelector((state) => state.AUTH);
  console.log("fcm", FCM_TOKEN);
  const onSubmitHandler = async (values) => {
    try {
      const response = await axios.post(
        baseURL + API_Endpoints.auth.SOCIAL_LOGIN,
        { ...values, fcmToken: FCM_TOKEN }
      );
      console.log(response.data, "datadatddatattd");
      if (response.data.status) {
        setLocalUser({
          ...response.data.data,
          accessToken: response.data.accessToken,
        });
        navigate("/renter");
      }
    } catch (error) {
      console.log(error, "errorororrororo");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await axios
        .post(baseURL + API_Endpoints.auth.LOGIN, {
          ...values,
          fcmToken: FCM_TOKEN,
        })
        .then((res) => {
          console.log(res);
          if (res?.status) {
            formik.resetForm();
            setLocalUser({
              ...res.data.data,
              accessToken: res.data.accessToken,
            });

            if (res.data.data.role === "Renter") {
              navigate("/renter");
            } else if (res.data.data.role === "Landlord") {
              navigate("/landlord-dashboard");
            } else {
              navigate("/property-manager");
            }
          }
        })
        .catch((err) => {
          console.log("err", err?.response);
          //if  user have not complete the signup flow and they will login without email verfication
          if (err?.response?.data?.message == "please verify email id") {
            navigate("/email-verification");
            dispatch(
              authSlice.actions.setTheUserIdAtTimeOfLoginOrSignUp(
                err?.response?.data?.data?.id
              )
            );
          } else {
            NotificationManager.error("", err?.response?.data?.message);
          }
        });
    },
  });

  // login with google with the useloginhook
  const login = useGoogleLogin({
    // theme="filled_black"
    // clientId=“821353603223-d3vutqm04fu88jl0jmju9ts19a5kp290.apps.googleusercontent.com”
    onSuccess: async (credentialResponse) => {
      console.log("cresd", credentialResponse);

      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse?.access_token}`,
        {
          Authorization: credentialResponse.access_token,
        }
      );

      const payload = response.data;
      onSubmitHandler({
        ...credentialResponse,
        ...payload,
        socialPlatform: "google",
      });
    },
    onError: () => {
      console.log("dfnfbjfnbjnf");
    },
    // flow: "auth-code",
  });

  // reset the forgotpassword flow
  useEffect(() => {
    dispatch(authSlice.actions.setforgotPasswordIntialState());
  }, []);
  return (
    <FormikProvider value={formik}>
      <AuthenticationLayout>
        <div>
          <Typography
            fontSize={24}
            fontWeight={"bold"}
            color="#111111"
            className="text-center"
            mb={2}
          >
            Sign In to Your Account
          </Typography>

          <Container maxWidth="xs">
            <Grid container spacing={2}>
              <Grid item md={12} xs={12} className="text-center">
                <CustomTextField
                  name="email"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                  placeholder="Enter email id"
                  label="Email id"
                />
              </Grid>
              <Grid item md={12}  xs={12} className="text-center">
                <div className="position-relative">
                  <CustomTextField
                    name={"password"}
                    value={formik.values.password}
                    onChange={(e) =>
                      formik.setFieldValue("password", e.target.value)
                    }
                    type={passwordType ? "password" : "text"}
                    placeholder="Enter password"
                    label="Password"
                  />
                  <div className="position_icon_absolute eye-icon-login">
                    <button
                      className="me-2"
                      type="button"
                      style={{
                        borderColor: "transparent",
                        background: "transparent",
                      }}
                      onClick={() => setPasswordType(!passwordType)}
                    >
                      {passwordType ? (
                        <IoMdEyeOff size={26} />
                      ) : (
                        <IoMdEye size={26} />
                      )}
                    </button>
                  </div>
                </div>

                <Link to={"#"}>
                  <Typography
                    fontWeight={500}
                    className="mt-2"
                    fontSize={12}
                    color={"text.primary"}
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </Typography>
                </Link>

                <ButtonComponent
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  className={"mt-4"}
                  sx={{ width: 327 }}
                  title={"Sign In"}
                  onClick={() => formik.handleSubmit()}
                  color="secondary"
                />

                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Typography fontSize={14} color="#B2BCBE">
                    Don't have an account?
                  </Typography>
                  &nbsp;
                  <Link to={"/signup"}>
                    <Typography fontSize={14} color="text.primary">
                      Sign up
                    </Typography>
                  </Link>
                </div>
                <div className="d-flex justify-content-center gap-3 align-items-center mt-5">
                  <Divider
                    component={"line"}
                    sx={{ width: 148, color: "#DBDBDB" }}
                    orientation="horizontal"
                  />
                  <Typography color="#DBDBDB" fontSize={12}>
                    OR
                  </Typography>
                  <Divider
                    component={"line"}
                    sx={{ width: 148, color: "#DBDBDB" }}
                    orientation="horizontal"
                  />
                </div>

                <div className="d-flex align-items-center justify-content-center mt-4 gap-4">
                  {/* <GoogleLogin
                    logo_alignment="center"
                    // theme="filled_black"
                    // clientId=“821353603223-d3vutqm04fu88jl0jmju9ts19a5kp290.apps.googleusercontent.com”
                    onSuccess={(credentialResponse, data) => {
                      const { credential } = credentialResponse;
                      const payload = credential
                        ? decodeJwt(credential)
                        : undefined;
                      if (payload) {
                        console.log(payload, "payload");
                      }
                      console.log({ ...credentialResponse, ...data }, "hiiiii");

                      onSubmitHandler({
                        ...credentialResponse,
                        ...data,
                        ...payload,
                        socialPlatform: "google",
                      });
                    }}
                    onError={() => {
                      console.log("dfnfbjfnbjnf");
                    }}

                    // redirect_uri={REDIRECT_URI}
                  /> */}
                  {/* <Box onClick={() => googleLogin()}>
                  </Box> */}
                  <button
                    style={{
                      borderColor: "transparent",
                      borderRadius: "13px",
                      marginTop: "10px",
                    }}
                    onClick={() => login()}
                  >
                    {" "}
                    <GoogleSocialIcon />
                  </button>

                  {/* <FacebookSocialIcon /> */}

                  <LoginSocialFacebook
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    onLogoutSuccess={() => {}}
                    onResolve={({ provider, data }) => {
                      console.log(data, "data");

                      onSubmitHandler({ ...data, socialPlatform: "facebook" });
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                    // redirect_uri={REDIRECT_URI}
                  >
                    <FacebookSocialIcon />
                  </LoginSocialFacebook>
                  {/* <AppleSocialIcon /> */}
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </AuthenticationLayout>
    </FormikProvider>
  );
};

export default Login;
